import { z } from "zod";
import { AdditionalServiceLanguage } from "albertine-shared-web";
import { LongString } from "../validate";
import { capitalize } from "../utils/string.util";

export const catalogItemNameMaxLength = 255;
export const CatalogItemTranslation = z.object({
    address: LongString.nullable(),
    benefits: z.string().nullable(),
    bookingPolicy: z.string().nullable(),
    description: z
        .string({ required_error: "Add general description" })
        .min(3, "Add general description")
        .nullable(),
    eventDates: z.string().nullable(),
    headline: z.string().nullable(),
    localInsights: z.string().nullable(),
    name: z
        .string({ required_error: "Add name" })
        .min(3, "Add name")
        .max(catalogItemNameMaxLength, "Limit for name is 255 characters")
        .nullable(),
    travelItineraryNotes: z.string().nullable(),
});

export type CatalogItemTranslationKeys = keyof CatalogItemTranslation;

type CatalogItemTranslationAdditionalLanguageKeys =
    `${CatalogItemTranslationKeys}${Capitalize<AdditionalServiceLanguage>}`;

export type CatalogItemTranslationServiceLanguageKeys =
    `${CatalogItemTranslationKeys}${Capitalize<"" | AdditionalServiceLanguage>}`;

export const CatalogItemTranslations = z.object({
    de: CatalogItemTranslation.optional(),
    fr: CatalogItemTranslation.optional(),
});

export type CatalogItemTranslation = z.infer<typeof CatalogItemTranslation>;
export type CatalogItemTranslations = z.infer<typeof CatalogItemTranslations>;

export function collectTranslationValues(
    keys: CatalogItemTranslationKeys[],
    item: Record<string, any>,
): (string | null | undefined)[] {
    const translationKeys = AdditionalServiceLanguage.options.flatMap(
        (lang): CatalogItemTranslationAdditionalLanguageKeys[] =>
            keys.map<CatalogItemTranslationAdditionalLanguageKeys>(
                (key) =>
                    `${key}${capitalize(lang) as Capitalize<AdditionalServiceLanguage>}`,
            ),
    );

    return translationKeys.map((key) => item[key]);
}
