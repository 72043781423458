import { Lib } from "albertine-shared-web";
import classNames from "classnames";
import React from "react";
import "./Modal.css";
import { motion } from "framer-motion";
import { Outlet } from "react-router";

type ModalProps = {
    className?: string;
    children: React.ReactNode;
};

function Modal(props: ModalProps) {
    const { className, children } = props;
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
            style={{
                /* These do not work in CSS so leave these for screens to apply full height */
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                zIndex: 1,
                backgroundColor: "#FFFFFF",
            }}
        >
            <Lib.Flex.Column
                gap="value16"
                className={classNames("modal", className)}
            >
                {children}
            </Lib.Flex.Column>
        </motion.div>
    );
}

export function ModalOutlet() {
    return <Outlet />;
}

export default Modal;
