import { z } from "zod";

export const BookingReservationStatus = z.enum([
    "unselected",
    "prebooked",
    "confirmed",
    "cancelled",
]);

export type BookingReservationStatus = z.infer<typeof BookingReservationStatus>;

export const BookingReservationStatusMap: {
    [key in BookingReservationStatus]: string;
} = {
    unselected: "Choose",
    prebooked: "Pre-booked",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
};

export function displayReservationStatus(
    status: BookingReservationStatus | undefined | null,
) {
    if (!status) return "Not booked";
    if (status === "unselected") return "Not booked";
    return BookingReservationStatusMap[status];
}
