import Start from "./OnboardingStart";
import Name from "./OnboardingName";
import Cities from "./OnboardingCities";
import ConciergeExperience from "./OnboardingConciergeExperience";
import { OnboardingSteps } from "../../types/OnboardingSteps";

type OnboardingStep = JSX.ElementType;
type StepsMap = {
    [key in OnboardingSteps]: OnboardingStep;
};

const onboardingStepsMap: StepsMap = {
    start: Start,
    name: Name,
    cities: Cities,
    conciergeExperience: ConciergeExperience,
};

export default onboardingStepsMap;
