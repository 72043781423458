import { Lib, useTenant } from "albertine-shared-web";
import React from "react";
import classNames from "classnames";
import useScrollIsOnTop from "../hooks/useScrollIsOnTop";
import useStyleTransform from "../hooks/useStyleTransform";
import { useScreenStack } from "../context/screenStack";
import "./Header.css";

interface Props {
    title: string;
    type: "article" | "booking" | "proposal" | "profile";
}

function Header(props: Props) {
    const { closeStackScreen } = useScreenStack();
    const { title, type } = props;
    const onTop = useScrollIsOnTop();

    return (
        <Lib.Flex.Row
            justifyContent="center"
            className={classNames("header__wrapper", `header__type--${type}`, {
                "header__wrapper--top": onTop,
            })}
        >
            <Lib.Button.Ghost
                onClick={closeStackScreen}
                className="header__back-button"
            >
                <Lib.Icon.ArrowBack.Medium />
            </Lib.Button.Ghost>

            <Lib.Heading.H5.Bold className="header__title">
                {title}
            </Lib.Heading.H5.Bold>
        </Lib.Flex.Row>
    );
}
export default Header;

export function BackButton() {
    const transformRef = useStyleTransform({
        initialOpacity: 1,
        finalOpacity: 0,
        transformDistance: 16,
        transitionDuration: 0.2,
        scrollThreshold: 30,
    });
    const { tenant } = useTenant();
    const { closeStackScreen } = useScreenStack();

    return (
        <Lib.Flex.Row
            className={classNames("header__back-button", {
                "text-color-inversable": tenant === "hugoboss",
            })}
            ref={transformRef}
        >
            <Lib.Button.Ghost onClick={closeStackScreen}>
                {tenant === "hugoboss" ? (
                    <Lib.Icon.ArrowBack.Medium.Gray100 />
                ) : (
                    <Lib.Icon.ArrowBack.Medium.Gray0 />
                )}
            </Lib.Button.Ghost>
        </Lib.Flex.Row>
    );
}
