import { z } from "zod";

export const NotificationDeliveryMethod = z.enum([
    "push-notification",
    "email",
]);
export type NotificationDeliveryMethod = z.infer<
    typeof NotificationDeliveryMethod
>;

export function isNotificationDeliveryMethod(
    possible: any,
): possible is NotificationDeliveryMethod {
    return NotificationDeliveryMethod.safeParse(possible).success;
}
