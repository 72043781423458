import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { firebaseAppConfig } from "./config";

export const firebase = initializeApp(firebaseAppConfig);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();
export const functions = getFunctions(firebase, "europe-west1");

export const isLocalDevelopmentEnvironment =
    import.meta.env.VITE_LOCAL === "true";

if (isLocalDevelopmentEnvironment) {
    const { hostname } = document.location;

    console.log("Connecting to Firebase Emulators", { hostname });

    connectFirestoreEmulator(db, hostname, 8080);
    connectStorageEmulator(storage, hostname, 9199);
    connectFunctionsEmulator(functions, hostname, 5001);
    connectAuthEmulator(auth, `http://${hostname}:9099`, {
        disableWarnings: true,
    });
}
