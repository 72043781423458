import { z } from "zod";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { AgentId, MemberId } from "../validate";
import { isTypeOf } from "../utils/zod.util";
import { LocalDate } from "../utils/time.util";

export const defaultBillingPeriodId = "yearly";
export const defaultMembershipTierId = "founding-tier";
export const essentialMembershipTier = "essential-tier";
export const defaultCommitmentPeriodId = "12-month";
export const defaultTrialPeriodId = "trial-1-month";

export const CommitmentPeriodId = z.enum(["12-month"]);
export type CommitmentPeriodId = z.infer<typeof CommitmentPeriodId>;
export const CommitmentPeriodIdMap: { [key in CommitmentPeriodId]: string } = {
    "12-month": "12 Months",
};

export const CommitmentPeriod = z.object({
    id: CommitmentPeriodId,
    name: z.string().max(60),
    value: z.number(),
    active: z.boolean(),
});
export type CommitmentPeriod = z.infer<typeof CommitmentPeriod>;

export const defaultCommitmentPeriods: CommitmentPeriod[] = [
    {
        id: defaultCommitmentPeriodId,
        name: "12 months",
        value: 12,
        active: true,
    },
];

export const TrialId = z.enum(["trial-1-month", "trial-3-month", "no-trial"]);
export type TrialId = z.infer<typeof TrialId>;

export const MembershipTrial = z.object({
    id: TrialId,
    name: z.string().max(60),
    value: z.number(),
    active: z.boolean(),
});
export type MembershipTrial = z.infer<typeof MembershipTrial>;

export type FilterByMembershipTrial = {
    id: MembershipTierId;
    name: string;
    count: number;
};
export type FilterByCommitmentPeriod = {
    id: CommitmentPeriodId;
    name: string;
    count: number;
};

export const MembershipStageId = z.enum(["trial", "paid"]);
export type MembershipStageId = z.infer<typeof MembershipStageId>;

export const defaultTrialPeriods: MembershipTrial[] = [
    {
        id: "trial-1-month",
        name: "1 months",
        value: 1,
        active: true,
    },
    {
        id: "trial-3-month",
        name: "3 months",
        value: 3,
        active: true,
    },
    {
        id: "no-trial",
        name: "No trial",
        value: 0,
        active: true,
    },
];

export function isNoTrial(trialId: TrialId): trialId is "no-trial" {
    return trialId === "no-trial";
}

export const BillingPeriodId = z.enum(["monthly", "yearly"]);
export type BillingPeriodId = z.infer<typeof BillingPeriodId>;

export const BillingPeriodIdMap: { [key in BillingPeriodId]: string } = {
    monthly: "Monthly",
    yearly: "Yearly",
};

export const MembershipTierId = z
    .string()
    .regex(/^[a-z0-9][-a-z0-9]{0,254}$/, "Invalid membership id.");
export type MembershipTierId = z.infer<typeof MembershipTierId>;
export const MembershipTier = z.object({
    id: MembershipTierId,
    name: z.string().max(100),
    active: z.boolean(),
});

export type MembershipTier = z.infer<typeof MembershipTier>;

export const defaultEndMembershipReason = "other";
export const EndMembershipReason = z.enum([
    "access",
    "price",
    "value",
    "service-level",
    "would-not-use",
    "other",
    "unknown",
]);
export type EndMembershipReason = z.infer<typeof EndMembershipReason>;
export const EndMembershipReasonMap: { [key in EndMembershipReason]: string } =
    {
        access: "Access",
        price: "Price",
        value: "Value",
        "service-level": "Service Level",
        "would-not-use": "Wouldn't Use",
        other: "Other",
        unknown: "Unknown",
    };

export const MembershipCancellation = z.object({
    reason: EndMembershipReason,
    additionalNote: z.string().nullable().optional(),
    cancelledAt: FirebaseTimestamp.optional().nullable(),
    cancelledBy: AgentId,
});
export type MembershipCancellation = z.infer<typeof MembershipCancellation>;

export const MembershipCommon = z.object({
    tier: MembershipTierId,
    cancellation: MembershipCancellation.optional().nullable(),
    /* Represents number of days for which membership is extended */
    extendedFor: z.number().optional().nullable(),
});

export const MembershipStageTrial = MembershipCommon.merge(
    z.object({
        trial: z.enum(["trial-1-month", "trial-3-month"]),
        startTrialDate: LocalDate.optional().nullable(),
        endTrialDate: LocalDate.optional().nullable(),
    }),
);
export type MembershipStageTrial = z.infer<typeof MembershipStageTrial>;

export const MembershipStagePaid = MembershipCommon.merge(
    z.object({
        trial: z.enum(["no-trial"]),
        startDate: LocalDate,
        endDate: LocalDate,
        commitmentPeriod: CommitmentPeriodId.optional().nullable(),
        billingPeriod: BillingPeriodId.optional().nullable(),
    }),
);
export type MembershipStagePaid = z.infer<typeof MembershipStagePaid>;

export const Membership = z.discriminatedUnion("trial", [
    z
        .object({
            trial: z.literal("trial-1-month"),
        })
        .merge(MembershipStageTrial.omit({ trial: true })),
    z
        .object({
            trial: z.literal("trial-3-month"),
        })
        .merge(MembershipStageTrial.omit({ trial: true })),
    z
        .object({
            trial: z.literal("no-trial"),
        })
        .merge(MembershipStagePaid.omit({ trial: true })),
]);
export type Membership = z.infer<typeof Membership>;

export const MembershipUpdatables = z.object({
    id: MemberId,
    membership: Membership,
});
export type MembershipUpdatables = z.infer<typeof MembershipUpdatables>;

export const MembershipStagePaidExposedToMember = z.object({
    endDate: LocalDate,
    isMembershipCancelled: z.boolean().or(z.undefined()),
});
export type MembershipStagePaidExposedToMember = z.infer<
    typeof MembershipStagePaidExposedToMember
>;

export const TimestampUpdatables = z.object({
    createdAt: FirebaseTimestamp.nullable(),
    updatedAt: FirebaseTimestamp.optional().nullable(),
});

export const MembershipDoc = z.union([Membership, TimestampUpdatables]);
export type MembershipDoc = z.infer<typeof MembershipDoc>;

export function getEndDate(membership: Membership): string | null | undefined {
    if (isTypeOf(membership, MembershipStagePaid)) return membership.endDate;
    return membership.endTrialDate;
}
