import { z } from "zod";
import { TravelQuickRequestSubtype } from "./QuickRequests";

export const OfferingCategory = z.enum(
    [
        "dining",
        "nightlife",
        "flight",
        "train",
        "accommodation",
        "travelItinerary",
        "transportation",
        "event",
        "localExperience",
        "shopping",
        "wellness",
        "services",
        "other",
    ],
    { errorMap: () => ({ message: "Primary category is required" }) },
);

export type OfferingCategory = z.infer<typeof OfferingCategory>;

export const OfferingCategoryUncategorizedKey = "uncategorized";

const CategoriesWithUncategorizedList = [
    ...OfferingCategory.options,
    OfferingCategoryUncategorizedKey,
] as const;
export const CategoriesWithUncategorized = z.enum(
    CategoriesWithUncategorizedList,
);
export type CategoriesWithUncategorized = z.infer<
    typeof CategoriesWithUncategorized
>;

export const CategoryLabelMap: {
    [key in CategoriesWithUncategorized]: string;
} = {
    dining: "Dining",
    nightlife: "Nightlife",
    flight: "Flight",
    train: "Train",
    accommodation: "Accommodation",
    travelItinerary: "Travel itinerary",
    transportation: "Transportation",
    event: "Event",
    localExperience: "Local experience",
    shopping: "Shopping",
    wellness: "Wellness",
    services: "Services",
    other: "Other",
    uncategorized: "Uncategorized",
};

export type FilterByCategory = {
    id: CategoriesWithUncategorized;
    name: string;
};

export const CategoryAndRequestCategory = z.object({
    offeringCategory: OfferingCategory.optional().nullable(),
});

export type CategoryAndRequestCategory = z.infer<
    typeof CategoryAndRequestCategory
>;

export function mapTravelQuickRequestSubtypeToOfferingCategory(
    subtype: TravelQuickRequestSubtype | undefined,
): OfferingCategory {
    if (subtype) {
        switch (subtype) {
            case "flight":
                return "flight";
            case "accommodation":
                return "accommodation";
            case "transportation":
                return "transportation";
            default:
                return "travelItinerary";
        }
    }
    return "travelItinerary";
}
