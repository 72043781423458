export const countryCodes = [
    "+1", // United States, Canada
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+33", // France
    "+34", // Spain
    "+39", // Italy
    "+41", // Switzerland
    "+43", // Austria
    "+44", // United Kingdom
    "+45", // Denmark
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+49", // Germany
    "+61", // Australia
    "+64", // New Zealand
    "+81", // Japan
    "+351", // Portugal
    "+352", // Luxembourg
    "+357", // Cyprus
    "+358", // Finland
    "+370", // Lithuania
    "+371", // Latvia
    "+372", // Estonia
    "+385", // Croatia
    "+386", // Slovenia
    "+420", // Czech Republic
    "+421", // Slovakia
];

export const defaultCountryCode = "+31";

export default { countryCodes, defaultCountryCode };
