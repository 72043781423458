/* eslint-disable no-underscore-dangle */
import { Timestamp } from "@firebase/firestore";
import { FirebaseTimestamp } from "../../../lmt/src/common/types/FirebaseTimestamp";

export function timestampFromDate(date: Date): Timestamp {
    return Timestamp.fromDate(date);
}

export function firebaseTimestampFromDate(date: Date): FirebaseTimestamp {
    const ts = Timestamp.fromDate(date);
    return {
        nanoseconds: ts.nanoseconds,
        seconds: ts.seconds,
    };
}

export function toTimestamp(firebaseTimestamp: FirebaseTimestamp): Timestamp {
    if (
        firebaseTimestamp.seconds !== undefined &&
        firebaseTimestamp.nanoseconds !== undefined
    )
        return new Timestamp(
            firebaseTimestamp.seconds,
            firebaseTimestamp.nanoseconds,
        );
    if (
        firebaseTimestamp._seconds !== undefined &&
        firebaseTimestamp._nanoseconds !== undefined
    )
        return new Timestamp(
            firebaseTimestamp._seconds,
            firebaseTimestamp._nanoseconds,
        );
    throw Error(`Invalid timestamp ${JSON.stringify(firebaseTimestamp)}`);
}

export function fromTimestampToDate(timestampStruct: FirebaseTimestamp): Date {
    return toTimestamp(timestampStruct).toDate();
}

export function fromTimestampToIsoDateString(
    timestampStruct: FirebaseTimestamp,
): string {
    return toTimestamp(timestampStruct).toDate().toISOString();
}
