import { z } from "zod";
import {
    AgentId,
    AlbertineServiceAgentId,
    FirebaseId,
    MemberId,
} from "../validate";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { MessageDoc } from "./Message";

export const ConversationUnreadMessage = z.object({
    request: FirebaseId.nullable().optional(),
    message: FirebaseId,
});
export type ConversationUnreadMessage = z.infer<
    typeof ConversationUnreadMessage
>;
export const ConversationUnreadMessages = z.array(ConversationUnreadMessage);
export type ConversationUnreadMessages = z.infer<
    typeof ConversationUnreadMessages
>;

export const UnseenReactions = z.array(FirebaseId);

export const ConversationDoc = z.object({
    createdAt: FirebaseTimestamp.nullable(),
    lastUpdatedAt: FirebaseTimestamp.optional(),
    lastMessage: MessageDoc.optional(),
    lastMessageMemberReactedTo: MessageDoc.optional(),
    lastMessageId: FirebaseId.optional(),
    lastMessageByAgentAgentId: AgentId.or(AlbertineServiceAgentId)
        .nullable()
        .optional(),
    lastMessageByAgentAt: FirebaseTimestamp.nullable().optional(),
    lastMessageByMemberAt: FirebaseTimestamp.nullable().optional(),
    lastSeenMessageDateByAgent: FirebaseTimestamp.nullable().optional(),
    lastSeenMessageDateByMember: FirebaseTimestamp.nullable().optional(),
    member: MemberId,
    title: z.string().optional(),
    unreadMessagesByMember: ConversationUnreadMessages.optional(),
    unreadMessagesByAgent: ConversationUnreadMessages.optional(),
    unreadMessagesCountByAgent: z.number().optional(),
    unreadMessagesCountByMember: z.number().optional(),
    agentUnseenReactions: UnseenReactions.optional(),
    memberLastReactionTimestamp: FirebaseTimestamp.nullable().optional(),
    hasAgentSentMessages: z.boolean().optional(),
    hasMemberSentMessages: z.boolean().optional(),
});

export const Conversation = z.object({ id: FirebaseId }).and(ConversationDoc);

export type ConversationDoc = z.infer<typeof ConversationDoc>;
export type Conversation = z.infer<typeof Conversation>;

export function isConversationDoc(
    possibleConversation: any,
): possibleConversation is ConversationDoc {
    return ConversationDoc.safeParse(possibleConversation).success;
}

export const MarkConversationMessagesReadPayload = z.object({
    conversationId: FirebaseId,
    requestId: FirebaseId.optional().nullable(),
});
export type MarkConversationMessagesReadPayload = z.infer<
    typeof MarkConversationMessagesReadPayload
>;
