import React from "react";
import { Lib } from "albertine-shared-web";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import "./Category.css";

type Props = {
    categories: DynamicTheme[];
};

function Category(props: Props) {
    const { categories } = props;

    return (
        <Lib.Flex.Column>
            {categories.map((category) => (
                <Lib.Flex.Row
                    key={category.key}
                    className="web-lib__category-item"
                >
                    <Lib.Flex.Column className="web-lib__category-item-bg">
                        {category.image && (
                            <Lib.RemoteImage.Square.Large
                                src={category.image}
                                alt={category.name}
                                className="web-lib__category-image"
                            />
                        )}
                    </Lib.Flex.Column>
                    <Lib.Flex.Column justifyContent="space-between">
                        <div className="web-lib__category-item-name">
                            <Lib.Heading.H6.Bold>
                                {category.name}
                            </Lib.Heading.H6.Bold>
                        </div>
                        <Lib.Line.Dashed />
                    </Lib.Flex.Column>
                </Lib.Flex.Row>
            ))}
        </Lib.Flex.Column>
    );
}

export default Category;
