import React from "react";
import { Lib } from "albertine-shared-web";
import "./MemberInitials.css";
import { Member } from "../../../lmt/src/common/types/Member";

interface MemberInitialsProps {
    member: Member;
}

function MemberInitials(props: MemberInitialsProps) {
    const { member } = props;
    const fullName = `${member.firstName} ${member.lastName}`;
    const initials = fullName
        ? Lib.Utils.TextFormatter.member.nameInitials(fullName)
        : "";

    return (
        <Lib.Flex.Column
            justifyContent="center"
            alignItems="center"
            className="web-lib__member-initials__background"
        >
            <Lib.Heading.H1.Bold className="web-lib__member-initials__text">
                {initials}
            </Lib.Heading.H1.Bold>
        </Lib.Flex.Column>
    );
}

export default MemberInitials;
