import { chatMessagesKey, getLocalStorageData } from "./localStorage.util";
import { triggerLocalStorageChange } from "./localStorage.listeners.util";
import {
    LocalStorageChatMessage,
    LocalStorageChatMessages,
    MessageSendingStatus,
} from "../../../lmt/src/common/types/Message";

export function setLocalChatMessages(chatMessages: LocalStorageChatMessages) {
    localStorage.setItem(chatMessagesKey, JSON.stringify(chatMessages));
    triggerLocalStorageChange(chatMessagesKey, chatMessages);
}

export function getLocalChatMessages(): LocalStorageChatMessages {
    return getLocalStorageData(chatMessagesKey, LocalStorageChatMessages) || [];
}

export function addLocalChatMessage(chatMessage: LocalStorageChatMessage) {
    const chatMessages = getLocalChatMessages();
    const newChatMessages = [...chatMessages, chatMessage];
    setLocalChatMessages(newChatMessages);
}

export function updateLocalChatMessage(chatMessage: LocalStorageChatMessage) {
    const chatMessages = getLocalChatMessages();
    const newChatMessages = chatMessages.map((message) =>
        message.message.sendingId === chatMessage.message.sendingId
            ? chatMessage
            : message,
    );
    setLocalChatMessages(newChatMessages);
}

function hasLocalChatMessage(sendingId: string) {
    const chatMessages = getLocalChatMessages();
    return chatMessages.some(
        (message) => message.message.sendingId === sendingId,
    );
}

export function upsertLocalChatMessage(chatMessage: LocalStorageChatMessage) {
    if (hasLocalChatMessage(chatMessage.message.sendingId)) {
        updateLocalChatMessage(chatMessage);
    } else {
        addLocalChatMessage(chatMessage);
    }
}

export function removeLocalChatMessageBySendingId(
    sendingId: string | string[],
) {
    const chatMessages = getLocalChatMessages();
    if (!chatMessages) return;
    if (chatMessages.length === 0) return;
    const sendingIds = Array.isArray(sendingId) ? sendingId : [sendingId];
    if (
        !chatMessages
            .map((message) => message.message.sendingId)
            .some((id) => sendingIds.includes(id))
    )
        return;
    const newChatMessages = chatMessages.filter(
        (message) => !sendingIds.includes(message.message.sendingId),
    );
    setLocalChatMessages(newChatMessages);
}

function setLocalChatMessagesToFailed() {
    const chatMessages = getLocalChatMessages();
    if (!chatMessages) return;
    if (chatMessages.length === 0) return;
    if (
        chatMessages.some(
            (message) =>
                message.sendingStatus === MessageSendingStatus.Values.sending,
        )
    ) {
        const newChatMessages = chatMessages.map((message) => ({
            message: message.message,
            sendingStatus: MessageSendingStatus.Values.failed,
        }));
        setLocalChatMessages(newChatMessages);
    }
}

// On reload invalidate all non-sent messages
setLocalChatMessagesToFailed();
