import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Screen from "./Screen";
import {
    getCurrentNotificationPermission,
    NotificationResult,
    requestNotificationPermission,
} from "../api/notifications";
import { useRoutes } from "../routes";
import Permission from "../components/Permission";
import "./NotificationPermission.css";

async function handleNotifications(
    callback: (permission: NotificationResult) => void,
) {
    const permission = await requestNotificationPermission();
    callback(permission);
}

function NotificationPermission() {
    const t = useAlbertineTranslation();
    const navigate = useNavigate();
    const { url } = useRoutes();
    const { tenant } = useTenant();

    const [notificationPermission, setNotificationPermission] =
        useState<NotificationResult>(getCurrentNotificationPermission());

    useEffect(() => {
        if (
            notificationPermission === "granted" ||
            notificationPermission === "denied"
        ) {
            navigate(url.bookings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationPermission]);

    return (
        <Screen.StartBg01
            className="notification_permission__container"
            textColorInversable={tenant === "hugoboss"}
        >
            <Lib.Flex.Column gap="value32">
                <Permission
                    tagText={t("notifications__tag")}
                    titleText={t("notifications__title")}
                    descriptionText={t("notifications__description")}
                    buttonText={t("notifications__button")}
                    onButton={() =>
                        handleNotifications(setNotificationPermission)
                    }
                />
            </Lib.Flex.Column>
        </Screen.StartBg01>
    );
}

export default NotificationPermission;
