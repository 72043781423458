import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import React from "react";
import "./ArticleMapDetails.css";

interface Props {
    details: {
        address: string | null;
        website: string | null;
        name: string;
        placeId: string | null;
    };
}

function ArticleMapDetails(props: Props) {
    const { details } = props;
    const { address, website, name, placeId } = details;
    const t = useAlbertineTranslation();
    const googleMapsLink = placeId
        ? `https://www.google.com/maps/place/?q=place_id:${placeId}`
        : null;
    return (
        <Lib.Flex.Column
            className="article-map-details__wrapper text-color-inversable"
            gap="value24"
        >
            <Lib.Flex.Column
                gap="value4"
                className="article-map-details__section"
            >
                <Lib.Label.XSmall.MediumBold.TextColor01 transform="uppercase">
                    {name}
                </Lib.Label.XSmall.MediumBold.TextColor01>

                {address && (
                    <Lib.Paragraph.Small.Reqular.TextColor01>
                        {address}
                    </Lib.Paragraph.Small.Reqular.TextColor01>
                )}
                {googleMapsLink ? (
                    <Lib.TextLink href={googleMapsLink}>
                        {t("article_map_visit_google_maps")}
                    </Lib.TextLink>
                ) : null}
            </Lib.Flex.Column>

            {website && (
                <Lib.Flex.Column
                    gap="value8"
                    className="article-map-details__section"
                >
                    <Lib.Label.XSmall.MediumBold.TextColor01 transform="uppercase">
                        {t("article_map_learn_more")}
                    </Lib.Label.XSmall.MediumBold.TextColor01>
                    <Lib.TextLink href={website}>
                        {t("article_map_visit_website")}
                    </Lib.TextLink>
                </Lib.Flex.Column>
            )}
        </Lib.Flex.Column>
    );
}

export default ArticleMapDetails;
