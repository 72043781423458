import React from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import { fromTimestampToDate } from "../utils/timestamp.util";
import { useScreenStack } from "../context/screenStack";
import "./UpcomingBookings.css";

interface Props {
    bookings: MemberBooking[];
}

function UpcomingBookings(props: Props) {
    const { bookings } = props;
    const t = useAlbertineTranslation();
    const { openBooking } = useScreenStack();

    return (
        <Lib.Flex.Column gap="value24" className="upcoming-bookings">
            {bookings.map((booking: MemberBooking) => {
                const {
                    id,
                    imageURL,
                    croppedImageURL,
                    reservationStatus,
                    title,
                    startTime,
                    startTimezone,
                    endTime,
                    endTimezone,
                    rooms,
                    numberOfGuests,
                    numberOfNights,
                } = booking;

                const startTimeString = startTime
                    ? fromTimestampToDate(startTime).toISOString()
                    : undefined;
                const endTimeString = endTime
                    ? fromTimestampToDate(endTime).toISOString()
                    : undefined;

                const { bookingStartTime, bookingEndTime } =
                    Lib.Utils.TextFormatter.booking.time(
                        startTimeString,
                        startTimezone || undefined,
                        endTimeString,
                        endTimezone || undefined,
                    );

                const details = [
                    Lib.Utils.TextFormatter.booking.numberOfNights(
                        numberOfNights || undefined,
                    ),
                    Lib.Utils.TextFormatter.booking.numberOfGuests(
                        numberOfGuests || undefined,
                    ),
                    rooms,
                ].filter(Boolean);

                return (
                    <Lib.Flex.Column
                        className="upcoming-bookings__booking"
                        gap="value16"
                        key={id}
                        onClick={() => openBooking(id)}
                    >
                        <Lib.Flex.Column className="upcoming_booking__image_container">
                            <Lib.RemoteImage.Card.Wide
                                src={croppedImageURL || imageURL}
                                alt={title || ""}
                            />

                            <Lib.Tag.Primary className="upcoming_booking__tag">
                                {reservationStatus === "confirmed" ? (
                                    <Lib.Icon.Check.Small className="upcoming_booking__icon" />
                                ) : (
                                    <Lib.Icon.AutoRenew.Small className="upcoming_booking__icon" />
                                )}

                                {t(`bookings__${reservationStatus}`)}
                            </Lib.Tag.Primary>
                        </Lib.Flex.Column>

                        <Lib.Flex.Column gap="value8">
                            <Lib.Heading.H2.XBold>{title}</Lib.Heading.H2.XBold>

                            <span>
                                {bookingStartTime && (
                                    <Lib.Label.Medium.MediumBold.TextColor02 transform="uppercase">
                                        {endTime
                                            ? `${bookingStartTime} - `
                                            : bookingStartTime}
                                    </Lib.Label.Medium.MediumBold.TextColor02>
                                )}

                                {bookingEndTime && (
                                    <Lib.Label.Medium.MediumBold.TextColor02 transform="uppercase">
                                        {bookingEndTime}
                                    </Lib.Label.Medium.MediumBold.TextColor02>
                                )}

                                {details.length !== 0 && (
                                    <Lib.Label.Medium.MediumBold.TextColor02 transform="uppercase">
                                        {` · ${details.join(" · ")}`}
                                    </Lib.Label.Medium.MediumBold.TextColor02>
                                )}
                            </span>
                        </Lib.Flex.Column>
                    </Lib.Flex.Column>
                );
            })}
        </Lib.Flex.Column>
    );
}

export default UpcomingBookings;
