import { sharedKeys } from "albertine-shared-web";
import { z } from "zod";
import { isLocalDevelopmentEnvironment } from "./Firebase";

const Manifest = z.object({
    [sharedKeys.cssFileManifestKey]: z.object({ css: z.array(z.string()) }),
});

async function getBundledCssUrl(sharedWeb: URL): Promise<URL | null> {
    const url = new URL("/manifest.json", sharedWeb);

    console.log("Fetching manifest from shared web", url.toString());

    const response = await fetch(url);
    if (!response.ok) {
        console.error("Failed to fetch manifest from shared web", { url });
        return null;
    }

    const manifest = Manifest.parse(await response.json());
    const cssFileUrl = manifest[sharedKeys.cssFileManifestKey].css;
    if (cssFileUrl.length === 0) {
        console.error("Failed to find css file url from manifest", manifest);
        return null;
    }

    if (cssFileUrl.length > 1) {
        console.warn(
            "Found more than one css file url from manifest, using the first one",
            manifest,
        );
    }

    return new URL(cssFileUrl[0], sharedWeb);
}

export default async function injectSharedWebCss() {
    const sharedWebHosting = import.meta.env.VITE_SHARED_WEB_HOSTING;
    const url = new URL(sharedWebHosting);
    if (isLocalDevelopmentEnvironment) {
        url.hostname = document.location.hostname;
    }

    const cssUrl = await getBundledCssUrl(url);
    if (!cssUrl) {
        console.error("Failed to get css url from shared web", {
            sharedWebHosting,
            url,
        });
        return;
    }

    console.log("Injecting shared web css", cssUrl.toString());
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = cssUrl.toString();
    document.head.prepend(link);
}
