import { z } from "zod";
import { AgentId } from "../validate";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { NotificationDeliveryMethod } from "./NotificationDeliveryMethod";

export const NotificationAttempt = z.object({
    sentAt: FirebaseTimestamp,
    sentBy: AgentId,
    notificationMethod: NotificationDeliveryMethod,
});

export const NotificationAttempts = z.array(NotificationAttempt);

export type NotificationAttempt = z.infer<typeof NotificationAttempt>;
export type NotificationAttempts = z.infer<typeof NotificationAttempts>;
