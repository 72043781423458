import {
    defaultServiceLanguage,
    Lib,
    ServiceLanguageMap,
    useAlbertineTranslation,
    useTenant,
    getUILanguage,
    ServiceLanguage,
} from "albertine-shared-web";
import React, { useState } from "react";
import classNames from "classnames";
import { useAuth } from "../context/auth";
import Screen from "./Screen";
import LoadingScreen from "./Loading";
import ListenToMember from "../loaders/ListenToMember";
import { updateMemberData } from "../api/firestore";
import { fromTimestampToDate } from "../utils/timestamp.util";
import useStyleTransform from "../hooks/useStyleTransform";
import AlbertineModal from "../components/AlbertineModal";
import Header, { BackButton } from "../components/Header";
import { useScreenStack } from "../context/screenStack";
import "./Profile.css";
import MemberInitials from "../components/MemberInitials";
import { Member } from "../../../lmt/src/common/types/Member";

type ProfileProps = {
    currentMemberId: string;
};

function Profile(props: ProfileProps) {
    const { currentMemberId } = props;

    const { logout } = useAuth();
    const t = useAlbertineTranslation();
    const { tenant } = useTenant();
    const currentYear = new Date().getFullYear();
    const fadeToTopRef = useStyleTransform({
        initialOpacity: 1,
        finalOpacity: 0,
        transformDistance: 100,
        transitionDuration: 0.2,
        scrollThreshold: 300,
    });

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [selectedLanguage, setSelectedLanguage] = useState<ServiceLanguage>(
        getUILanguage().lang,
    );
    const { openExternalWebsite } = useScreenStack();

    const navigateToTermsAndConditions = () => {
        openExternalWebsite("terms");
    };

    const navigateToPrivacyPolicy = () => {
        openExternalWebsite("privacy");
    };

    const changeServiceLanguage = async (language: ServiceLanguage) => {
        await updateMemberData({
            serviceLanguage: language,
        });
        setModalOpen(false);
    };

    const formatMemberName = (member: Member): string => {
        if (member.title) {
            return `${member.title} ${member.firstName} ${member.lastName}`;
        }

        return `${member.firstName} ${member.lastName}`;
    };

    const showMembership = tenant === "albertine";

    return (
        <Screen.ProfileBg02>
            <ListenToMember
                memberId={currentMemberId}
                isLoading={LoadingScreen}
            >
                {(member) => (
                    <>
                        <Header title={t("profile__profile")} type="booking" />
                        <Lib.Background.ProfileBg01
                            textColorInversable={tenant === "hugoboss"}
                        >
                            <BackButton />
                            <Lib.ContentCenter.Large>
                                <Lib.Flex.Column
                                    className="profile__header"
                                    justifyContent="center"
                                    alignItems="center"
                                    ref={fadeToTopRef}
                                    gap="value16"
                                >
                                    <Lib.Flex.Row
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <MemberInitials member={member} />
                                    </Lib.Flex.Row>
                                    <Lib.Flex.Row
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Lib.Heading.H2.XBold>
                                            {formatMemberName(member)}
                                        </Lib.Heading.H2.XBold>
                                    </Lib.Flex.Row>
                                </Lib.Flex.Column>
                            </Lib.ContentCenter.Large>
                        </Lib.Background.ProfileBg01>

                        <Lib.Flex.Column
                            gap={showMembership ? "value32" : "value4"}
                            className={classNames({
                                profile__content: showMembership,
                            })}
                        >
                            <Lib.ContentCenter.Large>
                                {showMembership && (
                                    <Lib.Background.ProfileBg04
                                        textColorInversable
                                        className="profile__membership-background"
                                    >
                                        <Lib.Flex.Row
                                            justifyContent="center"
                                            alignItems="center"
                                            className="profile__membership-type-label"
                                        >
                                            <Lib.Heading.H5.Bold>
                                                {t("profile__membership")}
                                            </Lib.Heading.H5.Bold>
                                        </Lib.Flex.Row>

                                        <Lib.Flex.Row
                                            justifyContent="center"
                                            alignItems="center"
                                            className="profile__membership-since-label"
                                        >
                                            <Lib.Label.Medium.MediumBold.TextColor01>
                                                {member.createdAt.seconds &&
                                                    t("profile__member-since", {
                                                        memberSince:
                                                            Lib.Utils.TextFormatter.member.membership(
                                                                fromTimestampToDate(
                                                                    member.createdAt,
                                                                ),
                                                                "monthYear",
                                                            ),
                                                    })}
                                            </Lib.Label.Medium.MediumBold.TextColor01>
                                        </Lib.Flex.Row>

                                        <Lib.Flex.Row
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Lib.Label.Medium.TextColor02>
                                                {member.membership?.endDate &&
                                                    t(
                                                        "profile__membership-renewal",
                                                        {
                                                            membershipRenewal:
                                                                Lib.Utils.TextFormatter.member.membership(
                                                                    member
                                                                        .membership
                                                                        .endDate,
                                                                    "dayMonthYear",
                                                                ),
                                                        },
                                                    )}
                                            </Lib.Label.Medium.TextColor02>
                                        </Lib.Flex.Row>
                                    </Lib.Background.ProfileBg04>
                                )}
                            </Lib.ContentCenter.Large>

                            <Lib.ContentCenter.Large>
                                <Lib.Flex.Column gap="value16">
                                    {/* TODO: Feedback */}
                                    {/* <Lib.Line.Dashed />

                                <Lib.Flex.Row justifyContent="space-between" className="profile__link" onClick={() => {}}>
                                    <Lib.Heading.H6.Bold>
                                        {t("profile__feedback")}
                                    </Lib.Heading.H6.Bold>

                                    <Lib.Icon.ArrowForward.Medium />
                                </Lib.Flex.Row> */}

                                    {tenant === "hugoboss" && (
                                        <Lib.Flex.Row
                                            justifyContent="space-between"
                                            className="profile__link"
                                            onClick={openModal}
                                        >
                                            <Lib.Flex.Column>
                                                <Lib.Heading.H6.Bold>
                                                    {t(
                                                        "profile__service-language",
                                                    )}
                                                </Lib.Heading.H6.Bold>
                                            </Lib.Flex.Column>

                                            <Lib.Flex.Column>
                                                <Lib.Flex.Row
                                                    justifyContent="end"
                                                    gap="value8"
                                                >
                                                    <Lib.Paragraph.Small.Reqular>
                                                        {member.serviceLanguage
                                                            ? ServiceLanguageMap[
                                                                  member
                                                                      .serviceLanguage
                                                              ]
                                                            : ServiceLanguageMap[
                                                                  defaultServiceLanguage
                                                              ]}
                                                    </Lib.Paragraph.Small.Reqular>
                                                    <Lib.Icon.ArrowForward.Medium />
                                                </Lib.Flex.Row>
                                            </Lib.Flex.Column>
                                        </Lib.Flex.Row>
                                    )}

                                    <Lib.Line.Dashed />

                                    <Lib.Flex.Row
                                        justifyContent="space-between"
                                        className="profile__link"
                                        onClick={navigateToTermsAndConditions}
                                    >
                                        <Lib.Heading.H6.Bold>
                                            {t("profile__terms-and-conditions")}
                                        </Lib.Heading.H6.Bold>

                                        <Lib.Icon.ArrowForward.Medium />
                                    </Lib.Flex.Row>

                                    <Lib.Line.Dashed />

                                    <Lib.Flex.Row
                                        justifyContent="space-between"
                                        className="profile__link"
                                        onClick={navigateToPrivacyPolicy}
                                    >
                                        <Lib.Heading.H6.Bold>
                                            {t("profile__privacy-policy")}
                                        </Lib.Heading.H6.Bold>

                                        <Lib.Icon.ArrowForward.Medium />
                                    </Lib.Flex.Row>

                                    <Lib.Line.Dashed />

                                    <Lib.Flex.Row
                                        justifyContent="center"
                                        alignItems="center"
                                        className="profile__logout"
                                    >
                                        <Lib.Button.Primary
                                            label={t("profile__logout")}
                                            onClick={logout}
                                            className="profile__logout-btn"
                                        />
                                    </Lib.Flex.Row>

                                    <Lib.Flex.Row
                                        justifyContent="center"
                                        alignItems="center"
                                        className="profile__footer"
                                    >
                                        <Lib.Label.Small.TextColor02>
                                            {t("profile__footer-copyright", {
                                                currentYear,
                                            })}
                                        </Lib.Label.Small.TextColor02>
                                    </Lib.Flex.Row>
                                </Lib.Flex.Column>
                            </Lib.ContentCenter.Large>
                        </Lib.Flex.Column>

                        {/* Service language change modal */}
                        <AlbertineModal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                        >
                            <Lib.Tag.Primary className="profile__service-language__tag">
                                {t("profile__change-language")}
                            </Lib.Tag.Primary>
                            <Lib.Heading.H2.XBold>
                                {t("profile__service-language")}
                            </Lib.Heading.H2.XBold>
                            <Lib.Line.Dashed />
                            <Lib.Flex.Column gap="value32">
                                <Lib.Flex.Row
                                    gap="value8"
                                    onClick={() => setSelectedLanguage("en")}
                                    className="profile__service-language"
                                >
                                    {selectedLanguage === "en" ? (
                                        <>
                                            <Lib.Icon.Check.Small />
                                            <Lib.Label.Medium.Bold>
                                                {t("profile__english")}
                                            </Lib.Label.Medium.Bold>
                                        </>
                                    ) : (
                                        <>
                                            <div className="profile__service-language__checkbox-placeholder" />
                                            <Lib.Label.Medium.Reqular>
                                                {t("profile__english")}
                                            </Lib.Label.Medium.Reqular>
                                        </>
                                    )}
                                </Lib.Flex.Row>
                                <Lib.Flex.Row
                                    gap="value8"
                                    onClick={() => setSelectedLanguage("de")}
                                    className="profile__service-language"
                                >
                                    {selectedLanguage === "de" ? (
                                        <>
                                            <Lib.Icon.Check.Small />
                                            <Lib.Label.Medium.Bold>
                                                {t("profile__german")}
                                            </Lib.Label.Medium.Bold>
                                        </>
                                    ) : (
                                        <>
                                            <div className="profile__service-language__checkbox-placeholder" />
                                            <Lib.Label.Medium.Reqular>
                                                {t("profile__german")}
                                            </Lib.Label.Medium.Reqular>
                                        </>
                                    )}
                                </Lib.Flex.Row>
                                <Lib.Flex.Row
                                    gap="value8"
                                    onClick={() => setSelectedLanguage("fr")}
                                    className="profile__service-language"
                                >
                                    {selectedLanguage === "fr" ? (
                                        <>
                                            <Lib.Icon.Check.Small />
                                            <Lib.Label.Medium.Bold>
                                                {t("profile__french")}
                                            </Lib.Label.Medium.Bold>
                                        </>
                                    ) : (
                                        <>
                                            <div className="profile__service-language__checkbox-placeholder" />
                                            <Lib.Label.Medium.Reqular>
                                                {t("profile__french")}
                                            </Lib.Label.Medium.Reqular>
                                        </>
                                    )}
                                </Lib.Flex.Row>
                            </Lib.Flex.Column>
                            <Lib.Line.Dashed />
                            <Lib.Button.Primary
                                className="albertine-modal__button"
                                onClick={() =>
                                    changeServiceLanguage(selectedLanguage)
                                }
                            >
                                {t("profile__continue-in")}{" "}
                                {ServiceLanguageMap[selectedLanguage]}
                            </Lib.Button.Primary>
                        </AlbertineModal>
                    </>
                )}
            </ListenToMember>
        </Screen.ProfileBg02>
    );
}

export default Profile;
