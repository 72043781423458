import { z } from "zod";

export function isTypeOf<T>(
    possible: any,
    schema: z.ZodSchema<T>,
): possible is T {
    return schema.safeParse(possible).success;
}

export function includeTypeOf<T>(schema: z.ZodSchema<T>) {
    // eslint-disable-next-line func-names
    return function (possible: any): possible is T {
        return isTypeOf(possible, schema);
    };
}
