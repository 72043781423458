import { z } from "zod";

export const MessageAnalysisStatus = z.enum([
    "pending",
    "analysing",
    "analysed",
    "error-analysing",
    "generating-responses",
    "completed",
    "error-generating-responses",
]);

export type MessageAnalysisStatus = z.infer<typeof MessageAnalysisStatus>;

export function isTerminalStatus(status: MessageAnalysisStatus): boolean {
    const terminalStatuses: MessageAnalysisStatus[] = [
        "error-analysing",
        "completed",
        "error-generating-responses",
    ];
    return terminalStatuses.includes(status);
}

export function isStillEvaluating(status: MessageAnalysisStatus): boolean {
    const evaluatingStatuses: MessageAnalysisStatus[] = [
        "analysing",
        "analysed",
        "generating-responses",
    ];
    return evaluatingStatuses.includes(status);
}
