import { z } from "zod";

export const archiveReasonLabels: [string, string][] = [
    ["inactive", "Inactive"],
    ["trial_ended", "Trial ended"],
    ["subscription_canceled", "Subscription canceled"],
    ["android_user", "Android user"],
    ["payment_overdue", "Payment overdue"],
    ["member_misconduct", "Member misconduct"],
    ["other", "Other"],
];

export const archiveReasons: readonly [string, ...string[]] =
    archiveReasonLabels.map((l) => l[0]) as unknown as readonly [
        string,
        ...string[],
    ];

export const ArchiveReason = z.enum(archiveReasons);
export type ArchiveReason = z.infer<typeof ArchiveReason>;
