import {
    DocumentData,
    DocumentReference,
    onSnapshot,
    Query,
    Unsubscribe,
} from "firebase/firestore";
import { z } from "zod";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

function listenToDocument<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
    listener: (_: T | undefined | null) => void,
): Unsubscribe {
    return onSnapshot(document, (docSnapshot) => {
        if (!docSnapshot.exists()) return;

        const parsedDoc = schema.safeParse({
            ...docSnapshot.data(),
            id: docSnapshot.id,
        });

        if (parsedDoc.success) {
            listener(parsedDoc.data);
        } else {
            // TODO: send parsedMemberDoc.error to the server
            listener(null);
        }
    });
}

export function listenToDocumentToState<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
    setState: SetState<T | undefined | null>,
) {
    return listenToDocument<T>(document, schema, (doc) => {
        setState(doc);
    });
}

export function listenToQuery<T>(
    query: Query<DocumentData>,
    listener: (_: T) => void,
): Unsubscribe {
    return onSnapshot(query, (querySnapshot) => {
        listener(
            querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            })) as unknown as T,
        );
    });
}

export function listenToQueryToState<T>(
    query: Query<DocumentData>,
    setState: SetState<T[] | undefined>,
) {
    return listenToQuery<T[]>(query, (documents) => {
        setState([...documents]);
    });
}
