import * as z from "zod";

export const CatalogSourceKey = z.enum([
    "unknown",
    "insider",
    "proposal",
    "supplier",
    "albertine",
    "other",
]);

export type CatalogSourceKey = z.infer<typeof CatalogSourceKey>;

export const CatalogSources: { [key in CatalogSourceKey]: string } = {
    unknown: "Unknown",
    insider: "Insider",
    proposal: "Proposal",
    supplier: "Supplier",
    albertine: "Albertine",
    other: "Other",
} as const;
