import { useLocation, useMatch, useParams } from "react-router-dom";
import { OnboardingSteps } from "./types/OnboardingSteps";

export const mainNavigationRoutes = {
    discover: "discover",
    /* "/bookings" and "/proposal" are reserved routes for public bookings and proposals at the moment */
    bookings: "my-bookings",
    conversations: "conversations",
    onboarding: "onboarding",
    onboardingStep: "onboardingStep",
};

export const routes = {
    schemas: {
        root: "/",
        conversations: `/${mainNavigationRoutes.conversations}`,
        discover: `/${mainNavigationRoutes.discover}`,
        bookings: `/${mainNavigationRoutes.bookings}`,
        notificationPermission: `/notification-permissions`,
        onboarding: `/${mainNavigationRoutes.onboarding}`,
        onboardingStep: `/${mainNavigationRoutes.onboarding}/:onboardingStep`,
    },
    url: {
        root: `/`,
        conversations: `/${mainNavigationRoutes.conversations}`,
        discover: `/${mainNavigationRoutes.discover}`,
        bookings: `/${mainNavigationRoutes.bookings}`,
        notificationPermission: `/notification-permissions`,
        onboarding: `/${mainNavigationRoutes.onboarding}`,
        onboardingStep: (onboardingStep: OnboardingSteps) =>
            `/${mainNavigationRoutes.onboarding}/${onboardingStep}`,
    },
};

export function useRoutes() {
    const location = useLocation();
    const match = location.pathname.split("/");
    const mainRouteFromUrl = match ? match[1] : undefined;
    const bookingsMatch = useMatch(routes.schemas.bookings);
    const conversationsMatch = useMatch(routes.schemas.conversations);
    const discoverMatch = useMatch(routes.schemas.discover);
    const onboardingStepMatch = useMatch(routes.schemas.onboardingStep);
    const notificationPermissionMatch = useMatch(
        routes.schemas.notificationPermission,
    );

    return {
        currentNavigationRoute: mainRouteFromUrl,
        hideNavigation:
            bookingsMatch ||
            conversationsMatch ||
            discoverMatch ||
            onboardingStepMatch ||
            notificationPermissionMatch,
        ...routes,
    };
}

export function WithOnboardingStepParams(props: {
    children: (onboardingStep: OnboardingSteps) => JSX.Element;
}) {
    const { children } = props;
    const { onboardingStep } = useParams();
    const parsedStep = OnboardingSteps.safeParse(onboardingStep);

    return parsedStep.success ? children(parsedStep.data) : undefined;
}
