import React from "react";
import { PublicBooking } from "albertine-shared-web/types/PublicBooking";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import LoadBooking from "./LoadBooking";
import { memberBookingToPublicBooking } from "../utils/bookings.util";
import { Attachment } from "../../../lmt/src/common/types/Attachment";

type Props = {
    memberId: string;
    bookingId: string;
    children: (
        publicBooking: PublicBooking | undefined,
        booking: MemberBooking | undefined,
    ) => React.ReactNode;
};

export function transformBookingToPublic(
    booking: MemberBooking,
): PublicBooking {
    return memberBookingToPublicBooking({
        publicId: booking.id,
        memberBooking: booking,
        publicAttachments: (booking.attachments || []).map(
            (attachment: Attachment) => ({
                name: attachment.filename || "",
                url: attachment.url,
            }),
        ),
        publicShareFunctionUrl: undefined,
    });
}

function LoadPublicBooking(props: Props) {
    const { memberId, bookingId, children } = props;

    return (
        <LoadBooking memberId={memberId} bookingId={bookingId}>
            {(booking) => {
                if (!booking) return children(undefined, undefined);
                return children(transformBookingToPublic(booking), booking);
            }}
        </LoadBooking>
    );
}

export default LoadPublicBooking;
