import { z } from "zod";
import { CatalogItemId } from "../CatalogItem";
import { Price } from "../currency";
import {
    AgentId,
    FirebaseId,
    GeoPoint,
    MediumLongString,
    MemberId,
    PercentCrop,
    PixelCrop,
    Rating,
} from "../validate";
import { BookingAttachment, ImageAttachment } from "./Attachment";
import { BookingReservationStatus } from "./BookingReservationStatus";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { OfferingCategory } from "./OfferingCategory";

const BookingRequired = z.object({
    catalogItem: CatalogItemId.optional().nullable(),
    createdBy: AgentId,
    member: MemberId,
    request: FirebaseId,

    title: z.string().nullable(),
    archived: z.boolean(),
});

export const BookingMeta = z.object({
    createdAt: FirebaseTimestamp,
    updatedAt: FirebaseTimestamp.nullable().optional(),
    updatedBy: AgentId.optional().nullable(),

    prebookedAt: FirebaseTimestamp.optional().nullable(),
    confirmedAt: FirebaseTimestamp.optional().nullable(),
    cancelledAt: FirebaseTimestamp.optional().nullable(),
    calendarEventId: z.string().nullable(),
});

export type BookingMeta = z.infer<typeof BookingMeta>;

export const BookingUpdatables = z.object({
    title: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    website: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    address: z.string().optional().nullable(),
    offeringCategory: OfferingCategory.optional().nullable(),
    googleUrl: z.string().optional().nullable(),
    geoPoint: GeoPoint.optional().nullable(),
    numberOfGuests: z.number().optional().nullable(),
    numberOfNights: z.number().optional().nullable(),
    rooms: z.string().optional().nullable(),
    price: Price.optional().nullable(),
    priceQuote: z.enum(["final", "estimate"]).optional().nullable(),
    paymentTo: z.enum(["albertine", "supplier"]).optional().nullable(),
    priceDetails: z.string().optional().nullable(),

    attachments: z.array(BookingAttachment).optional(),

    imageURL: z.string().optional().nullable(),
    absoluteCrop: PixelCrop.optional().nullable(),
    relativeCrop: PercentCrop.optional().nullable(),
    croppedImageURL: z.string().optional().nullable(),
    images: z.array(ImageAttachment).optional().nullable(),

    reference: z.string().optional().nullable(),
    reservationStatus: BookingReservationStatus.optional().nullable(),
    notesForMember: z.string().optional().nullable(),
    notesForMemberSenderId: AgentId.optional().nullable(),
    notesForMemberSenderName: z.string().optional().nullable(),

    suppliers: z.array(z.string()).optional().nullable(),
    supplierDetails: z.string().optional().nullable(),

    startTime: FirebaseTimestamp.optional().nullable(),
    startTimezone: z.string().optional().nullable(), // TODO: Create common type
    endTime: FirebaseTimestamp.optional().nullable(),
    endTimezone: z.string().optional().nullable(), // TODO: Create common type
    archived: z.boolean().optional(),
    reviewNeeded: z.boolean().optional().nullable(),

    headline: z.string().optional().nullable(),
    benefits: z.string().optional().nullable(),
    bookingPolicy: z.string().optional().nullable(),
});
export type BookingUpdatables = z.infer<typeof BookingUpdatables>;

export const BookingFeedback = z.object({
    bookingRating: Rating,
    bookingFeedback: MediumLongString.optional().nullable(),
    bookingFeedbackTimestamp: FirebaseTimestamp.optional().nullable(),
});
export type BookingFeedback = z.infer<typeof BookingFeedback>;

export const ProposalFeedbackEnum = z.enum(["like", "dislike"]);

export type ProposalFeedbackEnum = z.infer<typeof ProposalFeedbackEnum>;

export const ProposalFeedback = z.object({
    proposalFeedback: ProposalFeedbackEnum.optional().nullable(),
    proposalFeedbackTimestamp: FirebaseTimestamp.optional().nullable(),
});
export type ProposalFeedback = z.infer<typeof ProposalFeedback>;

const BookingId = z.object({ id: FirebaseId });

export const BookingDoc = BookingRequired.merge(BookingMeta)
    .merge(BookingUpdatables)
    .merge(ProposalFeedback)
    .merge(BookingFeedback);
export type BookingDoc = z.infer<typeof BookingDoc>;

export const BookingPayload = BookingRequired.merge(BookingUpdatables);
export type BookingPayload = z.infer<typeof BookingPayload>;

export const Booking = BookingId.merge(BookingDoc);
export type Booking = z.infer<typeof Booking>;

export const BookingInMessage = BookingId.merge(
    BookingRequired.pick({
        request: true,
        title: true,
    }),
).merge(
    BookingUpdatables.omit({
        supplierDetails: true,
    }),
);
export type BookingInMessage = z.infer<typeof BookingInMessage>;

export const BookingToConfirmInMemberMessage = BookingId.merge(
    BookingRequired.pick({
        title: true,
    }),
).merge(
    BookingUpdatables.omit({
        supplierDetails: true,
    }),
);
export type BookingToConfirmInMemberMessage = z.infer<
    typeof BookingToConfirmInMemberMessage
>;

export const parseBookingToMessage = (booking: Booking): BookingInMessage => {
    const bookingToMessage = BookingInMessage.parse(booking);
    if (bookingToMessage.croppedImageURL) {
        bookingToMessage.imageURL = booking.croppedImageURL;
    }
    return bookingToMessage;
};

export const CreateBookingRequest = BookingRequired.omit({
    createdBy: true,
    member: true,
}).merge(BookingUpdatables.omit({ offeringCategory: true }));
export type CreateBookingRequest = z.infer<typeof CreateBookingRequest>;

export const UpdateBookingRequest = BookingUpdatables.omit({
    offeringCategory: true,
}).merge(BookingId);
export type UpdateBookingRequest = z.infer<typeof UpdateBookingRequest>;

export const ManageBookingRequest = z.discriminatedUnion("action", [
    z.object({
        action: z.literal("update"),
        update: UpdateBookingRequest,
    }),
    z.object({
        action: z.literal("updateCalendarEvent"),
        booking: FirebaseId,
        isReSend: z.boolean(),
    }),
]);

export type ManageBookingRequest = z.infer<typeof ManageBookingRequest>;

const CreateBookingResponse = z.discriminatedUnion("success", [
    z.object({ success: z.literal(true), bookingId: FirebaseId }),
    z.object({ success: z.literal(false), error: z.string() }),
]);

export type CreateBookingResponse = z.infer<typeof CreateBookingResponse>;

export const CreateCopiedBooking = BookingRequired.merge(
    BookingUpdatables.omit({
        attachments: true,
        numberOfNights: true,
        rooms: true,
        reference: true,
        reservationStatus: true,
    }),
)
    .merge(
        BookingMeta.pick({
            createdAt: true,
            updatedAt: true,
        }),
    )
    .merge(
        z.object({
            reviewNeeded: z.literal(true),
        }),
    );
export type CreateCopiedBooking = z.infer<typeof CreateCopiedBooking>;

export const MemberBookingDoc = BookingDoc.pick({
    title: true,
    description: true,
    offeringCategory: true,
    imageURL: true,
    croppedImageURL: true,
    images: true,
    attachments: true,
    startTime: true,
    startTimezone: true,
    endTime: true,
    endTimezone: true,
    numberOfGuests: true,
    numberOfNights: true,
    website: true,
    address: true,
    phoneNumber: true,
    geoPoint: true,
    googleUrl: true,
    notesForMember: true,
    notesForMemberSenderId: true,
    notesForMemberSenderName: true,
    rooms: true,
    benefits: true,
    price: true,
    priceDetails: true,
    reference: true,
    request: true,
    bookingFeedbackTimestamp: true,
    bookingRating: true,
    bookingFeedback: true,
    headline: true,
    bookingPolicy: true,
    proposalFeedback: true,
    reservationStatus: true,
});
export type MemberBookingDoc = z.infer<typeof MemberBookingDoc>;

export const MemberBooking = z
    .object({ id: FirebaseId })
    .merge(MemberBookingDoc);
export type MemberBooking = z.infer<typeof MemberBooking>;

export function bookingCanHaveCalendarEvent(booking: Booking): boolean {
    return (
        booking.reservationStatus === "confirmed" &&
        !!booking.startTime &&
        !!booking.startTimezone &&
        !!booking.endTime &&
        !!booking.endTimezone
    );
}

export function isValidNumberOfGuests(value: string): boolean {
    return z.number().int().gte(1).safeParse(Number(value)).success;
}

export function isValidPrice(value: string): boolean {
    return z.number().gte(0).safeParse(Number(value)).success;
}
