import { z } from "zod";
import { httpsCallable } from "firebase/functions";
import { functions } from "../Firebase";
import { firebaseTimestampFromDate } from "../utils/timestamp.util";
import { MemberDataDeviceLocation } from "../../../lmt/src/common/types/MemberData";

const LocationResult = z.enum(["granted", "denied", "prompt"]);
type LocationResult = z.infer<typeof LocationResult>;
type Callback = (permission: LocationResult) => void;
interface MemberDeviceLocation {
    deviceLocation: MemberDataDeviceLocation;
}

export async function getLocationPermission(): Promise<LocationResult> {
    try {
        const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
        });
        return permissionStatus.state;
    } catch (error) {
        console.error("Error checking geolocation permission:", error);
        return "prompt";
    }
}

export function requestLocationPermission(
    callback: (permission: LocationResult) => void,
) {
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
            () => {
                callback("granted");
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    callback("denied");
                }
            },
        );
    } else {
        console.error("Geolocation is not supported by this browser.");
        callback("prompt");
    }
}

function updateDeviceLocation(update: MemberDeviceLocation) {
    return httpsCallable(functions, "memberUpdateMemberData")(update);
}

export async function checkLocationPermissions(callback?: Callback) {
    const locationStatus = await getLocationPermission();
    if (callback) {
        callback(locationStatus);
    }

    if (locationStatus === "granted") {
        navigator.geolocation.getCurrentPosition((success) => {
            const { coords, timestamp } = success;
            const date = firebaseTimestampFromDate(new Date(timestamp));
            const deviceLocation = {
                deviceLocation: {
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                    updatedAt: date,
                },
            };
            updateDeviceLocation(deviceLocation);
        });
    }
}
