import { Lib } from "albertine-shared-web";
import classNames from "classnames";
import React from "react";
import "./Screen.css";
import { motion, Variants } from "framer-motion";

export const screenCssClass = "screen";

type ScreenAnimation = "right-to-left" | "fade-in" | "no-animation";

const ScreenAnimationToVariants: { [key in ScreenAnimation]: Variants } = {
    "fade-in": {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    },
    "right-to-left": {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 400 },
    },
    "no-animation": {},
};

type ScreenProps = {
    className?: string;
    textColorInversable?: boolean;
    noScroll?: boolean;
    children: React.ReactNode;
};

function BaseScreen(baseClassName?: string, screenAnimation?: ScreenAnimation) {
    return function SceenComponent(props: ScreenProps) {
        const { className, children, textColorInversable, noScroll } = props;

        const motionProps =
            screenAnimation === "no-animation"
                ? {}
                : {
                      variants:
                          ScreenAnimationToVariants[
                              screenAnimation || "fade-in"
                          ],
                      initial: "initial",
                      animate: "animate",
                      exit: "exit",
                      transition: { duration: 0.25 },
                  };

        return (
            <motion.div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...motionProps}
                style={{
                    /* These do not work in CSS so leave these for screens to apply full height */
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                }}
            >
                <Lib.Flex.Column
                    gap="value16"
                    className={classNames(
                        screenCssClass,
                        baseClassName,
                        {
                            "text-color-inversable": textColorInversable,
                            "screen__no-scroll": noScroll,
                        },
                        className,
                    )}
                >
                    {children}
                </Lib.Flex.Column>
            </motion.div>
        );
    };
}

const Screen = {
    BookingBg01: BaseScreen("web-lib-booking-bg-01"),
    BookingBg02: BaseScreen("web-lib-booking-bg-02"),
    BookingBg03: BaseScreen("web-lib-booking-bg-03"),
    BookingBg04: BaseScreen("web-lib-booking-bg-04"),
    ConversationsBg01: BaseScreen("web-lib-conversations-bg-01", "fade-in"),
    ConversationsBg02: BaseScreen(
        "web-lib-conversations-bg-02",
        "right-to-left",
    ),
    ConversationsBg03: BaseScreen(
        "web-lib-conversations-bg-03",
        "right-to-left",
    ),
    ConversationsBg04: BaseScreen(
        "web-lib-conversations-bg-04",
        "right-to-left",
    ),
    ChatBg01: BaseScreen("web-lib-chat-bg-01"),
    DiscoverBg01: BaseScreen("web-lib-discover-bg-01"),
    DiscoverBg02: BaseScreen("web-lib-discover-bg-02"),
    DiscoverBg03: BaseScreen("web-lib-discover-bg-03"),
    DiscoverBg04: BaseScreen("web-lib-discover-bg-04"),
    ProfileBg01: BaseScreen("web-lib-profile-bg-01"),
    ProfileBg02: BaseScreen("web-lib-profile-bg-02", "right-to-left"),
    ProfileBg03: BaseScreen("web-lib-profile-bg-03"),
    ProfileBg04: BaseScreen("web-lib-profile-bg-04"),
    StartBg01: BaseScreen("web-lib-start-bg-01"),
    Onboarding: BaseScreen("web-lib-booking-bg-03", "no-animation"),
};

export default Screen;
