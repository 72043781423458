import React from "react";
import { motion } from "framer-motion";

type LoginStepMotionProps = {
    className?: string;
    children: React.ReactNode;
};

function LoginStepMotion(props: LoginStepMotionProps) {
    const { children, className } = props;
    return (
        <motion.div
            variants={{
                initial: { opacity: 0, y: 40 },
                animate: { opacity: 1, y: 0 },
                exit: { opacity: 0, y: -40 },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className={className}
        >
            {children}
        </motion.div>
    );
}

export default LoginStepMotion;
