import { z } from "zod";
import { AgentId, AlbertineServiceAgentId, MemberId } from "../validate";
import { FirebaseTimestamp } from "./FirebaseTimestamp";

export const MentionStatus = z.enum(["open", "resolved"]);
export const MentionStatusArray = z.array(MentionStatus);
export type MentionStatus = z.infer<typeof MentionStatus>;
export const MentionStatusList = MentionStatus.options;

export const MentionStatusTextMap: { [key in MentionStatus]: string } = {
    open: "Open",
    resolved: "Resolved",
};

export type FilterByMentionStatus = { id: MentionStatus; name: string };

export const MentionMeta = z.object({
    member: MemberId,
});

export const MentionBody = z.object({
    mentionStatus: MentionStatus,
    mentionCreatedAt: FirebaseTimestamp,
    mentionCreatedBy: AgentId.or(AlbertineServiceAgentId),
    mentionedAgents: z.array(AgentId.or(AlbertineServiceAgentId)),
    mentionResolvedAt: FirebaseTimestamp.optional().nullable(),
    mentionResolvedBy: AgentId.optional().nullable(),
    mentionDueDate: FirebaseTimestamp.optional().nullable(),
});
export type MentionBody = z.infer<typeof MentionBody>;

export const Mention = MentionMeta.and(MentionBody);
export type Mention = z.infer<typeof Mention>;

export function isMentionStatusArray(
    possible: any,
): possible is MentionStatus[] {
    return MentionStatusArray.safeParse(possible).success;
}
