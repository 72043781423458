import { useEffect, useRef } from "react";
import { screenCssClass } from "../screens/Screen";
import { getOnlyOrLast } from "./hooks.util";

type UseStyleTransformProps = {
    initialOpacity: number;
    finalOpacity: number;
    transformDistance: number;
    transitionDuration: number;
    scrollThreshold: number;
};

function useStyleTransform(
    props: UseStyleTransformProps,
): React.MutableRefObject<HTMLDivElement | null> {
    const {
        initialOpacity,
        finalOpacity,
        transformDistance,
        transitionDuration,
        scrollThreshold,
    } = props;
    const transformRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scrollableElement = getOnlyOrLast(
            document.getElementsByClassName(screenCssClass),
        );

        const handleScroll = () => {
            if (scrollableElement && transformRef.current) {
                const { scrollTop } = scrollableElement;

                const opacity = Math.max(
                    finalOpacity,
                    initialOpacity - scrollTop / scrollThreshold,
                );

                const translateY = Math.min(
                    (scrollTop / scrollThreshold) * transformDistance,
                    transformDistance,
                );

                transformRef.current.style.opacity = opacity.toString();
                transformRef.current.style.transform = `translateY(-${translateY}px)`;
                transformRef.current.style.transition = `opacity ${transitionDuration}s, transform ${transitionDuration}s`;
            }
        };

        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [transformRef]);

    return transformRef;
}

export default useStyleTransform;
