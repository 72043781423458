import { OnboardingTopCity } from "../../../lmt/src/common/types/Storyblok";
// import config from "../config";
import topCitiesList from "../assets/topCities.json";

const fetchTopCities = async (
    callback: (data: OnboardingTopCity[]) => void,
) => {
    // const list = config.topCitiesList; // this comes from .env file, there is cors issues on deployed dev instance
    // so still using fallback with local file from assets
    // when putting "no-cors" in request options, we can get just opaque result which is not usable at all
    // const list = topCitiesList;

    try {
        // const response = await fetch(`${list}`);

        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        // const data = await topCitiesList.json();

        const data = await topCitiesList;

        if (
            data &&
            data.story &&
            data.story.content &&
            data.story.content.topCities
        ) {
            callback(data.story.content.topCities);
        } else {
            throw new Error("Invalid data format in top cities response");
        }
    } catch (error) {
        throw new Error("Fetching top cities error");
    }
};

export default fetchTopCities;
