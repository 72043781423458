import React from "react";
import "./Glow.css";

type GlowProps = {
    children: React.ReactNode;
};
function Glow(props: GlowProps) {
    const { children } = props;
    return (
        <div className="glow__outer">
            <div className="glow__inner">{children}</div>
        </div>
    );
}

export default Glow;
