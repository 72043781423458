/* eslint-disable react/no-children-prop */
import React, { useEffect, useMemo, useState } from "react";
import {
    listenToDocumentToState,
    listenToQueryToState,
} from "../api/listeners";
import {
    conversationMessageDocument,
    queryConversationMessages,
    queryConversationMessagesPerRequest,
} from "../api/firestore";
import { Message } from "../../../lmt/src/common/types/Message";
import { sortByDateComparator } from "../../../lmt/src/common/utils/sort.util";
import { onlyUniqueWithExtractor } from "../../../lmt/src/common/utils/array.util";
import { fromTimestampToDate } from "../utils/timestamp.util";
import {
    ChatMessageGroup,
    messagesToChatMessageGroups,
} from "../utils/chatmessage.util";

function ListenToAllMessages(props: {
    conversationId: string;
    children: (chatMessageGroups: ChatMessageGroup[]) => JSX.Element;
    isLoading?: JSX.Element;
    onMessagesLoaded?: (messages: Message[]) => void;
}) {
    const { conversationId, children, isLoading, onMessagesLoaded } = props;
    const [messages, setMessages] = useState<Message[]>();

    useEffect(() => {
        if (messages && onMessagesLoaded) {
            onMessagesLoaded(messages);
        }
    }, [messages, onMessagesLoaded]);

    useEffect(() => {
        if (!conversationId) {
            setMessages(undefined);
            return undefined;
        }

        const unsubscribe = listenToQueryToState<Message>(
            queryConversationMessages(conversationId),
            setMessages,
        );
        return () => {
            unsubscribe();
            setMessages(undefined);
        };
    }, [conversationId]);

    if (messages === undefined) return isLoading || null;
    const chatMessageGroups = messagesToChatMessageGroups(messages);
    return children(chatMessageGroups);
}

export function ListenToThreadedMessages(props: {
    conversationId: string;
    requestId: string;
    createdFromMessageId?: string | undefined | null;
    limit?: number;
    children: (
        chatMessageGroups: ChatMessageGroup[],
        messages: Message[],
    ) => JSX.Element;
    isLoading?: JSX.Element;
    onMessagesLoaded?: (messages: Message[]) => void;
}) {
    const {
        conversationId,
        requestId,
        createdFromMessageId,
        limit,
        children,
        isLoading,
        onMessagesLoaded,
    } = props;
    const [messages, setMessages] = useState<Message[] | undefined>();
    const [createdFromMessage, setCreatedFromMessage] = useState<
        Message | undefined | null
    >();

    useEffect(() => {
        if (messages && onMessagesLoaded) {
            onMessagesLoaded(messages);
        }
    }, [messages, onMessagesLoaded]);

    const uniqueAndSortedMessages: ChatMessageGroup[] = useMemo(() => {
        const allMessages = [
            ...(createdFromMessage ? [createdFromMessage] : []),
            ...(messages ?? []),
        ];
        const sortedBySentAt = (allMessages ?? [])
            .sort(
                sortByDateComparator<Message>((message) =>
                    message ? fromTimestampToDate(message.sentAt) : undefined,
                ),
            )
            .reverse();
        const uniqueMessages = sortedBySentAt.filter(
            onlyUniqueWithExtractor<Message>((message) => message.id),
        );

        const chatMessageGroups = messagesToChatMessageGroups(uniqueMessages);
        return chatMessageGroups;
    }, [messages, createdFromMessage]);

    const showIsLoading = messages === undefined;

    useEffect(() => {
        if (!conversationId) {
            setMessages(undefined);
            setCreatedFromMessage(undefined);
            return undefined;
        }

        if (!createdFromMessageId) {
            setCreatedFromMessage(undefined);
            return listenToQueryToState<Message>(
                queryConversationMessagesPerRequest(
                    conversationId,
                    requestId,
                    limit,
                ),
                setMessages,
            );
        }

        const unsubscribeCreatedAtListener = listenToDocumentToState<Message>(
            conversationMessageDocument(conversationId, createdFromMessageId),
            Message,
            setCreatedFromMessage,
        );

        const unsubscribeMessageListener = listenToQueryToState<Message>(
            queryConversationMessagesPerRequest(
                conversationId,
                requestId,
                limit,
            ),
            setMessages,
        );

        const unsubscribe = () => {
            unsubscribeCreatedAtListener();
            unsubscribeMessageListener();
            setMessages(undefined);
        };
        return unsubscribe;
    }, [conversationId, requestId, createdFromMessageId, limit]);

    if (showIsLoading) return isLoading || null;
    return children(uniqueAndSortedMessages, messages);
}

interface Props {
    currentMemberId: string | undefined;
    conversationId: string | undefined;
    requestId?: string | undefined;
    requestCreatedFromMessage?: string | undefined;
    children: (chatMessageGroups: ChatMessageGroup[]) => JSX.Element;
    isLoading?: JSX.Element;
    onMessagesLoaded?: (messages: Message[]) => void;
}

export default function ListenToConversationMessages(props: Props) {
    const {
        currentMemberId,
        conversationId,
        requestId,
        requestCreatedFromMessage,
        children,
        isLoading,
        onMessagesLoaded,
    } = props;

    if (!currentMemberId) return null;
    if (!conversationId) return null;
    if (requestId) {
        return (
            <ListenToThreadedMessages
                key={conversationId + requestId}
                conversationId={conversationId}
                requestId={requestId}
                createdFromMessageId={requestCreatedFromMessage}
                children={children}
                isLoading={isLoading}
                onMessagesLoaded={onMessagesLoaded}
            />
        );
    }

    return (
        <ListenToAllMessages
            key={conversationId}
            conversationId={conversationId}
            isLoading={isLoading}
            children={children}
            onMessagesLoaded={onMessagesLoaded}
        />
    );
}
