import { z } from "zod";

export const FirebaseTimestamp = z.object({
    nanoseconds: z.number().optional(),
    seconds: z.number().optional(),
    _nanoseconds: z.number().optional(),
    _seconds: z.number().optional(),
});

export type FirebaseTimestamp = z.infer<typeof FirebaseTimestamp>;
