import { Lib } from "albertine-shared-web";
import classNames from "classnames";
import React from "react";
import "./ScreenHeader.css";
import useStyleTransform from "../hooks/useStyleTransform";

type ScreenHeaderProps = {
    className?: string;
    secondaryTitle?: string;
    primaryTitle: string;
};

function ScreenHeader(props: ScreenHeaderProps) {
    const { className, secondaryTitle, primaryTitle } = props;
    const transformRef = useStyleTransform({
        initialOpacity: 1,
        finalOpacity: 0,
        transformDistance: 100,
        transitionDuration: 0.2,
        scrollThreshold: 200,
    });

    return (
        <Lib.Flex.Column
            gap="value8"
            className={classNames("screen-header", className)}
            ref={transformRef}
        >
            {secondaryTitle && (
                <Lib.Heading.H5.Bold>{secondaryTitle}</Lib.Heading.H5.Bold>
            )}

            <Lib.Heading.H1.XBold>{primaryTitle}</Lib.Heading.H1.XBold>
        </Lib.Flex.Column>
    );
}

export default ScreenHeader;
