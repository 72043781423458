interface LocationProps {
    point: {
        lat: number;
        lon: number;
    };
}

interface NewCenter {
    lat: number;
    lng: number;
}

const adjustMapCenter = (
    location: LocationProps,
    callback: (newCenter: NewCenter) => void,
) => {
    let offset;

    switch (true) {
        case window.innerWidth < 420:
            offset = 0;
            break;
        case window.innerWidth >= 420 && window.innerWidth < 720:
            offset = 0.007;
            break;
        case window.innerWidth >= 720 && window.innerWidth < 1024:
            offset = 0.009;
            break;
        default:
            offset = 0.002;
            break;
    }
    const newCenter = {
        lat: offset === 0 ? location.point.lat - -0.004 : location.point.lat,
        lng: location.point.lon - offset,
    };
    callback(newCenter);
};
export default adjustMapCenter;
