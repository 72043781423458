import React from "react";
import MaskedInput from "react-text-mask";
import "./NumberInput.css";
import classNames from "classnames";
import { Lib } from "albertine-shared-web";
import Loading from "./Loading";

interface NumberInputProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    placeholder: string;
    className?: string;
    numberType: "phone" | "code";
    autoFocus?: boolean;
    currentValue?: string;
    loading?: boolean;
}

function NumberInput(props: NumberInputProps) {
    const {
        currentValue,
        onChange,
        placeholder,
        className,
        numberType,
        autoFocus,
        loading,
    } = props;

    const numberInputClass = classNames("number_input ", className);

    return numberType === "phone" ? (
        <MaskedInput
            onChange={onChange}
            placeholder={placeholder}
            className={numberInputClass}
            mask={[
                /\d/,
                " ",
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            guide={false}
            autoFocus={!!autoFocus}
            defaultValue={currentValue}
            inputMode="tel"
        />
    ) : (
        <Lib.Flex.Row>
            <MaskedInput
                onChange={onChange}
                placeholder={placeholder}
                className={numberInputClass}
                mask={[
                    /\d/,
                    " ",
                    /\d/,
                    " ",
                    /\d/,
                    " ",
                    /\d/,
                    " ",
                    /\d/,
                    " ",
                    /\d/,
                ]}
                guide={false}
                autoFocus={!!autoFocus}
                inputMode="numeric"
            />
            {loading && <Loading />}
        </Lib.Flex.Row>
    );
}

export default NumberInput;
