import { getUILanguage } from "albertine-shared-web";
import {
    PublicAttachment,
    PublicBooking,
} from "albertine-shared-web/types/PublicBooking";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import { BookingType } from "../types/BookingType";
import {
    fromTimestampToDate,
    fromTimestampToIsoDateString,
} from "./timestamp.util";
import { trimOrDefaultTo } from "../../../lmt/src/common/utils/string.util";
import { convertPriceToString } from "../../../lmt/src/common/currency";
import { googleViewInMapUrl } from "../../../lmt/src/common/utils/google/GooglePlaceService";
import { ImageAttachment } from "../../../lmt/src/common/types/Attachment";
import { cdnImage } from "../../../lmt/src/common/utils/image.util";

export function sortBookings(
    type: BookingType,
    bookings: MemberBooking[] | undefined,
): MemberBooking[] {
    if (!bookings || bookings.length === 0) {
        return [];
    }

    const now = Date.now() / 1000;
    const sixHours = 21600;

    return bookings.filter((booking) => {
        let bookingTime: number | undefined;

        if (booking.endTime?.seconds != null) {
            bookingTime = booking.endTime.seconds + sixHours;
        } else if (booking.startTime?.seconds != null) {
            bookingTime = booking.startTime.seconds + sixHours;
        }

        if (type === "past") {
            return bookingTime !== undefined && bookingTime < now;
        }
        return bookingTime !== undefined && bookingTime > now;
    });
}

export function groupBookingsByMonthAndYear(allBookings: MemberBooking[]) {
    const grouped: {
        [key: string]: {
            month: string;
            year: number;
            bookings: MemberBooking[];
        };
    } = {};

    const locale = getUILanguage().dateFnsLocale;

    allBookings.forEach((booking) => {
        const startDate = booking.startTime
            ? fromTimestampToDate(booking.startTime)
            : undefined;

        if (startDate) {
            const month = startDate.toLocaleDateString(locale.code, {
                month: "long",
            });
            const year = startDate.getFullYear();
            const key = `${year}-${month}`;

            if (!grouped[key]) {
                grouped[key] = { month, year, bookings: [] };
            }

            grouped[key].bookings.push(booking);
        }
    });

    return Object.values(grouped)
        .map((group) => ({
            month: group.month,
            year: group.year,
            sortedBookings: group.bookings.sort((a, b) => {
                const dateA = a.startTime
                    ? fromTimestampToDate(a.startTime).getTime()
                    : 0;
                const dateB = b.startTime
                    ? fromTimestampToDate(b.startTime).getTime()
                    : 0;
                return dateB - dateA;
            }),
        }))
        .sort((a, b) => {
            if (b.year !== a.year) return b.year - a.year;
            return (
                new Date(`1970-${b.month}-01`).getMonth() -
                new Date(`1970-${a.month}-01`).getMonth()
            );
        });
}

export function memberBookingToPublicBooking(props: {
    publicId: string;
    memberBooking: MemberBooking;
    publicAttachments: PublicAttachment[];
    publicShareFunctionUrl: string | undefined;
}): PublicBooking {
    const {
        publicId,
        memberBooking,
        publicAttachments,
        publicShareFunctionUrl,
    } = props;

    const mapUrl = googleViewInMapUrl(memberBooking);
    // QQ: should i transform memberBooking to booking for the above?
    // googleViewInMapUrl accepts booking, but this is also producing good maps

    const {
        address,
        benefits,
        offeringCategory,
        title,
        description,
        headline,
        bookingPolicy,
        images,
        imageURL,
        croppedImageURL,
        startTime,
        endTime,
        startTimezone,
        endTimezone,
        website,
        phoneNumber,
        rooms,
        price,
        priceDetails,
        numberOfGuests,
        numberOfNights,
        notesForMember,
        reservationStatus,
    } = memberBooking;

    const primaryImageNotInImages = !(
        images && images.some((it) => it.url === imageURL)
    );

    const arrangedImages: ImageAttachment[] = [
        ...(primaryImageNotInImages && imageURL
            ? [
                  {
                      id: imageURL,
                      url: imageURL,
                      croppedImageURL,
                      mimeType: "image/jpeg",
                  },
              ]
            : []),
        ...(images || []),
    ];

    function getAttachmentUrl(attachment: PublicAttachment) {
        if (publicShareFunctionUrl) {
            return `${publicShareFunctionUrl}/attachment/${attachment.url}`;
        }
        return attachment.url;
    }

    return {
        id: publicId,
        template:
            offeringCategory === "accommodation" ? "accommodation" : "general",
        title: trimOrDefaultTo(title, ""), // Handle empty titles
        description: trimOrDefaultTo(description, ""), // Handle empty descriptions
        headline: trimOrDefaultTo(headline, undefined),
        notesForMember: trimOrDefaultTo(notesForMember, undefined),
        notesForMemberSenderName: notesForMember ? "Albertine" : undefined,
        benefits: trimOrDefaultTo(benefits, undefined),
        images: (arrangedImages || []).map((it) =>
            cdnImage(it.croppedImageURL || it.url, { height: 960 }),
        ),
        attachments: publicAttachments.map((publicAttachment) => ({
            name: publicAttachment.name,
            url: publicAttachment.url
                ? getAttachmentUrl(publicAttachment)
                : undefined,
        })),
        bookingPolicy: trimOrDefaultTo(bookingPolicy, undefined),
        bookingReference: undefined, // Public booking doesn't have reference
        confirmed: reservationStatus === "confirmed",
        rooms: trimOrDefaultTo(rooms, undefined),
        numberOfGuests: numberOfGuests || undefined,
        numberOfNights:
            offeringCategory === "accommodation"
                ? numberOfNights || undefined
                : undefined,
        price: price ? convertPriceToString(price) : undefined,
        priceDetails: trimOrDefaultTo(priceDetails, undefined),
        startTime: startTime
            ? fromTimestampToIsoDateString(startTime)
            : undefined,
        startTimezone: startTimezone || undefined,
        endTime: endTime ? fromTimestampToIsoDateString(endTime) : undefined,
        endTimezone: endTimezone || undefined,
        website: trimOrDefaultTo(website, undefined),
        phoneNumber: trimOrDefaultTo(phoneNumber, undefined),
        address: trimOrDefaultTo(address, undefined),
        mapUrl,
    };
}
