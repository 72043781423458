import { data } from "currency-codes";
import * as z from "zod";
import { NonNegativeNumber } from "./validate";

export const supportedCurrencyCodes = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BOV",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CHW",
    "CLF",
    "CLP",
    "CNY",
    "COP",
    "COU",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRU",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MXV",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SSP",
    "STN",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "USN",
    "UYI",
    "UYU",
    "UYW",
    "UZS",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "XSU",
    "XUA",
    "YER",
    "ZAR",
    "ZMW",
    "ZWL",
] as const;
export const CurrencyCode = z.enum(supportedCurrencyCodes);
export type CurrencyCode = z.infer<typeof CurrencyCode>;

export interface Currency {
    code: CurrencyCode;
    currency: string;
}

const currencyList = data
    .filter((c) => supportedCurrencyCodes.includes(c.code as CurrencyCode))
    .map((d) => ({
        code: d.code as CurrencyCode,
        currency: d.currency,
    }));

const topCurrencyCodes = ["EUR", "GBP", "USD"];
const topCurrencies = currencyList.filter((c) =>
    topCurrencyCodes.includes(c.code),
);
const otherCurrencies = currencyList.filter(
    (c) => !topCurrencyCodes.includes(c.code),
);

export const autocompleteCurrencyList: { id: string; text: string }[] = [
    ...topCurrencies,
    ...otherCurrencies,
].map((currency) => ({
    id: currency.code,
    text: `${currency.code} - ${currency.currency}`,
}));

const currencySearchList: { searchTerm: string; currency: Currency }[] =
    currencyList.map((currency) => ({
        currency,
        searchTerm: `${currency.code} ${currency.currency}`.toLocaleLowerCase(),
    }));

export function codeToCurrency(code?: string): Currency | undefined {
    return currencyList.find((c) => c.code === code);
}

export const Price = z.object({
    currency: z.string(),
    amount: NonNegativeNumber,
});
export type Price = z.infer<typeof Price>;

export const EUR = codeToCurrency("EUR")!;
export const GBP = codeToCurrency("GBP")!;
export const USD = codeToCurrency("USD")!;

export function searchCurrency(query: string): Currency[] {
    if (query.length === 0) {
        return [EUR, GBP, USD];
    }
    return currencySearchList
        .filter((c) => c.searchTerm.includes(query.toLocaleLowerCase()))
        .map((c) => c.currency);
}

export function convertPriceToString(price: Price): string {
    return new Intl.NumberFormat("en-UK", {
        style: "currency",
        currency: price.currency,
    }).format(price.amount);
}

export function currencyCodeAsSymbol(
    currencyCode: string,
    locale: string = "en-UK",
): string | undefined {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
    })
        .formatToParts(0)
        .find((part) => part.type === "currency")?.value;
}
