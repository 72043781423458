import { z } from "zod";

export const MemberSatisfactionOptions: readonly [string, ...string[]] = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
];
export const MemberSatisfaction = z.enum(MemberSatisfactionOptions);
export type MemberSatisfaction = z.infer<typeof MemberSatisfaction>;
