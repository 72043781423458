import { z } from "zod";
import { ServiceLanguage } from "albertine-shared-web";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import {
    Contributions,
    Email,
    FirebaseId,
    MemberId,
    Name,
    PhoneNumber,
    ShortString,
    VeryShortString,
} from "../validate";
import { MembershipStagePaidExposedToMember } from "./Membership";

export const MemberDoc = z.object({
    title: VeryShortString.optional().nullable(),
    firstName: Name,
    lastName: Name,
    phoneNumber: PhoneNumber,
    email: Email,

    conversation: FirebaseId,
    createdAt: FirebaseTimestamp,
    updatedAt: FirebaseTimestamp.nullable().optional(),

    contributions: Contributions.optional().nullable(),
    referralId: z.string().optional().nullable(),

    hasVisitedChat: z.boolean().optional(),

    agentFirstName: ShortString.optional().nullable(),
    assignedToName: ShortString.optional().nullable(),

    greetingsName: z.string().optional().nullable(),

    hasOnboardingShown: z.boolean().optional().nullable(),
    hasUsedQuickActionPlaceRequest: z.boolean().optional().nullable(),
    hasUsedQuickActionOnboardingCall: z.boolean().optional().nullable(),

    membership: MembershipStagePaidExposedToMember.or(z.undefined()).nullable(),

    serviceLanguage: ServiceLanguage.optional().nullable(),
});

export type MemberDoc = z.infer<typeof MemberDoc>;

export const Member = MemberDoc.and(
    z.object({
        id: MemberId,
    }),
);

export type Member = z.infer<typeof Member>;
