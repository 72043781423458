import React, { useEffect, useState } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router";
import { AnimatePresence } from "framer-motion";
import {
    changeUILanguage,
    findServiceLanguageFromBrowser,
    getUILanguage,
    TenantConfig,
    useTenant,
} from "albertine-shared-web";
import { User } from "firebase/auth";
import Discover from "./screens/Discover";
import Login from "./screens/Login";
import { useAuth } from "./context/auth";
import { useRoutes, WithOnboardingStepParams } from "./routes";
import Profile from "./screens/Profile";
import LoadingScreen from "./screens/Loading";
import Navigation from "./components/Navigation";
import Conversations from "./screens/Conversations";
import Bookings from "./screens/Bookings";
import ConversationThread from "./screens/ConversationThread";
import NotificationPermission from "./screens/NotificationPermission";

import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import { checkLocationPermissions } from "./api/location";
import BookingDetails from "./screens/BookingDetails";
import ArticleDetails from "./screens/ArticleDetails";
import Proposal from "./screens/Proposal";
import { ScreenStackItem, useScreenStack } from "./context/screenStack";
import { Member } from "../../lmt/src/common/types/Member";
import { listenToDocumentToState } from "./api/listeners";
import { memberDocument, updateMemberData } from "./api/firestore";
import { shouldAskNotificationPermission } from "./api/notifications";
import Onboarding from "./screens/Onboarding";
import OnboardingStep from "./screens/Onboarding/OnboardingStep";

function ScreenStack(props: { user: User }) {
    const { user } = props;
    const { screenStack, getReactKey } = useScreenStack();

    const renderScreen = (screen: ScreenStackItem, index: number) => {
        switch (screen.type) {
            case "article": {
                return (
                    <ArticleDetails
                        key={getReactKey(screen, index)}
                        articleId={screen.id}
                    />
                );
            }
            case "booking": {
                return (
                    <BookingDetails
                        key={getReactKey(screen, index)}
                        currentMemberId={user.uid}
                        bookingId={screen.id}
                    />
                );
            }
            case "chat":
                return (
                    <ConversationThread
                        key={getReactKey(screen, index)}
                        currentMemberId={user.uid}
                        thread={screen.id === "all" ? undefined : screen.id}
                    />
                );
            case "proposal": {
                return (
                    <Proposal
                        key={getReactKey(screen, index)}
                        requestId={screen.id}
                    />
                );
            }
            case "profile": {
                return (
                    <Profile
                        key={getReactKey(screen, index)}
                        currentMemberId={user.uid}
                    />
                );
            }
            case "website": {
                if (screen.source === "privacy") {
                    return <PrivacyPolicy key={getReactKey(screen, index)} />;
                }

                if (screen.source === "terms") {
                    return (
                        <TermsAndConditions key={getReactKey(screen, index)} />
                    );
                }

                return null;
            }
            default:
                return null;
        }
    };
    return (
        <AnimatePresence>
            {screenStack.map((screen, index) => renderScreen(screen, index))}
        </AnimatePresence>
    );
}

function changeToBrowserLanguage(tenantConfig: TenantConfig) {
    const lang = findServiceLanguageFromBrowser(
        tenantConfig.allowedServiceLanguages,
    );
    if (getUILanguage().lang !== lang) {
        changeUILanguage(lang);
    }
}

async function changeToMemberLanguage(
    member: Member,
    tenantConfig: TenantConfig,
) {
    // If member doesn't have language set up yet, we will update it with browser language
    if (!member?.serviceLanguage) {
        const languageFromBrowser = findServiceLanguageFromBrowser(
            tenantConfig.allowedServiceLanguages,
        );
        await updateMemberData({
            serviceLanguage: languageFromBrowser,
        });
        changeUILanguage(languageFromBrowser);
    } else if (getUILanguage().lang !== member.serviceLanguage) {
        changeUILanguage(member.serviceLanguage);
    }
}

function useServiceLanguageSwitcher(
    member: Member | undefined | null,
    tenantConfig: TenantConfig,
) {
    useEffect(() => {
        if (member) {
            changeToMemberLanguage(member, tenantConfig);
        }
        changeToBrowserLanguage(tenantConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);
}

function useNotificationPermissionsRedirect(member: Member | undefined | null) {
    const { url } = useRoutes();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            member &&
            member.hasOnboardingShown &&
            location.pathname !== url.notificationPermission &&
            shouldAskNotificationPermission()
        ) {
            navigate(url.notificationPermission);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, location]);
}

function App() {
    const { user, loading } = useAuth();
    const { schemas, url, hideNavigation } = useRoutes();
    const location = useLocation();
    const { tenantConfig } = useTenant();
    const [member, setMember] = useState<Member | undefined | null>(undefined);

    useServiceLanguageSwitcher(member, tenantConfig);
    useNotificationPermissionsRedirect(member);

    useEffect(() => {
        checkLocationPermissions();

        if (user) {
            return listenToDocumentToState<Member>(
                memberDocument(user.uid),
                Member,
                setMember,
            );
        }
        setMember(undefined);
        return undefined;
    }, [user]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <AnimatePresence mode="wait">
                {user ? (
                    <Routes key={location.pathname} location={location}>
                        <Route
                            path={schemas.conversations}
                            element={
                                <Conversations currentMemberId={user.uid} />
                            }
                        />
                        <Route
                            path={schemas.bookings}
                            element={<Bookings currentMemberId={user.uid} />}
                        />
                        <Route
                            path={schemas.discover}
                            element={<Discover currentMemberId={user.uid} />}
                        />
                        <Route
                            path={schemas.notificationPermission}
                            element={<NotificationPermission />}
                        />
                        <Route
                            path={schemas.onboarding}
                            element={<Onboarding currentMember={member} />}
                        >
                            <Route
                                path={schemas.onboardingStep}
                                element={
                                    <WithOnboardingStepParams>
                                        {(onboardingStep) => (
                                            <OnboardingStep
                                                step={onboardingStep}
                                            />
                                        )}
                                    </WithOnboardingStepParams>
                                }
                            />
                        </Route>
                        <Route
                            path="*"
                            element={
                                <Navigate
                                    to={
                                        member?.hasOnboardingShown
                                            ? url.bookings
                                            : url.onboardingStep("start")
                                    }
                                />
                            }
                        />
                    </Routes>
                ) : (
                    <Routes key={location.pathname} location={location}>
                        <Route path={schemas.root} element={<Login />} />
                        <Route path="*" element={<Navigate to={url.root} />} />
                    </Routes>
                )}
            </AnimatePresence>

            {user && !hideNavigation && <Navigation />}
            {user && <ScreenStack user={user} />}
        </>
    );
}

export default App;
