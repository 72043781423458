import React from "react";
import Screen from "./Screen";
import "./BookingDetails.css";
import Booking from "./Booking";
import Header, { BackButton } from "../components/Header";
import LoadPublicBooking from "../loaders/LoadPublicBooking";

interface Props {
    currentMemberId: string;
    bookingId: string;
}

function BookingDetails(props: Props) {
    const { currentMemberId, bookingId } = props;

    return (
        <LoadPublicBooking memberId={currentMemberId} bookingId={bookingId}>
            {(publicBooking, memberBooking) => {
                if (!publicBooking || !memberBooking) return null;
                return (
                    <Screen.BookingBg01 className="booking_details__wrapper">
                        <Header
                            title={publicBooking?.title || ""}
                            type="booking"
                        />
                        <BackButton />
                        <Booking
                            publicBooking={publicBooking}
                            memberBooking={memberBooking}
                        />
                    </Screen.BookingBg01>
                );
            }}
        </LoadPublicBooking>
    );
}

export default BookingDetails;
