/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { Lib, useTenant, useAlbertineTranslation } from "albertine-shared-web";
import { useOutletContext } from "react-router-dom";
import "./OnboardingCommonStyles.css";
import "./OnboardingCities.css";
import classnames from "classnames";
import { requestLocationPermission } from "../../api/location";
import fetchTopCities from "../../api/topCities";
import { OnboardingTopCity } from "../../../../lmt/src/common/types/Storyblok";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";
import Transition from "./OnboardingTransition";

const maxCitiesSelected = 3;
interface InitialScreenProps {
    goToNextStep: () => void;
}

function InitialScreen(props: InitialScreenProps) {
    const { goToNextStep } = props;
    const t = useAlbertineTranslation();
    const { dependencyInjection } = useTenant();
    return (
        <Transition>
            <Lib.Flex.Column gap="value24">
                <Lib.Flex.Column gap="value8">
                    <Lib.Tag.Primary>
                        {t("onboarding__cities__tag")}
                    </Lib.Tag.Primary>
                    <Lib.Paragraph.XXLarge.XBold>
                        {t("onboarding__cities__title")}
                    </Lib.Paragraph.XXLarge.XBold>
                    <Lib.Paragraph.Small.Reqular>
                        {t("onboarding__cities__text")}
                    </Lib.Paragraph.Small.Reqular>
                </Lib.Flex.Column>
                <Lib.Flex.Column>
                    <Lib.Button.Onboarding type="button" onClick={goToNextStep}>
                        {dependencyInjection?.onboarding.OnboardingButtonIcon()}
                        {t("onboarding__cities__add-my-top-cities")}
                    </Lib.Button.Onboarding>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface ChoseCitiesScreenProps {
    goToNextStep: () => void;
    topCities: OnboardingTopCity[];
    selectedCities: OnboardingTopCity[];
    toggleCitySelection: (city: any) => void;
}

function ChooseCitiesScreen(props: ChoseCitiesScreenProps) {
    const { goToNextStep, topCities, selectedCities, toggleCitySelection } =
        props;
    const t = useAlbertineTranslation();

    return (
        <Transition>
            <Lib.Flex.Column
                gap="value8"
                className="onboarding-position-relative"
            >
                <Lib.Tag.Primary>
                    {t("onboarding__cities__tag")}
                </Lib.Tag.Primary>
                <Lib.Flex.Column gap="value16">
                    <Lib.Flex.Column gap="value32">
                        <Lib.Paragraph.XXLarge.XBold>
                            {t("onboarding__cities__choose-up-to-three")}
                        </Lib.Paragraph.XXLarge.XBold>

                        <Lib.Label.Medium.Bold>
                            {t("onboarding__cities__most-popular")}
                        </Lib.Label.Medium.Bold>
                    </Lib.Flex.Column>

                    <Lib.Flex.Column
                        gap="value16"
                        className={classnames("onboarding-cities__list", {
                            "onboarding-cities__list__overscroll":
                                selectedCities.length > 0 &&
                                selectedCities.length < 3,
                            "onboarding-cities__list__overscroll--full":
                                selectedCities.length === maxCitiesSelected,
                        })}
                    >
                        {topCities.map((city) => {
                            const isSelected = selectedCities.some(
                                (c) => c._uid === city._uid,
                            );
                            return (
                                <Lib.Flex.Row
                                    key={city._uid}
                                    onClick={() => toggleCitySelection(city)}
                                >
                                    {isSelected ? (
                                        <Lib.Button.Ghost
                                            onClick={() =>
                                                toggleCitySelection(city)
                                            }
                                        >
                                            <Lib.Flex.Row gap="value8">
                                                <Lib.Icon.Check.Small />
                                                <Lib.Label.Large.Bold>
                                                    {city.city}
                                                </Lib.Label.Large.Bold>
                                                <Lib.Label.Large.Bold>
                                                    {city.country}
                                                </Lib.Label.Large.Bold>
                                            </Lib.Flex.Row>
                                        </Lib.Button.Ghost>
                                    ) : (
                                        <Lib.Button.Ghost
                                            onClick={() =>
                                                toggleCitySelection(city)
                                            }
                                        >
                                            <Lib.Flex.Row gap="value8">
                                                <Lib.Icon.Check.Small className="onboarding-cities__transparent-icon" />
                                                <Lib.Label.Large.Reqular>
                                                    {city.city}
                                                </Lib.Label.Large.Reqular>
                                                <Lib.Label.Large.Reqular.TextColor02>
                                                    {city.country}
                                                </Lib.Label.Large.Reqular.TextColor02>
                                            </Lib.Flex.Row>
                                        </Lib.Button.Ghost>
                                    )}
                                </Lib.Flex.Row>
                            );
                        })}
                    </Lib.Flex.Column>
                </Lib.Flex.Column>
                <Lib.Flex.Column className="onboarding-cities__selected-cities__wrapper">
                    {selectedCities.length > 0 && (
                        <Transition>
                            <>
                                {selectedCities.length ===
                                    maxCitiesSelected && (
                                    <Transition>
                                        <Lib.Flex.Row
                                            gap="value8"
                                            justifyContent="start"
                                            alignItems="center"
                                            className="onboarding-cities__all-selected__wrapper"
                                        >
                                            <Lib.Paragraph.XXLarge.Bold>
                                                {t(
                                                    "onboarding__cities__all-selected",
                                                )}
                                            </Lib.Paragraph.XXLarge.Bold>
                                            <Lib.Icon.Check.Medium />
                                        </Lib.Flex.Row>
                                    </Transition>
                                )}
                                <Lib.Line.Dashed />
                                <Lib.Flex.Row
                                    alignItems="center"
                                    className="onboarding-cities__selected-cities"
                                >
                                    {selectedCities.map((city: any) => (
                                        <div
                                            key={city._uid}
                                            onClick={() =>
                                                toggleCitySelection(city)
                                            }
                                            className="onboarding-cities__selected-city text-color-inversable"
                                        >
                                            <Lib.Label.XSmall.Bold>
                                                {city.city}
                                            </Lib.Label.XSmall.Bold>
                                            <Lib.Icon.Close.Small.Gray100 />
                                        </div>
                                    ))}
                                </Lib.Flex.Row>
                                <Lib.Button.Onboarding
                                    onClick={() => goToNextStep()}
                                >
                                    {t("onboarding__cities__done")}
                                </Lib.Button.Onboarding>
                            </>
                        </Transition>
                    )}
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Transition>
    );
}

interface CityRecommendationsScreenProps {
    onContinue: () => void;
    goToPreviousStep: () => void;
    selectedCities: OnboardingTopCity[];
}

function CityRecommendationsScreen(props: CityRecommendationsScreenProps) {
    const { onContinue, goToPreviousStep, selectedCities } = props;
    const t = useAlbertineTranslation();

    return (
        <Transition>
            <Lib.Flex.Column gap="value24">
                <Lib.Flex.Column gap="value12">
                    <Lib.Flex.Row>
                        <Lib.Flex.Column>
                            <Lib.Tag.Primary>
                                {t("onboarding__cities__tag")}
                            </Lib.Tag.Primary>
                        </Lib.Flex.Column>
                        <Lib.Flex.Column
                            justifyContent="center"
                            alignItems="end"
                        >
                            <Lib.Button.Ghost onClick={goToPreviousStep}>
                                <Lib.Icon.LocationOn />
                                <Lib.Label.Medium.Bold>
                                    {t("onboarding__cities__edit")}
                                </Lib.Label.Medium.Bold>
                            </Lib.Button.Ghost>
                        </Lib.Flex.Column>
                    </Lib.Flex.Row>

                    <Lib.Flex.Row gap="value4">
                        {selectedCities.length > 0 &&
                            selectedCities.map((city: any) => (
                                <div
                                    className="onboarding-cities__recommendations-city"
                                    key={city._uid}
                                >
                                    <Lib.RemoteImage.Square.Medium
                                        src={city.coverImage.filename}
                                        alt={city.city}
                                        className="onboarding-cities__recommendations-city__image"
                                    />
                                    <Lib.Flex.Row className="onboarding-cities__recommendations-city__title text-color-inversable">
                                        <Lib.Heading.H6.Bold>
                                            {city.city}
                                        </Lib.Heading.H6.Bold>
                                    </Lib.Flex.Row>
                                </div>
                            ))}
                    </Lib.Flex.Row>
                </Lib.Flex.Column>
                <Lib.Flex.Column gap="value8">
                    <Lib.Paragraph.XXLarge.XBold>
                        {t("onboarding__cities__recommendations")}
                    </Lib.Paragraph.XXLarge.XBold>
                    <Lib.Paragraph.Small.MediumBold>
                        {t("onboarding__cities__location-sharing")}
                    </Lib.Paragraph.Small.MediumBold>
                </Lib.Flex.Column>

                <Lib.Button.Onboarding type="button" onClick={onContinue}>
                    {t("onboarding__cities__continue")}
                </Lib.Button.Onboarding>
            </Lib.Flex.Column>
        </Transition>
    );
}

function OnboardCities() {
    const [step, setStep] = useState<number>(0);
    const [topCities, setTopCities] = useState<OnboardingTopCity[]>([]);
    const [selectedCities, setSelectedCities] = useState<OnboardingTopCity[]>(
        [],
    );

    const { member, updateMemberPreferences, setNextStep, setProgress } =
        useOutletContext<OnboardingOutletContextType>();

    useEffect(() => {
        setProgress(30);
        fetchTopCities(setTopCities);
    }, []);

    const goToPreviousStep = () => {
        setStep(step - 1);
    };

    const goToNextStep = () => {
        setStep(step + 1);
    };

    const handleLocationPermission = () => {
        requestLocationPermission(() =>
            // updating member preferences here with selected cities, saves it in the firebase>memberData>id>preferences>onboarding>topCities
            updateMemberPreferences(
                { onboarding: { topCities: selectedCities } },
                member!,
            ).then(() => setNextStep("conciergeExperience")),
        );
    };

    const toggleCitySelection = (city: OnboardingTopCity) => {
        setSelectedCities((prevSelectedCities: OnboardingTopCity[]) => {
            const isSelected = prevSelectedCities.some(
                (c) => c._uid === city._uid,
            );

            if (!isSelected && prevSelectedCities.length >= 3) {
                return prevSelectedCities;
            }

            return isSelected
                ? prevSelectedCities.filter((c) => c._uid !== city._uid)
                : [...prevSelectedCities, city];
        });
    };

    return (
        <>
            {step === 0 && <InitialScreen goToNextStep={goToNextStep} />}
            {step === 1 && (
                <ChooseCitiesScreen
                    goToNextStep={goToNextStep}
                    topCities={topCities}
                    selectedCities={selectedCities}
                    toggleCitySelection={toggleCitySelection}
                />
            )}
            {step === 2 && (
                <CityRecommendationsScreen
                    onContinue={handleLocationPermission}
                    goToPreviousStep={goToPreviousStep}
                    selectedCities={selectedCities}
                />
            )}
        </>
    );
}

export default OnboardCities;
