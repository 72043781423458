import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import Screen from "./Screen";
import {
    getDynamicThemes,
    toDynamicThemes,
} from "../../../lmt/src/common/utils/opensearch/themes";
import LoadingScreen from "./Loading";
import ListenToMember from "../loaders/ListenToMember";
import Avatar from "../components/Avatar";
import { useAuth } from "../context/auth";
import { OpenSearchContentConfiguration } from "../../../lmt/src/common/types/ContentConfiguration";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import { useOpenSearch } from "../context/opensearch";
import { ModalOutlet } from "./Modal";
import ScreenHeader from "../components/ScreenHeader";
import Spotlight from "../components/Spotlight";
import CityGuide from "../components/CityGuide";
import Category from "../components/Category";
import MemberTopThemes from "../components/MemberTopThemes";
import { useScreenStack } from "../context/screenStack";
import "./Discover.css";

function WorldOfAlbertine() {
    const t = useAlbertineTranslation();
    const { tenant } = useTenant();
    const [dynamicThemes, setDynamicThemes] = useState<
        OpenSearchContentConfiguration | undefined
    >();
    const [staticThemes, setStaticThemes] = useState<
        DynamicTheme[] | undefined
    >();
    const [spotlight, ...memberTopThemes] = staticThemes || [];
    const { opensearch, memberPreferences } = useOpenSearch();
    useEffect(() => {
        const getDynamicContent = async () => {
            if (opensearch) {
                const dThemes = await getDynamicThemes(opensearch);
                setDynamicThemes(dThemes);
            }
        };
        if (memberPreferences) {
            setStaticThemes(toDynamicThemes(memberPreferences.topThemes));
        }
        getDynamicContent();
    }, [opensearch, memberPreferences]);
    const sortedCityGuides = dynamicThemes?.cityGuides.sort((a, b) => {
        // Extract the city keys from the cityGuides objects
        const keyA = a.key;
        const keyB = b.key;
        // Get the index of each city key in topCities array
        const indexA = memberPreferences?.topCities?.indexOf(keyA) ?? -1;
        const indexB = memberPreferences?.topCities?.indexOf(keyB) ?? -1;
        if (indexA === -1 && indexB === -1) {
            // If neither city is in topCities, maintain their original order
            return 0;
        }
        if (indexA === -1) {
            // If city A is not in topCities, put it after city B
            return 1;
        }
        if (indexB === -1) {
            // If city B is not in topCities, put it after city A
            return -1;
        }
        // If both cities are in topCities, sort based on their index
        return indexA - indexB;
    });

    return (
        <Lib.Flex.Column className="discover">
            <Lib.ContentCenter.Large>
                <Lib.Flex.Column>
                    {spotlight && (
                        <Spotlight key={spotlight.key} theme={spotlight} />
                    )}
                </Lib.Flex.Column>
            </Lib.ContentCenter.Large>

            {/* Cityguides section */}
            <Lib.Background.DiscoverBg02>
                <Lib.ContentCenter.Large>
                    <Lib.Flex.Column>
                        <Lib.Flex.Row>
                            <Lib.Heading.H4.Bold className="discover__cityguide-city">
                                {t("discover__top-cities")}
                            </Lib.Heading.H4.Bold>
                        </Lib.Flex.Row>

                        <Lib.Flex.Row>
                            {sortedCityGuides && (
                                <CityGuide cities={sortedCityGuides} />
                            )}
                        </Lib.Flex.Row>
                    </Lib.Flex.Column>
                </Lib.ContentCenter.Large>
            </Lib.Background.DiscoverBg02>

            {/* Member top themes section */}
            <Lib.Background.DiscoverBg02>
                <Lib.ContentCenter.Large>
                    <Lib.Flex.Column>
                        {memberTopThemes?.map((theme, index: number) => (
                            <MemberTopThemes
                                index={index}
                                key={theme.key}
                                name={theme.name}
                                theme={theme}
                            />
                        ))}
                    </Lib.Flex.Column>
                </Lib.ContentCenter.Large>
            </Lib.Background.DiscoverBg02>

            {/* Explore by Category section */}
            <Lib.Background.DiscoverBg01
                textColorInversable={tenant === "hugoboss"}
            >
                <Lib.ContentCenter.Large>
                    <Lib.Flex.Column>
                        <Lib.Flex.Row>
                            <Lib.Heading.H1.Bold>
                                {t("discover__explore")}
                            </Lib.Heading.H1.Bold>
                        </Lib.Flex.Row>

                        <Lib.Flex.Row>
                            <Lib.Heading.H1.Reqular
                                fontStyle="italic"
                                className="discover__categories-subtitle"
                            >
                                {t("discover__by-category")}
                            </Lib.Heading.H1.Reqular>
                        </Lib.Flex.Row>

                        <Lib.Flex.Row>
                            {dynamicThemes?.categories && (
                                <Category
                                    categories={dynamicThemes?.categories}
                                />
                            )}
                        </Lib.Flex.Row>
                    </Lib.Flex.Column>
                </Lib.ContentCenter.Large>
            </Lib.Background.DiscoverBg01>

            {/*    Behind the content */}
            <Lib.Background.DiscoverBg02 className="discover__behind-the-content">
                <Lib.ContentCenter.Large>
                    <Lib.Flex.Column
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Lib.Tag.Primary className="discover__behind-the-content__tag">
                            {t("discover__behind-the-content")}
                        </Lib.Tag.Primary>
                        <Lib.Paragraph.Large.Reqular className="discover__behind-the-content__text">
                            {t("discover__behind-the-content__text")}
                        </Lib.Paragraph.Large.Reqular>
                        <Lib.Line.Vertical />
                        <Lib.Button.Primary className="discover__behind-the-content__button">
                            {t("discover__behind-the-content__button")}
                        </Lib.Button.Primary>
                    </Lib.Flex.Column>
                </Lib.ContentCenter.Large>
            </Lib.Background.DiscoverBg02>
        </Lib.Flex.Column>
    );
}
type Discover = {
    currentMemberId: string;
};
function Discover(props: Discover) {
    const { currentMemberId } = props;
    const t = useAlbertineTranslation();
    const { user } = useAuth();
    const { tenant } = useTenant();
    const { openProfile } = useScreenStack();
    return user ? (
        <>
            <Screen.DiscoverBg02>
                <Lib.Flex.Column gap="value40">
                    <Lib.Button.Ghost
                        onClick={openProfile}
                        className="discover__profile-button"
                    >
                        <Avatar.Member
                            id={user.uid}
                            fullName={user?.displayName || ""}
                        />
                    </Lib.Button.Ghost>
                    <Lib.Background.DiscoverBg01
                        textColorInversable={tenant === "hugoboss"}
                    >
                        <Lib.ContentCenter.Large>
                            <ListenToMember
                                memberId={currentMemberId}
                                isLoading={LoadingScreen}
                            >
                                {(member) => (
                                    <ScreenHeader
                                        secondaryTitle={t(
                                            "discover__secondary-title-hi",
                                            {
                                                username:
                                                    member.greetingsName ||
                                                    `${member.firstName} ${member.lastName}`,
                                            },
                                        )}
                                        primaryTitle={t(
                                            "discover__primary-title-default",
                                        )}
                                        className="discover__header"
                                    />
                                )}
                            </ListenToMember>
                        </Lib.ContentCenter.Large>
                    </Lib.Background.DiscoverBg01>

                    <WorldOfAlbertine />
                </Lib.Flex.Column>
            </Screen.DiscoverBg02>

            <ModalOutlet />
        </>
    ) : null;
}
export default Discover;
