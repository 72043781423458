import { z } from "zod";
import { Macro } from "../types/Macro";
import { nineDigitsRandomNumber } from "./math.util";

export const isString = (possibleString: any): possibleString is string =>
    z.string().safeParse(possibleString).success;

export function isEmpty(str: string | null | undefined) {
    return !str || str.length === 0;
}

export function ifEmptyDefaultToNull(
    str: string | null | undefined,
): string | null {
    return !str || str.length === 0 ? null : str;
}

export function capitalize<T extends string>(s: T): Capitalize<T> {
    return (s.charAt(0).toUpperCase() + s.slice(1)) as Capitalize<T>;
}

export function slugify(s: string): string {
    return s
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const slugWithRandomDigits = (title: string) =>
    slugify(`${title} ${nineDigitsRandomNumber()}`);

export function stringToArray(value: undefined | string | string[]): string[] {
    if (value) {
        return isString(value) ? [value] : value;
    }
    return [];
}

export const zeroPad = (num: number) => String(num).padStart(2, "0");

export function replaceMacros<T extends string>(
    macros: Macro<T>[],
    template: string,
) {
    let result = template;
    macros.forEach((m) => {
        result = result.replaceAll(`{${m.key}}`, m.value);
    });
    return result;
}

export function roundNumberToDecimals(
    number: number,
    decimals: number,
): string {
    return number.toFixed(decimals).replace(/\.?0+$/, "");
}

export function lastNDigits(number: number, n: number) {
    const digits = number.toString();
    if (digits.length <= n || n <= 0) {
        return digits;
    }

    return digits.slice(-n);
}

export function trimOrDefaultTo<T>(
    str: string | null | undefined,
    orDefaultTo: T,
): string | T {
    const trimmed = str ? str.trim() : "";
    return trimmed.length !== 0 ? trimmed : orDefaultTo;
}

export function removeDiacritics(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
