import { z } from "zod";
import {
    AgentId,
    AlbertineServiceAgentId,
    FirebaseId,
    MemberId,
} from "../validate";
import { ArticleInquiry } from "./ArticleInquiry";
import { Attachment } from "./Attachment";
import { BookingInMessage, BookingToConfirmInMemberMessage } from "./Booking";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { Mention, MentionStatus } from "./Mention";
import { NotificationAttempts } from "./NotificationAttempt";
import { QuickRequest } from "./QuickRequests";
import { CatalogArticleItem } from "./Storyblok";
import { MessageAnalysisStatus } from "./MessageAnalysisStatus";
import { CatalogItemPreview } from "../CatalogItem";

export const EmojiReaction = z.enum(["👍", "👎", "❤️", "😊", "😞", "✅"]);
export type EmojiReaction = z.infer<typeof EmojiReaction>;

export const MemberReaction = z.object({
    memberReaction: EmojiReaction,
    memberReactionTimestamp: FirebaseTimestamp,
});
export type MemberReaction = z.infer<typeof MemberReaction>;
const MemberReactionUndefined = z.object({
    memberReaction: z.undefined(),
    memberReactionTimestamp: z.undefined(),
});

export const MessageMeta = z.object({
    isInternalNote: z.literal(true).optional(),
    additionalNotificationAttempts: NotificationAttempts.optional(),
});

export const MessageRequest = z.object({
    request: FirebaseId.nullable().optional(),
    requestTitle: z.string().nullable().optional(),
});

export type MessageRequest = z.infer<typeof MessageRequest>;

export const MessageLink = z.object({
    url: z.string().url(),
});

export type MessageLink = z.infer<typeof MessageLink>;

export const MessageBody = z
    .object({
        message: z.string(),
        senderName: z.string(),
        senderFirstName: z.string().nullable().optional(),
        sentBy: AgentId.or(MemberId).or(AlbertineServiceAgentId),
        sendingId: z.string().nullable().optional(),

        member: MemberId.nullable().optional(),
        memberName: z.string().nullable().optional(),

        editedAt: FirebaseTimestamp.nullable().optional(),
        editedBy: z.string().nullable().optional(),
        editorName: z.string().nullable().optional(),
        messageHtml: z.string().optional().nullable(),
        sentByAgent: z.boolean().optional(),
        updatedAt: FirebaseTimestamp.nullable().optional(),

        link: MessageLink.optional(),

        attachment: Attachment.optional(), // deprecated
        attachments: z.array(Attachment).optional(),

        confirmedBooking: BookingToConfirmInMemberMessage.optional().nullable(),
        bookingSuggestions: z.array(BookingInMessage).optional().nullable(),

        articles: z.array(CatalogArticleItem).optional(), // deprecated but still in use for education and activation messages
        articlesV2: z.array(CatalogItemPreview).optional(),

        analysisStatus: MessageAnalysisStatus.optional(),
    })
    .merge(MessageRequest);

export type MessageBody = z.infer<typeof MessageBody>;

export const MessageBodyWithSentAt = z
    .object({
        sentAt: FirebaseTimestamp,
    })
    .merge(MessageBody);

export type MessageBodyWithSentAt = z.infer<typeof MessageBodyWithSentAt>;

export const MessageWithArticleInquiry = z.object({
    articleInquiry: ArticleInquiry.optional().nullable(), // deprecated from app version onwards 1.4.18
});
export type MessageWithArticleInquiry = z.infer<
    typeof MessageWithArticleInquiry
>;

export const MessageWithQuickRequest = z.object({
    quickRequest: QuickRequest.optional().nullable(),
});
export type MessageWithQuickRequest = z.infer<typeof MessageWithQuickRequest>;

export const ReplyToMessage = z.object({
    replyToMessageId: FirebaseId,
    replyToMessage: MessageBody.omit({ senderName: true, sentBy: true }),
});
export type ReplyToMessage = z.infer<typeof ReplyToMessage>;
export const ReplyToMessageUndefined = z.object({
    replyToMessageId: z.undefined(),
    replyToMessage: z.undefined(),
});

export const MessageDoc = MessageMeta.merge(MessageBodyWithSentAt)
    .merge(MessageWithArticleInquiry)
    .merge(MessageWithQuickRequest)
    .and(MemberReaction.or(MemberReactionUndefined))
    .and(ReplyToMessage.or(ReplyToMessageUndefined));
export type MessageDoc = z.infer<typeof MessageDoc>;

export const Message = z.object({ id: FirebaseId }).and(MessageDoc);
export type Message = z.infer<typeof Message>;

export const InternalNote = MessageMeta.merge(MessageBodyWithSentAt).merge(
    z.object({
        id: FirebaseId,
        isInternalNote: z.literal(true),
    }),
);
export type InternalNote = z.infer<typeof InternalNote>;

export const InternalNoteWithMention = InternalNote.and(Mention);
export type InternalNoteWithMention = z.infer<typeof InternalNoteWithMention>;

export const InternalNoteOrMention = InternalNote.or(InternalNoteWithMention);

export type InternalNoteOrMention = z.infer<typeof InternalNoteOrMention>;

export const MessageOrInternalNote = Message.or(InternalNote).or(
    InternalNoteWithMention,
);
export type MessageOrInternalNote = z.infer<typeof MessageOrInternalNote>;

export const NotificationPayloadData = z.object({ id: FirebaseId }).merge(
    MessageBody.pick({
        message: true,
        messageHtml: true,
        attachments: true,
        articles: true,
        bookingSuggestions: true,
        request: true,
        sentBy: true,
        senderName: true,
        requestTitle: true,
    }),
);
export type NotificationPayloadData = z.infer<typeof NotificationPayloadData>;

const SendMessageResponse = z.discriminatedUnion("success", [
    z.object({ success: z.literal(true), messageId: FirebaseId }),
    z.object({ success: z.literal(false), error: z.string() }),
]);

export type SendMessageResponse = z.infer<typeof SendMessageResponse>;

const AddInternalNoteResponse = z.discriminatedUnion("success", [
    z.object({ success: z.literal(true), noteId: FirebaseId }),
    z.object({ success: z.literal(false), error: z.string() }),
]);

export type AddInternalNoteResponse = z.infer<typeof AddInternalNoteResponse>;

const AgentNewMessagePayload = MessageBody.omit({
    senderName: true,
    sentBy: true,
});
export type AgentNewMessagePayload = z.infer<typeof AgentNewMessagePayload>;

export const AgentWriteInternalNotePayload = z.object({
    conversation: FirebaseId,
    note: AgentNewMessagePayload,
    mentions: z.array(AgentId.or(AlbertineServiceAgentId)).optional(),
    mentionDueDate: FirebaseTimestamp.optional(),
});

export type AgentWriteInternalNotePayload = z.infer<
    typeof AgentWriteInternalNotePayload
>;

export const AgentEditInternalNotePayload = z.object({
    conversation: FirebaseId,
    noteId: FirebaseId,
    note: AgentNewMessagePayload.optional(),
    mentions: z.array(AgentId.or(AlbertineServiceAgentId)).optional(),
    mentionStatus: MentionStatus.optional(),
    mentionDueDate: FirebaseTimestamp.optional().nullable(),
});

export type AgentEditInternalNotePayload = z.infer<
    typeof AgentEditInternalNotePayload
>;

export const AgentMessagePayload = z.object({
    conversation: FirebaseId,
    message: AgentNewMessagePayload,
});

export type AgentMessagePayload = z.infer<typeof AgentMessagePayload>;

export const AgentEditMessagePayload = z.object({
    conversation: FirebaseId,
    messageId: FirebaseId,
    update: MessageBody,
});

export type AgentEditMessagePayload = z.infer<typeof AgentEditMessagePayload>;

export const AgentDeleteMessagePayload = z.object({
    conversation: FirebaseId,
    messageId: FirebaseId,
});

export type AgentDeleteMessagePayload = z.infer<
    typeof AgentDeleteMessagePayload
>;

export const MemberSendMessagePayload = z.object({
    sendingId: z.string(),
    message: z.string(),
    request: z.string().optional().nullable(),
    requestTitle: z.string().optional().nullable(),
    replyToMessageId: FirebaseId.optional(),
    // TODO: Match with NewMessage type in the cloud function memberSendMessage
    // quickRequest: QuickRequest.optional(),
    // confirmedBooking: Booking.optional(),
    // articleInquiry: ArticleInquiry.optional(),
    // articlesV2: z.array(CatalogItemPreview).optional(),
});
export type MemberSendMessagePayload = z.infer<typeof MemberSendMessagePayload>;

export const MessageSendingStatus = z.enum(["sending", "sent", "failed"]);
export type MessageSendingStatus = z.infer<typeof MessageSendingStatus>;

export const LocalStorageChatMessage = z.object({
    message: MemberSendMessagePayload,
    sendingStatus: MessageSendingStatus,
});
export type LocalStorageChatMessage = z.infer<typeof LocalStorageChatMessage>;
export const LocalStorageChatMessages = z.array(LocalStorageChatMessage);
export type LocalStorageChatMessages = z.infer<typeof LocalStorageChatMessages>;
