import {
    signInWithPhoneNumber,
    RecaptchaVerifier,
    ConfirmationResult,
    isSignInWithEmailLink,
    signInWithEmailLink,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { z } from "zod";
import { auth, functions } from "../Firebase";
import { MemberCatalogConfiguration } from "../../../lmt/src/common/types/MemberCatalogConfiguration";

// TODO: See how member auth is done in iOS
// Consider whether we want a cloud function that handles it
// In any case, add function here to do the sdk connection and separate component that uses it and delivers it to components

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier;
        confirmationResult: ConfirmationResult;
    }
}

export function setupRecaptchaVerifier(elementId: string): RecaptchaVerifier {
    return new RecaptchaVerifier(auth, elementId, {
        size: "invisible",
        callback: () => {
            // TODO: success
        },
        "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
        },
    });
}

async function memberPhoneNumberLogin(phoneNumber: string, tenant: string) {
    return httpsCallable<
        { phoneNumber: string; tenant: string },
        { success: boolean }
    >(
        functions,
        "memberPhoneNumberLogin",
    )({
        phoneNumber,
        tenant,
    });
}

export async function sendSms(
    phoneNumber: string,
    tenant: string,
    appVerifier: RecaptchaVerifier,
): Promise<ConfirmationResult | undefined> {
    try {
        const response = await memberPhoneNumberLogin(phoneNumber, tenant);

        if (response.data.success) {
            const confirmationResult = await signInWithPhoneNumber(
                auth,
                phoneNumber,
                appVerifier,
            );

            return confirmationResult;
        }

        return undefined;
    } catch (error) {
        console.log("Recaptcha error", error);
        return undefined;
    }
}

const MemberDataPushNotifications = z.object({
    deviceId: z.string().min(1).optional(),
    pushNotificationsToken: z.string().min(1).optional(),
    pushNotificationsEnabled: z.boolean().optional(),
});

type MemberDataPushNotifications = z.infer<typeof MemberDataPushNotifications>;

export async function updateDeviceTokens(updates: MemberDataPushNotifications) {
    return httpsCallable(functions, "memberUpdateMemberData")(updates);
}

async function memberRequestEmailSignInLink(
    email: string,
    tenant: string,
    languageCode?: string,
) {
    return httpsCallable<
        { email: string; tenant: string; languageCode?: string },
        { success: boolean }
    >(
        functions,
        "memberRequestEmailSignInLink",
    )({
        email,
        tenant,
        languageCode,
    });
}

export async function sendEmailLink(
    email: string,
    tenant: string,
    languageCode?: string,
): Promise<ConfirmationResult | undefined> {
    try {
        await memberRequestEmailSignInLink(email, tenant, languageCode);
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}

export function checkTheSignInLink(email: string) {
    if (isSignInWithEmailLink(auth, window.location.href)) {
        signInWithEmailLink(auth, email, window.location.href)
            .then(() => {
                console.log("Successfully signed in!");
            })
            .catch((error) => {
                console.error("Error signing in with email link:", error);
            });
    }
}

export async function memberGetCatalogConfiguration(): Promise<MemberCatalogConfiguration> {
    return (
        await httpsCallable<void, MemberCatalogConfiguration>(
            functions,
            "memberGetCatalogConfiguration",
        )()
    ).data;
}
