import React from "react";
import { Lib } from "albertine-shared-web";
import "./Loading.css";
import classNames from "classnames";

interface ILoading {
    className?: string;
}

function Loading(props: ILoading) {
    const { className } = props;

    return (
        <Lib.Flex.Column
            alignItems="center"
            justifyContent="center"
            className={classNames("loading", className)}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="36"
                height="36"
            >
                <radialGradient
                    id="a7"
                    cx=".66"
                    fx=".66"
                    cy=".3125"
                    fy=".3125"
                    gradientTransform="scale(1.5)"
                >
                    <stop offset="0" stopColor="#808E9C" />
                    <stop offset=".3" stopColor="#808E9C" stopOpacity=".9" />
                    <stop offset=".6" stopColor="#808E9C" stopOpacity=".6" />
                    <stop offset=".8" stopColor="#808E9C" stopOpacity=".3" />
                    <stop offset="1" stopColor="#808E9C" stopOpacity="0" />
                </radialGradient>
                <circle
                    fill="none"
                    stroke="url(#a7)"
                    strokeWidth="22"
                    strokeLinecap="round"
                    strokeDasharray="200 1000"
                    strokeDashoffset="0"
                    cx="100"
                    cy="100"
                    r="70"
                    className="loading_circle"
                >
                    <animateTransform
                        type="rotate"
                        attributeName="transform"
                        calcMode="spline"
                        dur="1"
                        values="360;0"
                        keyTimes="0;1"
                        keySplines="0 0 1 1"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    fill="none"
                    opacity=".2"
                    stroke="#808E9C"
                    strokeWidth="22"
                    strokeLinecap="round"
                    cx="100"
                    cy="100"
                    r="70"
                    className="loading_circle"
                />
            </svg>
        </Lib.Flex.Column>
    );
}

export default Loading;
