import {
    addDays,
    differenceInCalendarDays,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInYears,
    format,
    isMatch,
    isSameDay,
    Locale,
    parse,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { z } from "zod";
import { IsoDateTimeString } from "../validate";

export const dateWithShortMonthWithTimeFormat = "dd MMM yyyy, HH:mm";

const defaultDateFormat = "dd/MM/yyyy";
const defaultTimeFormat = "HH:mm";
export const defaultDateTimeFormat = `${defaultDateFormat}, ${defaultTimeFormat}`;
export const defaultTimezone = "Europe/London";

export const WeekdayCodeArray = [1, 2, 3, 4, 5, 6, 0];
export const WeekdayNameArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const WeekdayAbbrevArrayFromSunday = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];
export const WeekdayAbbrevArray = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
];

export function defaultDateTimeFormatter(date: Date) {
    return format(date, defaultDateTimeFormat);
}

// TODO: deprecated, use time.formatted.util instead
export function showTimeComparison(date: Date, now?: Date) {
    const compareTo = now || new Date();
    const tomorrow = addDays(compareTo, 1);
    const isInPast = date < compareTo;
    const minutes = differenceInMinutes(compareTo, date);
    const isNow = minutes > -1 && minutes < 1;
    if (isNow) {
        return "now";
    }
    if (isInPast) {
        if (minutes < 60) {
            return `${minutes} min ago`;
        }
        const hours = differenceInHours(compareTo, date);
        if (hours < 24) {
            return `${hours} hr ago`;
        }
        const days = differenceInDays(compareTo, date);
        if (days < 30) {
            return `${days} ${days > 1 ? "days" : "day"} ago`;
        }
        return format(date, defaultDateFormat);
    }
    if (isSameDay(compareTo, date)) {
        return format(date, defaultTimeFormat);
    }
    if (isSameDay(tomorrow, date)) {
        return `Tomorrow, ${format(date, defaultTimeFormat)}`;
    }
    return defaultDateTimeFormatter(date);
}

/**
 * Compute the difference between the given dates in days or months or years format.
 *
 * @param fromDate start date for comparison
 * @param toDate end date for comparison
 * @returns A string representation of difference between dates in terms of days or months or years.
 */
export function showDayMonthYearComparison(
    fromDate: Date,
    toDate: Date,
): string | undefined {
    const isInPast = toDate < fromDate;
    const days = Math.abs(differenceInCalendarDays(toDate, fromDate));
    const months = Math.abs(differenceInMonths(fromDate, toDate));

    if (days <= 30 || months === 0) {
        return isInPast
            ? `${days} ${days > 1 ? "days" : "day"} ago`
            : `${days} ${days > 1 ? "days" : "day"}`;
    }

    if (months < 12) {
        return isInPast
            ? `${months} ${months > 1 ? "months" : "month"} ago`
            : `${months} ${months > 1 ? "months" : "month"}`;
    }
    const years = Math.abs(differenceInYears(fromDate, toDate));
    return isInPast
        ? `${years} ${years > 1 ? "years" : "year"} ago`
        : `${years} ${years > 1 ? "years" : "year"}`;
}

export const localISODateFormat = "yyyy-MM-dd";

export function toDateLocalInputValue(date: Date | null): string {
    return date ? format(date, localISODateFormat) : "";
}

export function timeToDate(
    HHmm: string,
    date: Date | undefined = new Date(),
): Date {
    return new Date(`${toDateLocalInputValue(date)} ${HHmm}`);
}

export function utcDateFromTimeStrings(
    date: string,
    time: string | null | undefined,
    timezone: string,
    defaultTime: string = "12:00",
): Date {
    return fromZonedTime(`${date} ${time || defaultTime}`, timezone);
}

/* HH:mm, 24 hour clock */
const twentyFourHourTimeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

/* yyyy-MM-DD */
const dateRegex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;

export const NumberOfGuests = z
    .string()
    .regex(/^\d+$/, "invalid number of guests."); // TODO: Use validatorjs isInt() instead https://github.com/validatorjs/validator.js

export const LocalDate = z.string().refine((value) => {
    if (!dateRegex.test(value)) {
        return false;
    }
    if (isMatch(value, "yyyy-MM-dd")) {
        return true;
    }
    return false;
}, "invalid date format");
export type LocalDate = z.infer<typeof LocalDate>;

export const LocalTime = z.string().refine((value) => {
    if (!twentyFourHourTimeRegex.test(value)) {
        return false;
    }
    if (isMatch(value, "HH:mm")) {
        return true;
    }
    return false;
}, "invalid time format");

export function toIsoStringOrNull(date: any): string | null {
    if (date instanceof Date) {
        return date.toISOString();
    }
    if (date === null || date === undefined || date === "") {
        return null;
    }
    if (IsoDateTimeString.safeParse(date).success) {
        return date;
    }
    return null;
}

export function formatDateInTimeZone(date: Date, timezone: string) {
    return format(
        toZonedTime(date, timezone),
        dateWithShortMonthWithTimeFormat,
    );
}

export function localDateToDayDateString(
    localDate: LocalDate,
    locale: Locale,
): string {
    try {
        const parsed = parse(localDate, "yyyy-MM-dd", new Date());
        // default add 12:00:00 to the date to avoid timezone issues
        const dateWithLocalNoon = new Date(
            parsed.getFullYear(),
            parsed.getMonth(),
            parsed.getDate(),
            12,
            0,
            0,
        );
        if (!dateWithLocalNoon.getTime()) return "";
        const isCurrentYear =
            dateWithLocalNoon.getFullYear() === new Date().getFullYear();
        if (isCurrentYear) {
            return format(dateWithLocalNoon, "EEE d MMM", { locale });
        }
        return format(dateWithLocalNoon, "EEE d MMM yyyy", { locale });
    } catch (error) {
        return "";
    }
}
