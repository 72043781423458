import { z } from "zod";
import { IsoDateTimeString, MemberId } from "../validate";
import { OpenSearchVisibilityFilter } from "./OpenSearch";

export const dynamicThemeNameMaxLength = 255;

export const ThemeType = z.enum([
    "spotlight",
    "static",
    "dynamic",
    "cityGuide",
    "category",
]);
export type ThemeType = z.infer<typeof ThemeType>;

export const ThemeLocation = z.object({
    city: z.string().nullable(),
    country: z.string(),
    countryCode: z.string().max(2),
});
export type ThemeLocation = z.infer<typeof ThemeLocation>;

export const ThemePreference = z.enum([
    "travel",
    "wellness",
    "dining",
    "sportsEvents",
    "culturalEvents",
    "localExperiences",
]);
export type ThemePreference = z.infer<typeof ThemePreference>;

const ThemeGroup = z.object({ id: z.string(), name: z.string() });

export type ThemeGroup = z.infer<typeof ThemeGroup>;

export const ThemePreferenceTextMap: { [key in ThemePreference]: string } = {
    culturalEvents: "Cultural events",
    dining: "Dining",
    localExperiences: "Local experiences",
    travel: "Travel",
    sportsEvents: "Sports events",
    wellness: "Wellness",
};

export const DynamicTheme = z.object({
    key: z.string(),
    name: z.string(),
    published: IsoDateTimeString.nullable(),
    slugs: z.array(z.string()).nullable(),
    image: z.string().url().nullable(),
    query: z.string(),
    type: ThemeType,
    location: z.array(ThemeLocation),
    group: ThemeGroup.nullable(),
    preferences: z.array(ThemePreference),
});

export type DynamicTheme = z.infer<typeof DynamicTheme>;

export const ManageThemesPayload = z.discriminatedUnion("action", [
    z.object({
        action: z.literal("create"),
        theme: DynamicTheme,
    }),
    z.object({
        action: z.literal("read"),
        themeKey: z.string(),
    }),
    z.object({
        action: z.literal("getAll"),
    }),
    z.object({
        action: z.literal("update"),
        theme: DynamicTheme,
    }),
    z.object({
        action: z.literal("delete"),
        themeKey: z.string(),
    }),
    z.object({
        action: z.literal("getMembersThemes"),
        memberId: MemberId.nullable(),
    }),
]);

export type ManageThemesPayload = z.infer<typeof ManageThemesPayload>;

export const ManageThemesResponse = z.discriminatedUnion("action", [
    z.object({
        action: z.literal("create"),
        theme: DynamicTheme,
    }),
    z.object({
        action: z.literal("read"),
        theme: DynamicTheme,
    }),
    z.object({
        action: z.literal("getAll"),
        themes: z.array(DynamicTheme),
    }),
    z.object({
        action: z.literal("update"),
        theme: DynamicTheme,
    }),
    z.object({
        action: z.literal("delete"),
    }),
    z.object({
        action: z.literal("getMembersThemes"),
        themes: z.array(DynamicTheme),
        topCities: z.array(z.string()),
    }),
]);

export type ManageThemesResponse = z.infer<typeof ManageThemesResponse>;

export type ThemePreferenceScoreMap = {
    [key in ThemePreference]: number;
};

export function zeroThemePreferenceScoreMap(): ThemePreferenceScoreMap {
    return {
        travel: 0,
        wellness: 0,
        dining: 0,
        sportsEvents: 0,
        culturalEvents: 0,
        localExperiences: 0,
    };
}

export const DynamicThemeFormSchema = z.object({
    published: IsoDateTimeString.nullable(),
});

export type DynamicThemeFormSchema = z.infer<typeof DynamicThemeFormSchema>;

export const StaticThemeFormSchema = z.object({
    name: z
        .string({ required_error: "Add name" })
        .min(3, "Add name")
        .max(dynamicThemeNameMaxLength, "Limit for name is 255 characters"),
    slugs: z
        .array(z.string({ required_error: "Add at least one article" }))
        .min(1, "Add at least one article"),
    preferences: z.array(ThemePreference),
    location: z.array(ThemeLocation),
    published: IsoDateTimeString.nullable(),
});

export type StaticThemeFormSchema = z.infer<typeof StaticThemeFormSchema>;

export function themeLocationToText(themeLocation: ThemeLocation) {
    return themeLocation.city
        ? `${themeLocation.city}, ${themeLocation.country}`
        : `${themeLocation.country}`;
}

export type ThemeQueryFilters = {
    nameSearchTerm: string | undefined;
    location: ThemeLocation | undefined;
    preferences: ThemePreference[] | undefined;
    published: OpenSearchVisibilityFilter | undefined;
};
