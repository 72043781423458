import React, { useEffect, useState } from "react";
import { bookingDocument } from "../api/firestore";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import { loadToDocumentToState } from "../api/loaders";

type Props = {
    memberId: string;
    bookingId: string;
    children: (booking: MemberBooking | undefined) => React.ReactNode;
};

function LoadBooking(props: Props) {
    const { memberId, bookingId, children } = props;
    const [booking, setBooking] = useState<MemberBooking>();

    useEffect(() => {
        if (!memberId) return undefined;
        if (!bookingId) return undefined;

        loadToDocumentToState(
            bookingDocument(memberId, bookingId),
            MemberBooking,
            setBooking,
        );

        return () => setBooking(undefined);
    }, [memberId, bookingId]);

    return children(booking);
}

export default LoadBooking;
