/* eslint-disable no-underscore-dangle */
import { OpenSearchContentConfiguration } from "../../types/ContentConfiguration";
import { DynamicTheme } from "../../types/DynamicTheme";
import {
    OpenSearchPagination,
    OpenSearchQuery,
    setQueryPagination,
} from "../../types/OpenSearch";
import {
    OpenSearchCatalogHitSource,
    OpenSearchCatalogResponse,
} from "../../types/OpenSearch.CatalogItem";
import {
    QuerySpotlight,
    ThemeQuery,
    ThemeResponse,
    getQueriesForNextPages,
    themesQuery,
    themeResponseToThemes,
    OpenSearchTheme,
} from "../../types/OpenSearch.Theme";
import { OpenSearchWebClient } from "./client";
import { CatalogIndex, ConfigurationIndex, ThemesIndex } from "./constants";

export async function getSpotlightThemes(
    opensearch: OpenSearchWebClient,
): Promise<OpenSearchTheme[]> {
    const spotlightResponse = await opensearch.search(
        ThemesIndex,
        QuerySpotlight,
    );
    const spotlightThemes = ThemeResponse.parse(
        spotlightResponse,
    ).hits.hits.map((hit) => hit._source);
    return spotlightThemes;
}

export async function getThemesPage(
    opensearch: OpenSearchWebClient,
    query: ThemeQuery,
) {
    const themesResponse = await opensearch.search(ThemesIndex, query);
    const parsedResponse = ThemeResponse.parse(themesResponse);
    return parsedResponse;
}

function toDynamicTheme(theme: OpenSearchTheme): DynamicTheme {
    return {
        ...theme,
        location: [],
        preferences: [],
        group: null,
        type: "static",
        image: null,
    };
}

export function toDynamicThemes(themes: OpenSearchTheme[]): DynamicTheme[] {
    return themes.map(toDynamicTheme);
}

export async function getStaticThemeArticles(
    opensearch: OpenSearchWebClient,
    theme: OpenSearchTheme,
    pagination: OpenSearchPagination = { from: 0, size: 100 },
): Promise<OpenSearchCatalogHitSource[]> {
    const query = JSON.parse(toDynamicTheme(theme).query) as OpenSearchQuery;
    const response = await opensearch.search(
        CatalogIndex,
        setQueryPagination(query, pagination),
    );
    const parsed = OpenSearchCatalogResponse.parse(response);
    return parsed.hits.hits.map((hit) => ({ ...hit._source, id: hit._id }));
}

export async function getDynamicThemes(
    opensearch: OpenSearchWebClient,
): Promise<OpenSearchContentConfiguration> {
    const response = await opensearch.getDocumentById<any>(
        ConfigurationIndex,
        "content",
        OpenSearchContentConfiguration,
    );
    return response;
}

export async function getArticle(
    opensearch: OpenSearchWebClient,
    articleId: string,
): Promise<OpenSearchCatalogHitSource> {
    const response = await opensearch.getDocumentById<any>(
        CatalogIndex,
        articleId,
        OpenSearchCatalogHitSource,
    );
    return response;
}

export async function getThemes(
    opensearch: OpenSearchWebClient,
): Promise<DynamicTheme[]> {
    const spotlightThemes = await getSpotlightThemes(opensearch);
    const themesFirstPage = await getThemesPage(
        opensearch,
        themesQuery(0, 100),
    );
    const themes: OpenSearchTheme[] = themeResponseToThemes(themesFirstPage);
    const themesTotalCount = themesFirstPage.hits.total.value;

    if (themesTotalCount > 100) {
        const queries = getQueriesForNextPages(themesTotalCount);
        const promises = queries.map((query) =>
            getThemesPage(opensearch, query).then(themeResponseToThemes),
        );
        const responses: OpenSearchTheme[] = (
            await Promise.all(promises)
        ).flatMap((x) => x);
        const themeResults = [spotlightThemes, themes, responses];
        return themeResults.flatMap(toDynamicThemes);
    }
    return [spotlightThemes, themes].flatMap(toDynamicThemes);
}
