import React from "react";
import { Lib } from "albertine-shared-web";
import "./ArticleTagTable.css";
import { Tag } from "../../../lmt/src/common/tags";
import { categorizeTags, mapTagsToReadableArray } from "../utils/tags.util";

interface Props {
    tags: Tag[];
}

function ArticleTagTable(props: Props) {
    const { tags } = props;
    const categories = categorizeTags(tags);
    const readableTags = mapTagsToReadableArray(categories);

    return (
        <Lib.Flex.Column className="article-tag-table">
            {readableTags.map((tag) => (
                <Lib.Flex.Column
                    key={tag.title}
                    className="article-tag-table__row"
                >
                    <Lib.Flex.Row
                        className="article-tag-table__row-text"
                        gap="value16"
                    >
                        <Lib.Label.Medium.Reqular>
                            {tag.title}
                        </Lib.Label.Medium.Reqular>

                        <Lib.Label.Medium.MediumBold>
                            {tag.values as string}
                        </Lib.Label.Medium.MediumBold>
                    </Lib.Flex.Row>
                    <Lib.Line.Dashed />
                </Lib.Flex.Column>
            ))}
        </Lib.Flex.Column>
    );
}

export default ArticleTagTable;
