/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import classNames from "classnames";
import { Lib } from "albertine-shared-web";
import "./MemberTopThemesItem.css";

type Props = {
    className?: string;
    index: number;
    name: string;
    keyDetail: string | null;
    background: string;
    onClick?: () => void;
};
type MemberTopThemesItem = (
    className?: string,
) => (props: Props) => JSX.Element;

function MemberTopThemesItem(props: Props) {
    const { className, index, name, keyDetail, background, onClick } = props;
    return (
        <div
            className={classNames(
                "web-lib__member-top-themes__item",
                className,
            )}
            onClick={onClick}
        >
            {background && index !== 2 && (
                <>
                    <Lib.RemoteImage.Square.Large
                        src={background}
                        alt={name}
                        instantLoad
                    />

                    <div className="web-lib__member-top-themes__square-item-text">
                        <Lib.Heading.H3.XBold className="web-lib__member-top-themes__item-text-title">
                            {name}
                        </Lib.Heading.H3.XBold>
                        <Lib.Label.Medium.MediumBold.TextColor02>
                            {keyDetail}
                        </Lib.Label.Medium.MediumBold.TextColor02>
                    </div>
                </>
            )}

            {background && index === 2 && (
                <>
                    <Lib.RemoteImage.Card.Tall
                        className="web-lib__member-top-themes__tall-card"
                        src={background}
                        alt={name}
                        instantLoad
                    />

                    <div className="web-lib__member-top-themes__tall-item__text text-color-inversable">
                        <Lib.Heading.H3.XBold className="web-lib__member-top-themes__tall-item__text-title">
                            {name}
                        </Lib.Heading.H3.XBold>
                        <Lib.Label.Medium.MediumBold.TextColor02>
                            {keyDetail}
                        </Lib.Label.Medium.MediumBold.TextColor02>
                    </div>
                </>
            )}
        </div>
    );
}
export default MemberTopThemesItem;
