import { z } from "zod";

export const RequestOutcome = z.enum([
    "notBooked",
    "booked",
    "noBookingNeeded",
    "memberCancelled",
    "noActionNeeded",
]);

export type RequestOutcome = z.infer<typeof RequestOutcome>;

export const RequestOutcomeMap: { [key in RequestOutcome]: string } = {
    notBooked: "Not converted to Booking",
    booked: "Booked",
    noBookingNeeded: "Information request only",
    memberCancelled: "Member cancelled",
    noActionNeeded: "No further action",
};

export const RequestBookingOutcome = z.enum([
    "firstChoiceBooked",
    "alternativeChoiceBooked",
]);

export type RequestBookingOutcome = z.infer<typeof RequestBookingOutcome>;

export const RequestBookingOutcomeMap: {
    [key in RequestBookingOutcome]: string;
} = {
    firstChoiceBooked: "First choice booked",
    alternativeChoiceBooked: "Alternative accepted",
};
