/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import { listenToDocumentToState } from "../api/listeners";
import { MemberRequest } from "../../../lmt/src/common/types/Request";
import { memberRequestDocument } from "../api/firestore";

interface Props {
    memberId: string | undefined;
    requestId: string | undefined;
    children: (request: MemberRequest) => JSX.Element;
    isLoading?: () => JSX.Element;
}

export default function ListenToRequest(props: Props) {
    const { memberId, requestId, children, isLoading } = props;
    const [request, setRequest] = useState<MemberRequest | undefined | null>();

    useEffect(() => {
        if (!memberId || !requestId) {
            setRequest(undefined);
            return undefined;
        }
        return listenToDocumentToState<MemberRequest>(
            memberRequestDocument(memberId, requestId),
            MemberRequest,
            setRequest,
        );
    }, [memberId, requestId]);

    if (!request) return isLoading ? isLoading() : null;
    return children(request);
}

interface PropsOptional {
    requestId?: string | undefined;
    children: (request: MemberRequest | undefined) => JSX.Element;
}

export function ListenToRequestOptional(props: Props & PropsOptional) {
    const { memberId, requestId, children, isLoading } = props;

    if (!requestId) return children(undefined);
    return (
        <ListenToRequest
            memberId={memberId}
            requestId={requestId}
            isLoading={isLoading}
            children={children}
        />
    );
}
