import { z } from "zod";

export const RequestStatus = z.enum([
    "new",
    "draft",
    "open",
    "confirmed",
    "closed",
]);

export type RequestStatus = z.infer<typeof RequestStatus>;

export const RequestStatusList = RequestStatus.options;

export const RequestStatusMap: { [key in RequestStatus]: string } = {
    new: "New",
    draft: "Draft",
    open: "Open",
    confirmed: "Confirmed",
    closed: "Closed",
};

export const RequestStatusToTimestampMap: {
    [key in RequestStatus]: string | undefined;
} = {
    new: undefined,
    draft: undefined,
    open: "openedAt",
    confirmed: "confirmedAt",
    closed: "closedAt",
};
