import React from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import "./PrivacyPolicy.css";
import { useScreenStack } from "../context/screenStack";
import Screen from "./Screen";

function TermsAndConditions() {
    const t = useAlbertineTranslation();
    const { closeStackScreen } = useScreenStack();

    return (
        <Screen.ProfileBg02>
            <Lib.Flex.Row
                justifyContent="center"
                className="privacy-policy__header"
            >
                <Lib.Button.Ghost onClick={closeStackScreen}>
                    <Lib.Icon.ArrowBack.Medium className="privacy-policy__back-button" />
                </Lib.Button.Ghost>

                <div>
                    <Lib.Heading.H5.Bold>
                        {t("profile__privacy-policy")}
                    </Lib.Heading.H5.Bold>
                </div>
            </Lib.Flex.Row>
            <iframe
                src="https://www.albertineapp.com/privacy-policy/?app-embed"
                title={t("albertine__privacy-policy")}
                className="privacy-policy__iframe"
            />
        </Screen.ProfileBg02>
    );
}

export default TermsAndConditions;
