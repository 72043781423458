export type OpenSearchFetchWrapper = (
    path: string,
    request: RequestInit,
) => Promise<Response>;

export type OpenSearchCall = (
    path: string,
    request: RequestInit,
) => Promise<any>;

export interface OpenSearchFetch {
    fetchWrapper: OpenSearchFetchWrapper;
    callOpenSearch: OpenSearchCall;
}

export class OpenSearchError extends Error {
    constructor(
        message: string,
        public readonly response: any,
    ) {
        super(message);
        this.response = response;
    }
}

async function fetchWrapper(
    baseUrl: string,
    Authorization: string,
    path: string,
    request: RequestInit,
): Promise<Response> {
    const requestWithAuth: RequestInit = {
        ...request,
        headers: {
            ...request.headers,
            Authorization,
        },
    };
    return fetch(`${baseUrl}/${path}`, requestWithAuth);
}

async function callOpenSearch(
    baseUrl: string,
    authorization: string,
    path: string,
    request: RequestInit,
): Promise<any> {
    const url = `${baseUrl}/${path}`;
    const result = await fetchWrapper(baseUrl, authorization, path, request);

    if (!result.ok) {
        const response = await result.json();
        const message =
            result.status === 404 ? "Not found" : "Failed to call OpenSearch";
        throw new OpenSearchError(message, {
            url,
            request,
            response,
            status: result.status,
            statusText: result.statusText,
        });
    }

    return result.json();
}

export function openSearchFetch(
    url: string,
    Authorization: string,
): OpenSearchFetch {
    return {
        fetchWrapper: (path, request) =>
            fetchWrapper(url, Authorization, path, request),
        callOpenSearch: (path, request) =>
            callOpenSearch(url, Authorization, path, request),
    };
}
