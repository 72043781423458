import { Lib } from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import { getStaticThemeArticles } from "../../../lmt/src/common/utils/opensearch/themes";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import { OpenSearchCatalogHitSource } from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import { useOpenSearch } from "../context/opensearch";
import AlbertineSlider from "./AlbertineSlider";
import { useScreenStack } from "../context/screenStack";

function Spotlight(props: { theme: DynamicTheme }) {
    const { theme } = props;
    const [articles, setArticles] = useState<OpenSearchCatalogHitSource[]>();
    const { opensearch } = useOpenSearch();
    const { openArticle } = useScreenStack();

    useEffect(() => {
        async function fetchArticles() {
            if (opensearch) {
                const themeArticles = await getStaticThemeArticles(
                    opensearch,
                    theme,
                );
                setArticles(themeArticles);
            }
        }

        fetchArticles();
    }, [theme, opensearch]);

    return (
        <Lib.Flex.Row>
            <AlbertineSlider slidesToShow={3}>
                {articles?.map((article: OpenSearchCatalogHitSource) => {
                    const { id, name, keyDetail, images } = article;
                    return (
                        <Lib.Flex.Column key={id}>
                            <Lib.SpotlightItem
                                name={name}
                                keyDetail={keyDetail}
                                background={images ? images[0] : ""}
                                onClick={() => openArticle(id)}
                            />
                        </Lib.Flex.Column>
                    );
                })}
            </AlbertineSlider>
        </Lib.Flex.Row>
    );
}

export default Spotlight;
