import { z } from "zod";
import { isTypeOf } from "./zod.util";
import { ImageKitUrlPrefix } from "../types/Attachment";

const storyblokAmazonDomain = "https://s3.amazonaws.com/a.storyblok.com";
const storyblokCdnDomain = "https://a.storyblok.com";

// https://docs.imagekit.io/features/image-transformations
function transformImageKitUrl(
    url: string,
    options?: { height?: number; width?: number },
): string {
    const { height, width } = options || {};
    const urlParts = url.split("/");
    const filename = urlParts.pop();
    const path = urlParts.join("/");
    return `${path}/tr:f-avif${width ? `,w-${width}` : ""}${height ? `,h-${height}` : ""}/${filename}`;
}

export function cdnImage(
    filename: string,
    options?: { height?: number; width?: number },
) {
    if (filename.startsWith(ImageKitUrlPrefix)) {
        return transformImageKitUrl(filename, options);
    }

    if (
        !filename.startsWith(storyblokCdnDomain) &&
        !filename.startsWith(storyblokAmazonDomain)
    ) {
        return filename;
    }

    if (
        filename.includes(storyblokAmazonDomain) ||
        filename.includes(storyblokCdnDomain)
    ) {
        // https://www.storyblok.com/docs/image-service/#resizing
        const imageResize = options
            ? `${options.width || 0}x${options.height || 0}`
            : "";

        return `${filename.replace(
            storyblokAmazonDomain,
            storyblokCdnDomain,
        )}/m/${imageResize}`;
    }

    return filename;
}

export const SupportedImageMimeType = z.enum([
    "image/avif",
    "image/jpeg",
    "image/png",
    "image/webp",
]);
export type SupportedImageMimeType = z.infer<typeof SupportedImageMimeType>;
export const supportedImageTypes = SupportedImageMimeType.options;

export function mimeTypeToExtension(
    mimeType: string,
): "jpeg" | "png" | "webp" | "avif" | undefined {
    if (!isTypeOf(mimeType, SupportedImageMimeType)) {
        return undefined;
    }

    switch (mimeType) {
        case "image/avif":
            return "avif";
        case "image/jpeg":
            return "jpeg";
        case "image/png":
            return "png";
        case "image/webp":
            return "webp";
        default:
            return undefined;
    }
}
