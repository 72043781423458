import {
    Tag,
    TagCategoryToDisplayName,
    TagToDisplayName,
} from "../../../lmt/src/common/tags";

export function categorizeTags(tags: Tag[]) {
    const categories: { [key: string]: string[] } = {};
    tags.forEach((tag) => {
        const [key, value] = tag.split(":");
        if (!categories[key]) {
            categories[key] = [];
        }
        categories[key].push(value);
    });
    return categories;
}

export function mapTagsToReadableArray(tags: { [key: string]: string[] }) {
    return Object.keys(tags).map((category) => {
        const title =
            TagCategoryToDisplayName[
                category as keyof typeof TagCategoryToDisplayName
            ];
        const values = tags[category]
            .map(
                (tag) =>
                    TagToDisplayName[
                        `${category}:${tag}` as keyof typeof TagToDisplayName
                    ],
            )
            .join(", ");
        return { title, values };
    });
}
