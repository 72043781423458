import { z } from "zod";

export const RequestSource = z.enum(["reactive", "proactive"]);

export type RequestSource = z.infer<typeof RequestSource>;

export const RequestSourceMap: { [key in RequestSource]: string } = {
    reactive: "Member (reactive)",
    proactive: "Albertine (proactive)",
};
