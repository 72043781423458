import React from "react";
import "./Proposal.css";
import { Lib } from "albertine-shared-web";
import LoadProposal from "../loaders/LoadProposal";
import Loading from "./Loading";
import { transformBookingToPublic } from "../loaders/LoadPublicBooking";
import Screen from "./Screen";
import Header, { BackButton } from "../components/Header";
import { useScreenStack } from "../context/screenStack";

interface Props {
    requestId: string;
}

function Proposal(props: Props) {
    const { requestId } = props;
    const { openChat } = useScreenStack();

    return (
        <LoadProposal requestId={requestId}>
            {(proposal) => {
                if (!proposal) return <Loading />;

                return (
                    <>
                        <Header
                            title={proposal.request.title || ""}
                            type="proposal"
                        />
                        <Screen.BookingBg04 className="">
                            <BackButton />
                            <Lib.Flex.Column>
                                <Lib.Proposal.Header
                                    id={proposal.request.id}
                                    title={proposal.request.title || ""}
                                    startDate={
                                        proposal.request.startDate || undefined
                                    }
                                    startTime={
                                        proposal.request.startTime || undefined
                                    }
                                    endDate={
                                        proposal.request.endDate || undefined
                                    }
                                    endTime={
                                        proposal.request.endTime || undefined
                                    }
                                    numberOfGuests={
                                        proposal.request.numberOfGuests ||
                                        undefined
                                    }
                                    bookings={
                                        proposal.bookings.map((b) =>
                                            transformBookingToPublic(b),
                                        ) || []
                                    }
                                />

                                {proposal.bookings.map((booking, index) => (
                                    <Lib.Proposal.ProposalBooking
                                        key={booking.id}
                                        booking={transformBookingToPublic(
                                            booking,
                                        )}
                                        index={index}
                                        requestId={proposal.request.id}
                                    />
                                ))}

                                <Lib.CallToAction
                                    openChat={() => {
                                        openChat(
                                            proposal.request.status === "draft"
                                                ? "all"
                                                : proposal.request.id,
                                        );
                                    }}
                                />
                            </Lib.Flex.Column>
                        </Screen.BookingBg04>
                    </>
                );
            }}
        </LoadProposal>
    );
}

export default Proposal;
