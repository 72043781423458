import { z } from "zod";
import {
    OpenSearchHit,
    OpenSearchIndexConfiguration,
    OpenSearchQuery,
} from "../../types/OpenSearch";
import { OpenSearchCall, OpenSearchError } from "./fetch";

// TODO: This type should be fixed so that it doesn't return zod any but actual type of T
export type GetDocumentById = <T extends z.ZodTypeAny>(
    indexName: string,
    id: string,
    schema: T,
) => Promise<T>;

export type Search = <Query extends OpenSearchQuery>(
    index: string,
    query: Query,
) => Promise<any>;

export interface OpenSearchWebClient {
    search: Search;
    getDocumentById: GetDocumentById;
    indexDocumentCount: (indexName: string) => Promise<number>;
}

export interface OpenSearchManagementClient extends OpenSearchWebClient {
    getDocumentById: GetDocumentById;
    createIndex: (
        indexName: string,
        mappings: OpenSearchIndexConfiguration,
    ) => Promise<any>;
    getIndexMappings: (indexName: string) => Promise<any>;
    deleteIndex: (indexName: string) => Promise<any>;
    indexExists: (indexName: string) => Promise<boolean>;
    bulkWrite: <T>(
        indexName: string,
        data: T[],
        idField: keyof T,
    ) => Promise<any>;
    bulkWriteAndWait: <T>(
        indexName: string,
        data: T[],
        idField: keyof T,
        maxRetries?: number,
        retryInterval?: number,
    ) => Promise<any>;
    reindex: (sourceIndex: string, destinationIndex: string) => Promise<any>;
    reindexAndWait: (
        sourceIndex: string,
        destinationIndex: string,
        maxRetries?: number,
        retryInterval?: number,
    ) => Promise<any>;
    writeDocument: (indexName: string, id: string, data: any) => Promise<any>;
    updateDocument: (indexName: string, id: string, data: any) => Promise<any>;
    deleteDocument: (indexName: string, id: string) => Promise<any>;
    search: Search;
}

export async function getDocumentById<T extends z.ZodTypeAny>(
    indexName: string,
    id: string,
    schema: T,
    callOpenSearch: OpenSearchCall,
): Promise<T> {
    const response = await callOpenSearch(`${indexName}/_doc/${id}`, {
        method: "GET",
    });
    // eslint-disable-next-line no-underscore-dangle
    const result = OpenSearchHit(schema).parse(response)._source;
    if (!result) {
        throw new OpenSearchError("Failed to parse document", {
            response,
            indexName,
            id,
            schema,
        });
    }
    return result;
}

export async function callSearch<Query extends OpenSearchQuery>(
    index: string,
    query: Query,
    callOpenSearch: (path: string, request: RequestInit) => Promise<any>,
): Promise<any> {
    return callOpenSearch(`${index}/_search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(query),
    });
}

export async function indexDocumentCount(
    indexName: string,
    callOpenSearch: OpenSearchCall,
): Promise<number> {
    const result = await callOpenSearch(`${indexName}/_count`, {
        method: "GET",
    });
    return z.object({ count: z.number() }).parse(result).count;
}
