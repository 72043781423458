import { z } from "zod";
import { PublicProposal } from "albertine-shared-web/types/PublicProposal";
import {
    AgentId,
    FirebaseId,
    MediumLongString,
    MemberId,
    Rating,
} from "../validate";
import { OfferingCategory } from "./OfferingCategory";
import { DeadlineStep } from "./Deadline";
import { FirebaseTimestamp } from "./FirebaseTimestamp";
import { MemberSatisfaction } from "./MemberSatisfaction";
import { RequestBookingOutcome, RequestOutcome } from "./RequestOutcome";
import { RequestSource } from "./RequestSource";
import { RequestStatus } from "./RequestStatus";
import { LocalDate, LocalTime } from "../utils/time.util";
import { AddressComponents } from "../CatalogItem";
import { MemberBooking } from "./Booking";

export const RequestRequired = z.object({
    conversation: FirebaseId,
    createdBy: AgentId.optional().nullable(),
    member: MemberId,
    status: RequestStatus,
    createdFromMessage: FirebaseId.nullable().optional(),
    memberAssignedTo: AgentId.nullable().optional(),
});

export const RequestRequiredUpdatables = z.object({
    assignedTo: AgentId.nullable().optional(),
    assignedToName: z.string().nullable().optional(),
    title: z.string().optional().nullable(),
    status: RequestStatus.optional(),
    archived: z.boolean().optional(),
});

const ProposalMessageData = z.object({
    message: FirebaseId,
    bookings: z.array(FirebaseId),
    sentAt: FirebaseTimestamp,
});

const Proposal = z.object({
    messages: z.array(ProposalMessageData),
});

export type ProposalMessageData = z.infer<typeof ProposalMessageData>;

// Think about body naming convention still
export const RequestMeta = z.object({
    createdAt: FirebaseTimestamp,
    updatedAt: FirebaseTimestamp.nullable().optional(),
    openedAt: FirebaseTimestamp.nullable().optional(),
    confirmedAt: FirebaseTimestamp.nullable().optional(),
    closedAt: FirebaseTimestamp.nullable().optional(),

    proposalBookings: z.array(FirebaseId).nullable().optional(),
    proposal: Proposal.nullable().optional(),
    ticketNumber: z.number(),
});

const RequestUpdatablesBase = z.object({
    title: z.string().optional().nullable(),
    offeringCategory: OfferingCategory.nullable().optional(),
    location: z.string().nullable().optional(),
    memberSatisfaction: MemberSatisfaction.nullable().optional(),
    notes: z.string().nullable().optional(),
    numberOfGuests: z.number().nullable().optional(),
    outcome: RequestOutcome.nullable().optional(),
    bookingOutcome: RequestBookingOutcome.nullable().optional(),
    source: RequestSource.nullable().optional(),
    budget: z.string().nullable().optional(),

    startDate: LocalDate.nullable().optional(),
    startTime: LocalTime.nullable().optional(),
    endDate: LocalDate.nullable().optional(),
    endTime: LocalTime.nullable().optional(),

    nextDeadlineStep: DeadlineStep.nullable().optional(),
    nextDeadlineAt: FirebaseTimestamp.nullable().optional(),
    deadlineCreatedAt: FirebaseTimestamp.nullable().optional(),
});
type RequestUpdatablesBase = z.infer<typeof RequestUpdatablesBase>;

export const RequestHighlightedFields = z.array(
    RequestUpdatablesBase.pick({
        offeringCategory: true,
        location: true,
        numberOfGuests: true,
        startDate: true,
        startTime: true,
        endDate: true,
        endTime: true,
        budget: true,
    })
        .merge(RequestRequiredUpdatables.pick({ title: true }))
        .keyof(),
);
export type RequestHighlightedFields = z.infer<typeof RequestHighlightedFields>;

export const RequestGooglePlaceLocation = z.object({
    locationGooglePlaceId: z.string().optional().nullable(),
    locationGoogleName: z.string().optional().nullable(),
    locationGoogleFormattedAddress: z.string().optional().nullable(),
    locationGoogleAddressComponents: AddressComponents.optional().nullable(),
    locationGoogleGeometryLat: z.number().optional().nullable(),
    locationGoogleGeometryLng: z.number().optional().nullable(),
});
export type RequestGooglePlaceLocation = z.infer<
    typeof RequestGooglePlaceLocation
>;

export const RequestUpdatables = RequestUpdatablesBase.merge(
    RequestGooglePlaceLocation,
).merge(
    z.object({
        highlightedFields: RequestHighlightedFields.optional().nullable(),
        isAssistedByAI: z.boolean().optional().nullable(),
        isCreatedByAI: z.boolean().optional().nullable(),
    }),
);

export const RequestFeedback = z.object({
    memberRating: Rating,
    memberFeedback: MediumLongString.optional().nullable(),
    memberFeedbackTimestamp: FirebaseTimestamp.optional().nullable(),
});
export type RequestFeedback = z.infer<typeof RequestFeedback>;

export type RequestRequired = z.infer<typeof RequestRequired>;
export type RequestRequiredUpdatables = z.infer<
    typeof RequestRequiredUpdatables
>;
export type RequestMeta = z.infer<typeof RequestMeta>;
export type RequestUpdatables = z.infer<typeof RequestUpdatables>;

export const RequestDoc = RequestRequiredUpdatables.merge(RequestRequired)
    .merge(RequestMeta)
    .merge(RequestUpdatables)
    .merge(RequestFeedback);
export type RequestDoc = z.infer<typeof RequestDoc>;

const RequestRequireArchived = z.object({ archived: z.literal(false) });

export const RequestPayload = RequestRequired.merge(
    RequestRequiredUpdatables,
).merge(RequestRequireArchived);
export type RequestPayload = z.infer<typeof RequestPayload>;

export const RequestPayloadWithUpdatables = RequestRequiredUpdatables.merge(
    RequestRequired,
)
    .merge(RequestUpdatables)
    .merge(RequestRequireArchived);
export type RequestPayloadWithUpdatables = z.infer<
    typeof RequestPayloadWithUpdatables
>;

export const RequestAgentUpdatables =
    RequestRequiredUpdatables.merge(RequestUpdatables);
export type RequestAgentUpdatables = z.infer<typeof RequestAgentUpdatables>;

export const Request = z.object({ id: FirebaseId }).merge(RequestDoc);
export type Request = z.infer<typeof Request>;

export const defaultRequestTitle = "New request...";

export function isRequestDoc(
    possibleRequest: any,
): possibleRequest is RequestDoc {
    return RequestDoc.safeParse(possibleRequest).success;
}

const CreateRequestResponse = z.discriminatedUnion("success", [
    z.object({ success: z.literal(true), requestId: FirebaseId }),
    z.object({ success: z.literal(false), error: z.string() }),
]);

export type CreateRequestResponse = z.infer<typeof CreateRequestResponse>;

export const MemberRequestDoc = RequestDoc.pick({
    assignedTo: true,
    assignedToName: true,
    title: true,
    offeringCategory: true,
    createdAt: true,
    openedAt: true,
    location: true,
    startDate: true,
    startTime: true,
    endDate: true,
    endTime: true,
    updatedAt: true,
    nextDeadlineAt: true,
    nextDeadlineStep: true,
    deadlineCreatedAt: true,
    numberOfGuests: true,
    status: true,
    createdFromMessage: true,
    closedAt: true,
    memberFeedbackTimestamp: true,
    memberRating: true,
    memberFeedback: true,
    proposalBookings: true,
    proposal: true,
});
export type MemberRequestDoc = z.infer<typeof MemberRequestDoc>;

export const MemberRequest = z
    .object({ id: FirebaseId })
    .merge(MemberRequestDoc);
export type MemberRequest = z.infer<typeof MemberRequest>;

export type ProposalUpdate = Pick<RequestMeta, "proposal"> &
    Pick<RequestMeta, "proposalBookings">;

export const ProposalGetRequest = z.object({
    request: FirebaseId,
});
export type ProposalGetRequest = z.infer<typeof ProposalGetRequest>;

export type ProposalGetResponse = PublicProposal;

export const MemberGetProposalPayload = z.object({
    request: FirebaseId,
});

export type MemberGetProposalPayload = z.infer<typeof MemberGetProposalPayload>;

export const MemberGetProposalResponse = z.object({
    request: MemberRequest,
    bookings: z.array(MemberBooking),
});

export type MemberGetProposalResponse = z.infer<
    typeof MemberGetProposalResponse
>;
