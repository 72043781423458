import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import React from "react";
import { useScreenStack } from "../context/screenStack";
import "./BookingEmptyState.css";

function BookingEmptyState() {
    const t = useAlbertineTranslation();
    const { openChat } = useScreenStack();
    const { dependencyInjection } = useTenant();

    const handleNavigate = () => {
        openChat("all");
    };

    return (
        <Lib.Background.BookingBg02 className="booking_empty_state">
            <Lib.Flex.Column alignItems="center" gap="value24">
                {dependencyInjection?.bookings.emptyState.ServiceEmblem()}

                <Lib.Flex.Column gap="value16" alignItems="center">
                    <Lib.Label.Medium.Bold transform="uppercase">
                        {t("bookings__empty_state_title")}
                    </Lib.Label.Medium.Bold>
                    <Lib.Paragraph.Small.Reqular.TextColor03 className="booking_empty_state__description">
                        {t("bookings__empty_state_description")}
                    </Lib.Paragraph.Small.Reqular.TextColor03>
                </Lib.Flex.Column>

                {dependencyInjection?.bookings.emptyState.CtaButton({
                    onClick: handleNavigate,
                })}
            </Lib.Flex.Column>
        </Lib.Background.BookingBg02>
    );
}

export default BookingEmptyState;
