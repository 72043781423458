import React from "react";
import { Lib } from "albertine-shared-web";
import classNames from "classnames";
import { z } from "zod";
import "./OnboardingProgress.css";

const ProgressSchema = z
    .number()
    .min(0)
    .max(100)
    .refine((value: number) => value % 5 === 0, {
        message: "Progress must be divisible by 5",
    });

type ProgressProps = z.infer<typeof ProgressSchema>;

interface Props {
    progress: ProgressProps;
}

function OnboardingProgress(props: Props) {
    const { progress } = props;
    function getProgressClass(value: number) {
        return classNames("onboarding-progress", {
            [`onboarding-progress-${value}`]:
                value % 5 === 0 && value >= 0 && value <= 100,
        });
    }

    return <Lib.Line.Dashed className={getProgressClass(progress)} />;
}

export default OnboardingProgress;
