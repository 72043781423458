import { Lib } from "albertine-shared-web";
import React, { useEffect, useState } from "react";
import { getStaticThemeArticles } from "../../../lmt/src/common/utils/opensearch/themes";
import { DynamicTheme } from "../../../lmt/src/common/types/DynamicTheme";
import { OpenSearchCatalogHitSource } from "../../../lmt/src/common/types/OpenSearch.CatalogItem";
import { useOpenSearch } from "../context/opensearch";
import AlbertineSlider from "./AlbertineSlider";
import MemberTopThemesItem from "./MemberTopThemesItem";
import "./MemberTopThemes.css";
import { useScreenStack } from "../context/screenStack";

interface MemberTopThemesProps {
    index: number;
    name: string;
    theme: DynamicTheme;
}
function MemberTopThemes(props: MemberTopThemesProps) {
    const { theme, name: themeTitle, index } = props;
    const [articles, setArticles] = useState<OpenSearchCatalogHitSource[]>();
    const { opensearch } = useOpenSearch();
    const { openArticle } = useScreenStack();
    useEffect(() => {
        async function fetchArticles() {
            if (opensearch) {
                const themeArticles = await getStaticThemeArticles(
                    opensearch,
                    theme,
                );
                setArticles(themeArticles);
            }
        }
        fetchArticles();
    }, [theme, opensearch]);

    return (
        <>
            <Lib.Flex.Row className="member-top-themes__title">
                <Lib.Heading.H4.Bold>{themeTitle}</Lib.Heading.H4.Bold>
            </Lib.Flex.Row>
            <Lib.Flex.Row className="member-top-themes__items">
                <AlbertineSlider slidesToShow={3.5} hasTextBelow>
                    {articles?.map((article: OpenSearchCatalogHitSource) => {
                        const { id, name, keyDetail, images } = article;
                        return (
                            <MemberTopThemesItem
                                index={index}
                                key={article.id}
                                name={name}
                                keyDetail={keyDetail}
                                background={images ? images[0] : ""}
                                onClick={() => openArticle(id)}
                            />
                        );
                    })}
                </AlbertineSlider>
            </Lib.Flex.Row>
        </>
    );
}
export default MemberTopThemes;
