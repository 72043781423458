import React from "react";
import classNames from "classnames";
import { Lib, useTenant } from "albertine-shared-web";
import CachedAvatar from "./CachedAvatar";
import {
    agentAvatarThumbnailRef,
    memberAvatarThumbnailRef,
} from "../api/firestore";
import "./Avatar.css";

export type AvatarType = "agent" | "member" | "service-agent";
type AvatarSize = "xlarge" | "large" | "medium" | "small";

const sizeToLabel: { [key in AvatarSize]: any } = {
    xlarge: Lib.Label.Large.MediumBold.TextColor01,
    large: Lib.Label.Large.MediumBold.TextColor01,
    medium: Lib.Label.Medium.MediumBold.TextColor01,
    small: Lib.Label.Small.MediumBold.TextColor01,
};

function BaseAvatar(avatarType: AvatarType, size: AvatarSize) {
    const Label = sizeToLabel[size];

    return function AlbertineAvatar(props: {
        id: string | undefined;
        fullName?: string;
    }) {
        const { id, fullName } = props;
        const initials = fullName
            ? Lib.Utils.TextFormatter.member.nameInitials(fullName)
            : "";
        const { dependencyInjection } = useTenant();

        if (avatarType === "agent" && id) {
            return (
                <div
                    className={classNames(
                        "avatar",
                        "avatar--agent",
                        `avatar--${size}`,
                        {
                            "text-color-inversable":
                                dependencyInjection?.avatar.inverseInitials,
                        },
                    )}
                >
                    <CachedAvatar
                        storageRef={agentAvatarThumbnailRef(id)}
                        emptyString={<Label>{initials}</Label>}
                    />
                </div>
            );
        }

        if (avatarType === "member" && id) {
            return (
                <div
                    className={classNames(
                        "avatar",
                        "avatar--member",
                        `avatar--${size}`,
                        {
                            "text-color-inversable":
                                dependencyInjection?.avatar.inverseInitials,
                        },
                    )}
                >
                    <CachedAvatar
                        storageRef={memberAvatarThumbnailRef(id)}
                        emptyString={<Label>{initials}</Label>}
                    />
                </div>
            );
        }

        return (
            <div
                className={classNames(
                    "avatar",
                    "avatar--service-agent",
                    `avatar--${size}`,
                )}
            >
                {dependencyInjection?.avatar.serviceAgentAvatarEmblem()}
            </div>
        );
    };
}

const Sizes = (avatarType: AvatarType) =>
    Object.assign(BaseAvatar(avatarType, "medium"), {
        XLarge: BaseAvatar(avatarType, "xlarge"),
        Large: BaseAvatar(avatarType, "large"),
        Medium: BaseAvatar(avatarType, "medium"),
        Small: BaseAvatar(avatarType, "small"),
    });

const Avatar = Object.assign(Sizes("agent"), {
    Agent: Sizes("agent"),
    Member: Sizes("member"),
    ServiceAgent: Sizes("service-agent"),
});

export default Avatar;
