import { isValidPhoneNumber } from "libphonenumber-js";
import * as z from "zod";
import { ArchiveReason } from "./types/ArchiveReason";

export const NonNegativeNumber = z.number().min(0);
export type NonNegativeNumber = z.infer<typeof NonNegativeNumber>;
export const isNonNegativeNumber = (x: any) =>
    NonNegativeNumber.safeParse(x).success;

export const Email = z
    .string()
    .email({ message: "invalid email address" })
    .refine((e) => e.toLowerCase() === e, "invalid email address");

export type Email = z.infer<typeof Email>;

export const FirebaseId = z.string().regex(/^[A-Za-z0-9]{20}$/, "Invalid id.");
export type FirebaseId = z.infer<typeof FirebaseId>;

export const IsoDateTimeString = z.string().datetime({ offset: true });
export type IsoDateTimeString = z.infer<typeof IsoDateTimeString>;

export const AlbertineServiceAgentSenderId = "AlbertineServiceAgent";
export const AlbertineServiceAgentSenderName = "Albertine";
export const AlbertineServiceAgentId = z.literal(AlbertineServiceAgentSenderId);

export const TrimmedString = z
    .string()
    .transform((s) => s.trim().replaceAll(/\s\s+/g, " "));

export const MemberId = z
    .string()
    .regex(/^[A-Fa-f0-9]{64}$/, "Invalid member id.");
export type MemberId = z.infer<typeof MemberId>;

export const Name = TrimmedString.refine(
    (s) => /^[\u00c0-\u01ffa-zA-Z'-\s]+$/.test(s),
    {
        message: "invalid name",
    },
).refine((s) => s.length > 0, "Name cannot be empty.");

export const PhoneNumber = z
    .string()
    .refine((t) => isValidPhoneNumber(t), { message: "invalid phone number" });

export const Url = z.string().url({ message: "invalid url" });

export const AgentId = z
    .string()
    .regex(/^[A-Za-z0-9]{28}$/, "Invalid agent id.");
export type AgentId = z.infer<typeof AgentId>;

export const VeryShortString = z.string().max(20);
export const ShortString = z.string().max(100);
export const MediumLengthString = z.string().max(200);
export const MediumLongString = z.string().max(1024);
export const LongString = z.string().max(10000);
export const GeoPoint = z.object({
    latitude: z.number().optional(),
    longitude: z.number().optional(),
});

export type GeoPoint = z.infer<typeof GeoPoint>;

export const Link = z.object({
    id: z.string(),
    url: z.union([z.literal(""), z.string().url()]),
    title: ShortString,
});

export type Link = z.infer<typeof Link>;

export const Contributions = z.object({
    trees: z.number().int().nonnegative(),
    kilograms: z.number().int().nonnegative(),
    squareMeters: z.number().int().nonnegative(),
});

export type Contributions = z.infer<typeof Contributions>;

export const MemberPhoneUpdate = z.object({
    uid: MemberId,
    phoneNumber: PhoneNumber,
});

export type MemberPhoneUpdate = z.infer<typeof MemberPhoneUpdate>;

export const MemberGroupsUpdate = z.object({
    uid: MemberId,
    groups: z.array(z.string()),
});

export type MemberGroupsUpdate = z.infer<typeof MemberGroupsUpdate>;

export const ArchiveMemberRequest = z.object({
    member: MemberId,
    archiveReason: ArchiveReason,
});

export type ArchiveMemberRequest = z.infer<typeof ArchiveMemberRequest>;

export const ActivateMemberRequest = z.object({
    member: MemberId,
});

export type ActivateMemberRequest = z.infer<typeof ActivateMemberRequest>;

/* YYYY-MM-dd */
export const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

/* HH:mm, 24 hour clock */
export const twentyFourHourTimeRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/;

/* yyyy-MM-dd'T'HH:mm:ss.SSS'Z' */
const isoDateRegexp =
    /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const DateString = z
    .string()
    .regex(dateRegex, "Invalid YYYY-MM-dd date string");

export const ISODateString = z
    .string()
    .regex(isoDateRegexp, "Invalid ISO date");

export const NumericStringOrNumber = z.coerce.string();

export const ChangeMessageRequestRequest = z.object({
    conversationId: FirebaseId,
    messageId: FirebaseId.optional().nullable(),
    noteId: FirebaseId.optional().nullable(),
    request: FirebaseId.optional().nullable(),
});
export type ChangeMessageRequestRequest = z.infer<
    typeof ChangeMessageRequestRequest
>;

export const Rating = z.number().int().min(1).max(5).optional().nullable();

const Crop = z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
    unit: z.enum(["px", "%"]),
});

export const PixelCrop = Crop.omit({ unit: true }).and(
    z.object({ unit: z.enum(["px"]) }),
);
export const PercentCrop = Crop.omit({ unit: true }).and(
    z.object({ unit: z.enum(["%"]) }),
);
export type PixelCrop = z.infer<typeof PixelCrop>;
export type PercentCrop = z.infer<typeof PercentCrop>;

export const BooleanOrBooleanString = z
    .boolean()
    .or(z.string().transform((v) => v === "true"));

export type BooleanOrBooleanString = z.infer<typeof BooleanOrBooleanString>;
