import React from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import Screen from "./Screen";
import Chat from "./Chat";
import ListenToMember from "../loaders/ListenToMember";
import "./ConversationThread.css";
import { ListenToRequestOptional } from "../loaders/ListenToRequest";
import { useScreenStack } from "../context/screenStack";
import ListenToConversation from "../loaders/ListenToConversation";

type ConversationThreadProps = {
    currentMemberId: string;
    thread: string | undefined;
};

function ConversationHeader(props: {
    allMessagesTitle: string;
    requestTitle: string | undefined;
}) {
    const { allMessagesTitle, requestTitle } = props;
    const { closeStackScreen } = useScreenStack();

    return (
        <Lib.Flex.Row
            justifyContent="center"
            className="conversation-thread__header"
        >
            <Lib.Button.Ghost onClick={closeStackScreen}>
                <Lib.Icon.ArrowBack.Medium className="conversation-thread__back-button" />
            </Lib.Button.Ghost>

            <div className="conversation-thread__header__title">
                <Lib.Heading.H5.Bold>
                    {requestTitle ?? allMessagesTitle}
                </Lib.Heading.H5.Bold>
            </div>
        </Lib.Flex.Row>
    );
}

function ConversationThread(props: ConversationThreadProps) {
    const { currentMemberId, thread } = props;
    const t = useAlbertineTranslation();

    const requestId = thread;

    return (
        <Screen.ConversationsBg02>
            <Lib.Flex.Column className="conversation-thread">
                <ListenToRequestOptional
                    requestId={requestId}
                    memberId={currentMemberId}
                >
                    {(request) => (
                        <>
                            <ConversationHeader
                                allMessagesTitle={t("chat__all_messages")}
                                requestTitle={
                                    request
                                        ? (request.title ??
                                          t("chat__new_request"))
                                        : undefined
                                }
                            />
                            <ListenToMember memberId={currentMemberId}>
                                {(member) => (
                                    <ListenToConversation
                                        conversationId={member.conversation}
                                    >
                                        {(conversation) => (
                                            <Chat
                                                currentMemberId={
                                                    currentMemberId
                                                }
                                                conversationId={
                                                    member.conversation
                                                }
                                                conversation={conversation}
                                                requestId={requestId}
                                                requestCreatedFromMessage={
                                                    request?.createdFromMessage ??
                                                    undefined
                                                }
                                            />
                                        )}
                                    </ListenToConversation>
                                )}
                            </ListenToMember>
                        </>
                    )}
                </ListenToRequestOptional>
            </Lib.Flex.Column>
        </Screen.ConversationsBg02>
    );
}

export default ConversationThread;
