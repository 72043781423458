import { Lib, useTenant } from "albertine-shared-web";
import { motion } from "framer-motion";
import React from "react";
import "./Permission.css";

interface PermissionProps {
    tagText: string;
    titleText: string;
    descriptionText: string;
    buttonText: string;
    onButton: () => void;
}

function Permission(props: PermissionProps) {
    const { tagText, titleText, descriptionText, buttonText, onButton } = props;
    const { tenant } = useTenant();

    const variants = {
        initial: { opacity: 0, y: -5 },
        animate: { opacity: 1, y: -20 },
        exit: { opacity: 0, y: -40 },
    };
    const componentStyle = {
        display: "flex",
        maxWidth: "514px",
        height: "auto",
        width: "100%",
        alignSelf: "center",
        justifyContent: "end",
    };
    const ButtonComponent =
        tenant === "hugoboss"
            ? Lib.Button.PrimaryBackground
            : Lib.Button.Primary;

    return (
        <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            style={componentStyle}
        >
            <Lib.Flex.Column className="permission__container">
                <Lib.Tag.Primary className="permission__tag">
                    {tagText}
                </Lib.Tag.Primary>
                <Lib.Paragraph.XXLarge.Bold className="permission__title">
                    {titleText}
                </Lib.Paragraph.XXLarge.Bold>
                <Lib.Paragraph.Medium.Reqular.TextColor02 className="permission__description">
                    {descriptionText}
                </Lib.Paragraph.Medium.Reqular.TextColor02>

                <ButtonComponent
                    disabled={false}
                    onClick={onButton}
                    label={buttonText}
                    className="permission__submit-button"
                />
            </Lib.Flex.Column>
        </motion.div>
    );
}

export default Permission;
