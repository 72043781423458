import { z } from "zod";
import { MediumLongString, NumericStringOrNumber } from "../validate";

const AssetResponse = z
    .object({
        filename: z.string(),
        id: z.number().nullable(),
    })
    .nullable();

export type AssetResponse = z.infer<typeof AssetResponse>;

export const FullCatalogCollectionResponse = z.object({
    uuid: z.string(),
    fullSlug: z.string(),
    publishedAt: z.string(),
    firstPublishedAt: z.string(),
    createdAt: z.string(),
    slug: z.string(),
    content: z.object({
        component: z.literal("collection"),
        title: z.string(),
        titleHtml: z.string(),
        date: z.string(),
        coverImage: AssetResponse,
        themes: z.array(z.string()),
    }),
});

export const FullCatalogThemeResponse = z.object({
    createdAt: z.string(),
    uuid: z.string(),
    slug: z.string(),
    fullSlug: z.string(),
    publishedAt: z.string(),
    firstPublishedAt: z.string(),
    tagList: z.array(z.string()),
    content: z.object({
        title: z.string(),
        titleHtml: z.string(),
        articles: z.array(z.string()),
        component: z.literal("theme"),
        coverImage: AssetResponse,
        description: z.string(),
    }),
});

export const ArticleContentPlace = z.object({
    lat: NumericStringOrNumber,
    lon: NumericStringOrNumber,
    uid: z.string().optional().nullable(),
    name: z.string(),
    address: z.string(),
    country: z.string().optional(),
    component: z.literal("googlePlace"),
    googlePlaceId: z.string(),
});

export const ArticleCallToActionEnum = z.enum([
    "request_more_info",
    "ask_lifestyle_manager",
    "place_request",
]);

export type ArticleCallToActionEnum = z.infer<typeof ArticleCallToActionEnum>;

export const ArticleCallToAction = z
    .string()
    .transform((value) => {
        if (value === "") {
            return "place_request";
        }
        return value;
    })
    .refine((value) => ArticleCallToActionEnum.options.includes(value as any), {
        message: "Invalid enum value",
    });

export type ArticleContentPlace = z.infer<typeof ArticleContentPlace>;

export const ArticleContent = z.object({
    catalogItemId: z.string().nullable().optional(),
    title: z.string(),
    content: z.string(),
    component: z.literal("article"),
    coverImage: AssetResponse,
    additionalImages: z.array(AssetResponse),
    place: z.array(ArticleContentPlace).optional().nullable(),
    insight: z.string().optional().nullable(),
    callToAction: ArticleCallToAction.optional().nullable(),
});

export type ArticleContent = z.infer<typeof ArticleContent>;

export const CatalogArticleItem = z.object({
    uuid: z.string(),
    fullSlug: z.string(),
    createdAt: z.string().optional(),
    tagList: z.array(z.string()),
    slug: z.string(),
    publishedAt: z.string().optional(),
    firstPublishedAt: z.string().optional(),
    content: ArticleContent,
});

export const FullCatalogResponse = z.object({
    articles: z.object({
        total: z.number(),
        items: z.array(CatalogArticleItem),
    }),

    collections: z.object({
        total: z.number(),
        items: z.array(FullCatalogCollectionResponse),
    }),

    themes: z.object({
        total: z.number(),
        items: z.array(FullCatalogThemeResponse),
    }),

    categories: z.object({
        total: z.number(),
        items: z.array(
            z.object({
                name: z.string(),
                title: z.string(),
                articles: z.array(z.string()),
            }),
        ),
    }),

    spotlight: z.object({
        title: z.string(),
        total: z.number(),
        items: z.array(
            z.object({
                component: z.literal("theme"),
                uuid: z.string(),
            }),
        ),
    }),

    articleSpotlight: z.object({
        title: z.string(),
        total: z.number(),
        items: z.array(
            z.object({
                component: z.literal("article"),
                uuid: z.string(),
            }),
        ),
    }),
});

export type FullCatalogResponse = z.infer<typeof FullCatalogResponse>;
export type FullCatalogCollectionResponse = z.infer<
    typeof FullCatalogCollectionResponse
>;
export type FullCatalogThemeResponse = z.infer<typeof FullCatalogThemeResponse>;
export type CatalogArticleItem = z.infer<typeof CatalogArticleItem>;

const ArticlePreviewPlace = z.object({
    lat: NumericStringOrNumber,
    lon: NumericStringOrNumber,
    uid: z.string().optional(),
    name: z.string(),
    address: z.string(),
    country: z.string().optional(),
    component: z.literal("googlePlace"),
    googlePlaceId: z.string(),
});

const ArticlePreview = z.object({
    _uid: z.string().optional(),
    coverImage: AssetResponse,
    additionalImages: z.array(AssetResponse),
    component: z.literal("article"),
    content: z.string(),
    title: z.string(),
    place: z.array(ArticlePreviewPlace),
    insight: z.string().optional(),
    callToAction: ArticleCallToAction.optional().nullable(),
    keyDetail: z.string().optional().nullable(),
    headline: z.string().optional().nullable(),
    website: z.string().url().optional().nullable(),
    tags: z.array(z.string()).optional().nullable(),
    benefits: z.string().optional().nullable(),
});
export type ArticlePreview = z.infer<typeof ArticlePreview>;
export type ArticlePreviewPlace = z.infer<typeof ArticlePreviewPlace>;

const CollectionPreview = z.object({
    title: z.string(),
    ingress: z.string(),
    themes: z.array(z.string()),
    coverImage: AssetResponse,
});
const ThemePreview = z.object({
    _uuid: z.string(),
    title: z.string(),
    titleHtml: z.string(),
    articles: z.array(z.string()),
    component: z.literal("theme"),
    coverImage: AssetResponse,
    description: z.string(),
});
export type PreviewRichText = {
    type: "text" | unknown;
    text: string;
    content: PreviewRichText[];
};
export type ThemePreview = z.infer<typeof ThemePreview>;
export type CollectionPreview = z.infer<typeof CollectionPreview>;

export const PhilanthropyBlok = z.object({
    title: z.string(),
    copy: z.string(),
    image: AssetResponse,
    missions: z.array(
        z.object({
            tag: z.string(),
            title: z.string(),
            copy: z.string(),
            image: AssetResponse,
        }),
    ),
});

export type PhilanthropyBlok = z.infer<typeof PhilanthropyBlok>;

const OnboardingContentFeedback = z.enum([
    "neutral",
    "like",
    "heart",
    "dislike",
]);

export type OnboardingContentFeedback = z.infer<
    typeof OnboardingContentFeedback
>;

const OnboardingContent = z.object({
    title: z.string(),
    description: MediumLongString,
    coverImageUrl: z.string(),
    feedback: OnboardingContentFeedback,
});

export const OnboardingBenefitContent = z
    .object({
        group: z.string(),
    })
    .merge(OnboardingContent);

export type OnboardingBenefitContent = z.infer<typeof OnboardingBenefitContent>;

export const OnboardingRecommendationContent = z
    .object({
        category: z.string(),
    })
    .merge(OnboardingContent);

export type OnboardingRecommendationContent = z.infer<
    typeof OnboardingRecommendationContent
>;

export const OnboardingTopCity = z.object({
    city: z.string(),
    country: z.string(),
    countryCode: z.string().optional(),
    googlePlaceId: z.string(),
    appLocale: z.string().optional().nullable(),
});

export type OnboardingTopCity = z.infer<typeof OnboardingTopCity>;
