import { z } from "zod";
import { OnboardingSteps } from "../types/OnboardingSteps";

const phoneNumberKey = "albertine-phone-number";
const phoneCodeKey = "albertine-phone-code";
const loginEmailKey = "albertine-login-email";
const deviceIdLocalStorageKey = "albertine-deviceId";
export const chatMessagesKey = "albertine-chat-messages";
const onboardingNextStep = "concierge-onboarding-next-step";

export function getLocalStorageData<T>(
    key: string,
    schema: z.ZodSchema<T>,
): T | undefined {
    const storedData = localStorage.getItem(key);
    if (!storedData) return undefined;
    const parsed = schema.safeParse(JSON.parse(storedData));
    if (!parsed.success) return undefined;
    return parsed.data;
}

export function setLoginPhoneNumber(phone: string) {
    localStorage.setItem(phoneNumberKey, phone);
}
export function setLoginPhoneCode(code: string) {
    localStorage.setItem(phoneCodeKey, code);
}
export function setLoginEmail(email: string) {
    localStorage.setItem(loginEmailKey, email);
}

export function getLoginPhoneNumber(): string | null {
    return localStorage.getItem(phoneNumberKey);
}
export function getLoginPhoneCode(): string | null {
    return localStorage.getItem(phoneCodeKey);
}
export function getLoginEmail(): string | null {
    return localStorage.getItem(loginEmailKey);
}

export function getDeviceId(): string {
    const deviceId = localStorage.getItem(deviceIdLocalStorageKey);
    if (deviceId !== null) {
        return deviceId;
    }

    const generatedDeviceId = crypto.randomUUID();
    localStorage.setItem(deviceIdLocalStorageKey, generatedDeviceId);
    return generatedDeviceId;
}

export function getOnboardingNextStep(): OnboardingSteps | null {
    const step = localStorage.getItem(onboardingNextStep);
    const result = OnboardingSteps.safeParse(step);
    return result.success ? result.data : null;
}
export function setOnboardingNextStep(nextStep: OnboardingSteps) {
    localStorage.setItem(onboardingNextStep, nextStep);
}
export function removeOnboardingNextStep() {
    localStorage.removeItem(onboardingNextStep);
}
