import { z } from "zod";
import { isString } from "../utils/string.util";
import { RequestStatus } from "./RequestStatus";
import {
    RequestBookingOutcome,
    RequestBookingOutcomeMap,
    RequestOutcome,
    RequestOutcomeMap,
} from "./RequestOutcome";
import { isTypeOf } from "../utils/zod.util";

export const DeadlineStep = z.enum([
    "revisit_request",
    "send_status_update",
    "send_status_update_internal",
    "send_proposal",
    "send_booking_confirmation",
    "wait_for_member_response",
    "wait_for_supplier_response",
    "check_on_pending_payment",
    "no_further_action",
]);

export type DeadlineStep = z.infer<typeof DeadlineStep>;

export const DeadlineStepList = DeadlineStep.options;

export const DeadlineStepToTextMap: {
    [key in DeadlineStep]: string;
} = {
    send_status_update: "Send status update",
    send_proposal: "Send proposal",
    send_booking_confirmation: "Send booking confirmation",
    no_further_action: "No further action",
    revisit_request: "Revisit request",
    send_status_update_internal: "Send status update",
    wait_for_member_response: "Wait for member response",
    wait_for_supplier_response: "Wait for supplier response",
    check_on_pending_payment: "Check on pending payment",
};

export type StatusDeadline =
    | `${RequestStatus}`
    | `${"open" | "confirmed"}__${DeadlineStep}`
    | `${"closed" | "confirmed"}__${RequestOutcome | RequestBookingOutcome}`;
export type RequestDeadlineStatus = RequestStatus | StatusDeadline;

export function isRequestDeadlineStatus(
    possible: any,
): possible is RequestDeadlineStatus {
    if (!isString(possible)) {
        return false;
    }
    const [status, deadline] = possible.split("__");

    const allowedStatus = isTypeOf(status, RequestStatus);
    const allowedStep =
        isTypeOf(deadline, DeadlineStep) ||
        isTypeOf(deadline, RequestOutcome) ||
        isTypeOf(deadline, RequestBookingOutcome);

    return deadline ? allowedStatus && allowedStep : allowedStatus;
}

export function isRequestDeadlineStatusArray(
    possible: any[],
): possible is RequestDeadlineStatus[] {
    return possible.filter(isRequestDeadlineStatus).length === possible.length;
}

export function StatusDeadlineToName(key: StatusDeadline): string {
    const step = key.split("__")[1];
    if (isTypeOf(step, RequestBookingOutcome))
        return RequestBookingOutcomeMap[step];
    if (isTypeOf(step, RequestOutcome)) return RequestOutcomeMap[step];
    if (isTypeOf(step, DeadlineStep)) return DeadlineStepToTextMap[step];
    return key;
}

export function splitStatusDeadline(key: StatusDeadline): {
    status: RequestStatus | undefined;
    deadlineStep: DeadlineStep | undefined;
    outcome: RequestOutcome | undefined;
    bookingOutcome: RequestBookingOutcome | undefined;
} {
    let status: RequestStatus | undefined;
    let deadlineStep: DeadlineStep | undefined;
    let outcome: RequestOutcome | undefined;
    let bookingOutcome: RequestBookingOutcome | undefined;

    const base = key.split("__")[0];
    const value = key.split("__")[1];
    if (isTypeOf(base, RequestStatus)) status = base;
    if (isTypeOf(value, DeadlineStep)) deadlineStep = value;
    if (isTypeOf(value, RequestOutcome)) outcome = value;
    if (isTypeOf(value, RequestBookingOutcome)) bookingOutcome = value;

    return {
        status,
        deadlineStep,
        outcome,
        bookingOutcome,
    };
}
