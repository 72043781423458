import React from "react";
import {
    Lib,
    Trans,
    useAlbertineTranslation,
    useTenant,
} from "albertine-shared-web";
import CountryCodes from "./CountryCodes";
import { countryCodes } from "../constants/countryCodes";
import NumberInput from "./NumberInput";
import EmailInput from "./EmailInput";
import { LoginStep } from "../types/LoginStep";
import "./LoginFormStep.css";

interface LoginFormStepProps {
    type: LoginStep;
    descriptionText: string;
    buttonText: string;
    buttonDisabled?: boolean;
    currentValue?: string;
    countryCode?: string;
    loading?: boolean;
    onButton: () => void;
    onTyping?: (value: string) => void;
    onSelect?: (value: string) => void;
    onEmailLink?: () => void;
}

function LoginFormStep(props: LoginFormStepProps) {
    const {
        countryCode,
        type,
        descriptionText,
        buttonText,
        buttonDisabled,
        currentValue = "",
        loading,
        onButton,
        onTyping,
        onSelect,
        onEmailLink,
    } = props;

    const { tenant, tenantConfig } = useTenant();
    const t = useAlbertineTranslation();
    const ButtonComponent =
        tenant === "hugoboss"
            ? Lib.Button.PrimaryBackground
            : Lib.Button.Primary;

    const supportEmailLink = `mailto:${tenantConfig.emailService}`;

    return (
        <Lib.Flex.Column gap="value8">
            <Lib.Flex.Column gap="value16">
                <Lib.Paragraph.Medium.Reqular.TextColor02>
                    {type !== "link" && descriptionText}

                    {type === "link" && (
                        <Trans
                            i18nKey="login_form__description_link"
                            components={[
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    key="supportEmail"
                                    href={supportEmailLink}
                                    className="login_form_step__email_in_text"
                                />,
                            ]}
                            values={{ linkText: tenantConfig.emailService }}
                        />
                    )}
                </Lib.Paragraph.Medium.Reqular.TextColor02>

                {type !== "link" && <Lib.Line.Dashed />}

                <Lib.Flex.Column gap="value8">
                    <Lib.Flex.Row className="login_form_step__form-fields-container">
                        {type === "phone" && (
                            <>
                                <CountryCodes
                                    value={countryCode}
                                    countryCodes={countryCodes}
                                    onChange={(event) =>
                                        onSelect?.(event.target.value)
                                    }
                                />
                                <NumberInput
                                    onChange={(event) =>
                                        onTyping?.(
                                            event.target.value.replace(
                                                /\s/g,
                                                "",
                                            ),
                                        )
                                    }
                                    placeholder="6 12 345 678"
                                    numberType="phone"
                                    autoFocus
                                    currentValue={currentValue}
                                />
                            </>
                        )}
                    </Lib.Flex.Row>
                    {type === "code" && (
                        <Lib.Flex.Column gap="value24">
                            <NumberInput
                                onChange={(event) =>
                                    onTyping?.(
                                        event.target.value.replace(/\s/g, ""),
                                    )
                                }
                                placeholder="0 0 0 0 0 0"
                                numberType="code"
                                autoFocus
                                currentValue={currentValue}
                                loading={loading}
                            />

                            <Lib.Button.Ghost onClick={onEmailLink}>
                                <Lib.Label.Medium.MediumBold.TextColor01>
                                    {t("login_form__email_login_link_text")}
                                </Lib.Label.Medium.MediumBold.TextColor01>
                            </Lib.Button.Ghost>
                        </Lib.Flex.Column>
                    )}
                    {type === "email" && (
                        <EmailInput
                            onChange={(event) => onTyping?.(event.target.value)}
                            placeholder="your@email.com"
                            loading={loading}
                            defaultValue={currentValue}
                        />
                    )}
                </Lib.Flex.Column>
            </Lib.Flex.Column>

            <Lib.Flex.Column gap="value16" alignItems="center">
                {type !== "code" && (
                    <ButtonComponent
                        disabled={buttonDisabled}
                        onClick={onButton}
                        label={buttonText}
                        className="login_form_step__submit-button"
                    />
                )}
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default LoginFormStep;
