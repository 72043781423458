import { z } from "zod";
import { placeTypesToOfferingCategories } from "../../../types/GooglePlaces";
import {
    AddressComponents,
    CatalogItem,
    CatalogItemEditables,
    NewCatalogItemPayload,
    safelyConvertLocationToGeoPoint,
} from "../../CatalogItem";
import {
    GooglePlace,
    GooglePlaceAddressComponent,
    GooglePlaceFields,
    mapGooglePlaceDetailsToTags,
    mapGooglePlacePriceLevel,
} from "../../GooglePlace";
import { Tag } from "../../tags";
import { Booking, MemberBooking } from "../../types/Booking";
import { OfferingCategory } from "../../types/OfferingCategory";
import { encodeSearchQuery } from "../url.util";

export function googlePlaceURL(
    placeId: string,
    fields: GooglePlaceFields,
    apiKey: string,
): string {
    const fieldsToUrl = fields.join(",");
    const url = `https://places.googleapis.com/v1/places/${placeId}?fields=${fieldsToUrl}&key=${apiKey}&languageCode=en`;
    return url;
}

export const GooglePhoto = z.object({
    name: z.string(),
    photoUri: z.string(),
});

export type GooglePhoto = z.infer<typeof GooglePhoto>;

export interface GooglePhotoOption {
    maxHeightPx: number;
    maxWidthPx: number;
}

export const photoUrlOptions: GooglePhotoOption = {
    maxWidthPx: 1200,
    maxHeightPx: 1200,
};

export function googlePhotoURL(
    resourceName: string,
    options: GooglePhotoOption,
    apiKey: string,
): string {
    const parametersToUrl = encodeSearchQuery(options);
    const url = `https://places.googleapis.com/v1/${resourceName}/media?&key=${apiKey}&${parametersToUrl}&skipHttpRedirect=true`;
    return url;
}

export interface GooglePlaceService {
    fetchPlacePhoto: (
        resourceName: string,
        options: GooglePhotoOption,
    ) => Promise<GooglePhoto | undefined>;

    fetchGooglePlace: (
        placeId: string,
        fields: GooglePlaceFields,
    ) => Promise<GooglePlace | undefined>;
}

export function googlePlaceService(apiKey: string): GooglePlaceService {
    return {
        async fetchPlacePhoto(
            resourceName: string,
            options: GooglePhotoOption,
        ): Promise<GooglePhoto | undefined> {
            const url = googlePhotoURL(resourceName, options, apiKey);
            const response = await fetch(url);

            if (!response.ok) {
                return undefined;
            }

            const data = await response.json();
            return GooglePhoto.parse(data);
        },

        async fetchGooglePlace(
            placeId: string,
            fields: GooglePlaceFields,
        ): Promise<GooglePlace | undefined> {
            const url = googlePlaceURL(placeId, fields, apiKey);
            const response = await fetch(url);

            if (!response.ok) {
                return undefined;
            }

            const data = await response.json();
            return GooglePlace.parse(data);
        },
    };
}

export function googleAddressSearchUrl(address: string) {
    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
}

export function googlePlaceIdUrl(googlePlaceId: string) {
    return `https://www.google.com/maps/place/?q=place_id:${googlePlaceId}`;
}

function googleLatLongUrl(lat: number, lon: number) {
    return `https://maps.google.com/?ll=${lat},${lon}`;
}

export function googleViewInMapUrl(
    item:
        | MemberBooking
        | Booking
        | CatalogItem
        | CatalogItemEditables
        | undefined,
): string | undefined {
    if (!item) return undefined;
    if (item.googleUrl) return item.googleUrl;
    if (item.geoPoint && item.geoPoint.latitude && item.geoPoint.longitude)
        return googleLatLongUrl(
            item.geoPoint.latitude,
            item.geoPoint.longitude,
        );
    if (item.address && item.address.length !== 0)
        return googleAddressSearchUrl(item.address);
    return undefined;
}

const PlaceTypesToTags: { [key: string]: Tag[] } = {
    // https://developers.google.com/maps/documentation/places/web-service/place-types
    // Culture
    art_gallery: ["type:art-gallery", "type:art"],
    museum: ["type:museum"],
    performing_arts_theater: ["type:theatre"],
    // Entertainment and Recreation
    amusement_park: ["type:tourist-attraction"],
    aquarium: ["type:tourist-attraction"],
    night_club: ["type:nightclub"],
    tourist_attraction: ["type:tourist-attraction"],
    zoo: ["type:tourist-attraction"],
    // Food and Drink
    american_restaurant: ["cuisine:american", "type:restaurant"],
    bar: ["type:bar"],
    barbecue_restaurant: ["type:restaurant"],
    brazilian_restaurant: ["cuisine:latin", "type:restaurant"],
    breakfast_restaurant: ["meals:breakfast", "type:restaurant"],
    brunch_restaurant: ["meals:brunch", "type:restaurant"],
    cafe: ["type:cafe"],
    chinese_restaurant: ["cuisine:asian", "cuisine:chinese", "type:restaurant"],
    coffee_shop: ["type:cafe"],
    french_restaurant: ["cuisine:french", "type:restaurant"],
    greek_restaurant: ["cuisine:greek", "type:restaurant"],
    indian_restaurant: ["cuisine:indian", "cuisine:asian", "type:restaurant"],
    indonesia_restaurant: ["cuisine:asian", "type:restaurant"],
    italian_restaurant: ["cuisine:italian", "type:restaurant"],
    japanese_restaurant: [
        "cuisine:japanese",
        "cuisine:asian",
        "type:restaurant",
    ],
    korean_restaurant: ["cuisine:asian", "type:restaurant"],
    lebanese_restaurant: ["cuisine:middle-eastern", "type:restaurant"],
    mediterranean_restaurant: ["cuisine:mediterranean", "type:restaurant"],
    mexican_restaurant: ["cuisine:mexican", "type:restaurant"],
    middle_eastern_restaurant: ["cuisine:middle-eastern", "type:restaurant"],
    ramen_restaurant: ["cuisine:asian", "type:restaurant"],
    restaurant: ["type:restaurant"],
    seafood_restaurant: ["type:restaurant", "cuisine:seafood"],
    spanish_restaurant: ["cuisine:spanish", "type:restaurant"],
    steak_house: ["cuisine:steakhouse", "type:restaurant"],
    sushi_restaurant: ["cuisine:asian", "cuisine:japanese", "type:restaurant"],
    thai_restaurant: ["cuisine:asian", "type:restaurant"],
    turkish_restaurant: ["cuisine:middle-eastern", "type:restaurant"],
    vegan_restaurant: [
        "goodFor:vegans",
        "goodFor:vegetarians",
        "type:restaurant",
    ],
    vegetarian_restaurant: ["goodFor:vegetarians", "type:restaurant"],
    vietnamese_restaurant: ["cuisine:asian", "type:restaurant"],
    // Health and Wellness
    spa: ["type:spa"],
    // Lodging
    bed_and_breakfast: ["type:bed-and-breakfast"],
    hotel: ["type:hotel"],
    resort_hotel: ["type:resort", "type:hotel"],
    // Services
    beauty_salon: ["type:beauty-salon"],
    book_store: ["type:bookshop"],
};

function typesToTags(types: string[]): Tag[] {
    const tags = types
        .map((type): Tag[] => {
            const tag = PlaceTypesToTags[type];
            return tag || [];
        })
        .flat();
    return tags;
}

export function mapGooglePlaceToTags(place: GooglePlace): Tag[] {
    if (!place) return [];
    const priceTags = mapGooglePlacePriceLevel(place);
    const typeTags = typesToTags(place.types || []);
    const detailTags = mapGooglePlaceDetailsToTags(place);
    const tags = [...priceTags, ...typeTags, ...detailTags];
    return Array.from(new Set(tags)).sort();
}

export function newCatalogItemPayloadFromGooglePlace(
    place: GooglePlace,
): NewCatalogItemPayload {
    const {
        location,
        displayName,
        id: placeId,
        addressComponents,
        formattedAddress,
        internationalPhoneNumber,
        googleMapsUri,
        websiteUri: website,
        types,
    } = place;
    const geoPoint = safelyConvertLocationToGeoPoint(location);
    const offeringCategories: OfferingCategory[] =
        placeTypesToOfferingCategories(types || []);
    const tags = mapGooglePlaceToTags(place);

    const newItem: NewCatalogItemPayload = {
        googlePlaceId: placeId,
        name: displayName?.text || "",
        address: formattedAddress,
        addressComponents: addressComponents?.map((components) => ({
            longName: components.longText || "",
            shortName: components.shortText || "",
            types: components.types,
        })),
        website: website ? website.split("?")[0] : undefined,
        phoneNumber: internationalPhoneNumber,
        googleUrl: googleMapsUri,
        geoPoint,
        offeringCategories,
        tags,
        googlePlaceData: place,
    };
    return newItem;
}

export function googlePlaceToAddressComponents(
    place: GooglePlace,
): AddressComponents | undefined {
    return place.addressComponents?.map((components) => ({
        longName: components.longText || "",
        shortName: components.shortText || "",
        types: components.types,
    }));
}

export function toAddressComponents(
    components: GooglePlaceAddressComponent[] | undefined | null,
): AddressComponents {
    if (!components) return undefined;
    return components.map((component) => ({
        longName: component.longText,
        shortName: component.shortText,
        types: component.types,
    }));
}
