import { onAuthStateChanged, signOut, User } from "firebase/auth";
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { auth } from "../Firebase";

type AuthContextType = {
    user: User | undefined;
    loading: boolean;
    logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

function AuthProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [user, setUser] = useState<User | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    const logout = () => signOut(auth);

    useEffect(() => {
        onAuthStateChanged(auth, (authUser) => {
            setUser(authUser || undefined);
            setLoading(false);
        });
    }, []);

    const value = useMemo(
        () => ({
            user,
            loading,
            logout,
        }),
        [user, loading],
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
