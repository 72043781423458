import { isBefore } from "date-fns";

export function sortByNumberComparator<T>(
    getValue: (n: T | undefined | null) => number,
) {
    return (a: T | undefined | null, b: T | undefined | null) => {
        const valueA = a ? getValue(a) : 0;
        const valueB = b ? getValue(b) : 0;
        return valueA - valueB;
    };
}

export function sortByDateComparator<T>(
    getValue: (n: T | undefined) => Date | undefined,
    showUndefinedAsLastInDecendingOrder: boolean = false,
) {
    return (a: T | undefined, b: T | undefined) => {
        const valueA = getValue(a);
        const valueB = getValue(b);

        if (valueA && !valueB) {
            return showUndefinedAsLastInDecendingOrder ? 1 : -1;
        }
        if (!valueA && valueB) {
            return showUndefinedAsLastInDecendingOrder ? -1 : 1;
        }

        if (valueA && valueB) {
            return isBefore(valueA, valueB) ? -1 : 1;
        }

        return 0;
    };
}

export function sortByStringComparator<T>(
    getValue: (n: T | undefined) => string | undefined,
) {
    return (a: T | undefined, b: T | undefined) => {
        const valueA = getValue(a);
        const valueB = getValue(b);

        if (valueA && !valueB) {
            return -1;
        }
        if (!valueA && valueB) {
            return 1;
        }

        return (valueA || "") < (valueB || "") ? -1 : 1;
    };
}
