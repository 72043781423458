import { z } from "zod";
import { OpenSearchQuery } from "../../types/OpenSearch";
import {
    OpenSearchWebClient,
    callSearch,
    getDocumentById,
    indexDocumentCount,
} from "./client";
import { openSearchFetch } from "./fetch";

export type OpenSearchClientTokenType = "Bearer" | "Basic";

export function openSearchWebClient(
    baseUrl: string,
    tokenProvider: () => Promise<{
        tokenType: OpenSearchClientTokenType;
        token: string;
    }>,
): OpenSearchWebClient {
    function getClient() {
        return tokenProvider().then(({ tokenType, token }) =>
            openSearchFetch(baseUrl, `${tokenType} ${token}`),
        );
    }
    const search = <Query extends OpenSearchQuery>(
        index: string,
        query: Query,
    ) =>
        getClient().then((client) =>
            callSearch(index, query, client.callOpenSearch),
        );

    return {
        search,
        getDocumentById: <T extends z.ZodTypeAny>(
            indexName: string,
            id: string,
            schema: T,
        ): Promise<T> =>
            getClient().then((client) =>
                getDocumentById<T>(
                    indexName,
                    id,
                    schema,
                    client.callOpenSearch,
                ),
            ),
        indexDocumentCount: (indexName) =>
            getClient().then((client) =>
                indexDocumentCount(indexName, client.callOpenSearch),
            ),
    };
}
