import { useEffect, useState } from "react";
import { getOnlyOrLast } from "./hooks.util";
import { screenCssClass } from "../screens/Screen";

const useScrollIsOnTop = () => {
    const [isAtTop, setIsAtTop] = useState(true);

    useEffect(() => {
        const scrollableElement = getOnlyOrLast(
            document.getElementsByClassName(screenCssClass),
        );

        const handleScroll = () => {
            if (scrollableElement) {
                setIsAtTop(scrollableElement.scrollTop < 30);
            }
        };

        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return isAtTop;
};

export default useScrollIsOnTop;
