import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import React, { useEffect } from "react";
import "./Chat.css";
import { v4 as uuid } from "uuid";
import { useLocation } from "react-router";
import { MemberSendMessagePayload } from "../../../lmt/src/common/types/Message";
import { fromTimestampToDate } from "../utils/timestamp.util";
import ListenToConversationMessages from "../loaders/ListenToConversationMessages";
import {
    memberMarkConversationMessagesRead,
    memberSendMessage,
} from "../api/firestore";
import Avatar from "../components/Avatar";
import { ChatMessage, ChatMessageGroup } from "../utils/chatmessage.util";
import { useScreenStack } from "../context/screenStack";
import { Conversation } from "../../../lmt/src/common/types/Conversation";
import {
    quickRequestQuestionAnswerPairs,
    quickRequestSubtitle,
    quickRequestTag,
    quickRequestTitle,
} from "../../../lmt/src/common/utils/quickRequests.util";
import {
    removeLocalChatMessageBySendingId,
    updateLocalChatMessage,
    upsertLocalChatMessage,
} from "../utils/localStorage.messages.util";
import { useLocalChatMessages } from "../utils/localStorage.listeners.util";
import Loading from "../components/Loading";

type ChatProps = {
    currentMemberId: string;
    conversationId: string;
    conversation: Conversation;
    requestId: string | undefined;
    requestCreatedFromMessage: string | undefined;
};

function messageToMemberSendMessagePayload(
    message: string,
    request?: string,
): MemberSendMessagePayload {
    return {
        sendingId: uuid(),
        message: message.trim(),
        request,
    };
}

function ChatMessageComponent(props: {
    currentMemberId: string;
    displayTopic: boolean;
    chatMessage: ChatMessage;
}) {
    const { chatMessage, currentMemberId, displayTopic } = props;
    const { message, showSenderRow } = chatMessage;

    const t = useAlbertineTranslation();
    const { openChat, openBooking, openArticle, openProposal } =
        useScreenStack();

    const isSender = message.sentBy === currentMemberId;
    const messageHtml = message.messageHtml || undefined;
    const sentAt = fromTimestampToDate(message.sentAt);
    const topic =
        displayTopic && message.requestTitle
            ? {
                  title: message.requestTitle,
                  onClick: () => {
                      if (message.request) {
                          openChat(message.request);
                      }
                  },
              }
            : undefined;
    const addExtraSpacing = showSenderRow
        ? "chat__message__additional-spacing"
        : undefined;
    const attachments = message.attachments?.map((attachment) => ({
        filename: attachment.filename,
        mimeType: attachment.mimeType,
        url: attachment.url,
        onClick: () => {
            window.open(attachment.url, "_blank");
        },
    }));

    const hasBookingSuggestions =
        message.bookingSuggestions && message.bookingSuggestions.length > 0;
    const everyBookingSuggestionIsConfirmed =
        message.bookingSuggestions?.every(
            (booking) => booking.reservationStatus === "confirmed",
        ) || false;

    const confirmedBookingSuggestions =
        hasBookingSuggestions && everyBookingSuggestionIsConfirmed
            ? message.bookingSuggestions
            : undefined;
    const proposalBookingSuggestions =
        hasBookingSuggestions && !everyBookingSuggestionIsConfirmed
            ? message.bookingSuggestions
            : undefined;

    const confirmedBookings = [
        ...(message.confirmedBooking ? [message.confirmedBooking] : []),
        ...(confirmedBookingSuggestions || []),
    ].map((booking) => ({
        id: booking.id,
        title: booking.title,
        imageUrl: booking?.croppedImageURL || booking?.imageURL,
        onClick: () => {
            openBooking(booking.id);
        },
    }));

    const proposal =
        hasBookingSuggestions &&
        proposalBookingSuggestions &&
        proposalBookingSuggestions.length > 0
            ? {
                  id: message.request,
                  title: message.requestTitle,
                  imageUrls: proposalBookingSuggestions.map(
                      (booking) =>
                          booking?.croppedImageURL || booking?.imageURL,
                  ),
                  onClick: () => {
                      if (message.request) openProposal(message.request);
                  },
              }
            : undefined;

    const label = (() => {
        if (proposal) return t("chat__proposal-title");
        if (confirmedBookings && confirmedBookings.length > 0)
            return t("chat__confirmed-booking-title");
        if (message.quickRequest) return quickRequestTag(message.quickRequest);
        return undefined;
    })();

    const reply =
        message.replyToMessageId && message.replyToMessage
            ? {
                  id: message.replyToMessageId,
                  title: t("chat__reply_title"),
                  message: message.replyToMessage.message,
                  imageUrl:
                      message.confirmedBooking?.croppedImageURL ||
                      message.confirmedBooking?.imageURL ||
                      message.replyToMessage.attachments?.filter((attachment) =>
                          attachment.mimeType.startsWith("image/"),
                      )[0]?.url,
                  onClick: () => {
                      console.log("TODO");
                  },
              }
            : undefined;

    const articlesV1 = (message.articles || []).map((article) => ({
        id: article.uuid,
        title: article.content.title,
        imageUrl: article.content.coverImage?.filename,
        onClick: () => {}, // deprecated - won't be implemented
    }));

    const articlesV2 = (message.articlesV2 || []).map((article) => ({
        id: article.id,
        title: article.name,
        subtitle: article.keyDetail,
        imageUrl: article.image,
        onClick: () => {
            openArticle(article.id);
        },
    }));

    const quickRequest = message.quickRequest
        ? {
              title: quickRequestTitle(message.quickRequest),
              subtitle: quickRequestSubtitle(message.quickRequest),
              questionAnswerPairs: quickRequestQuestionAnswerPairs(
                  message.quickRequest,
              ),
          }
        : undefined;

    const articles = [...articlesV1, ...articlesV2];

    if (isSender) {
        return (
            <Lib.ChatMessage.Member
                id={message.id}
                key={message.id}
                className={addExtraSpacing}
                message={message.message}
                messageHtml={messageHtml}
                sentAt={sentAt}
                topic={topic}
                label={label}
                showSenderRow={showSenderRow}
                attachments={attachments}
                confirmedBookings={confirmedBookings}
                proposal={proposal}
                reply={reply}
                articles={articles}
                quickRequest={quickRequest}
            />
        );
    }
    return (
        <Lib.ChatMessage.Agent
            id={message.id}
            key={message.id}
            avatar={
                <Avatar.Agent.Small
                    id={message.sentBy}
                    fullName={message.senderName}
                />
            }
            className={addExtraSpacing}
            message={message.message}
            messageHtml={messageHtml}
            senderName={message.senderName}
            sentAt={sentAt}
            topic={topic}
            label={label}
            showSenderRow={showSenderRow}
            attachments={attachments}
            confirmedBookings={confirmedBookings}
            proposal={proposal}
            reply={reply}
            articles={articles}
        />
    );
}

function Chat(props: ChatProps) {
    const {
        currentMemberId,
        conversationId,
        conversation,
        requestId,
        requestCreatedFromMessage,
    } = props;

    const t = useAlbertineTranslation();
    const location = useLocation();
    const localChatMessages = useLocalChatMessages()?.filter(
        (it) => it.message.request === requestId,
    );

    const isActive = location.state?.focus;

    const displayTopic = !requestId;

    const sendMessage = async (messagePayload: MemberSendMessagePayload) => {
        upsertLocalChatMessage({
            message: messagePayload,
            sendingStatus: "sending",
        });
        try {
            await memberSendMessage(messagePayload);
            removeLocalChatMessageBySendingId(messagePayload.sendingId);
        } catch (e) {
            // TODO: Log error to backend
            updateLocalChatMessage({
                message: messagePayload,
                sendingStatus: "failed",
            });
        }
    };

    useEffect(() => {
        const unreadMessages =
            conversation.unreadMessagesByMember?.filter((it) =>
                requestId ? it.request === requestId : !it.request,
            ) || [];
        if (unreadMessages.length > 0) {
            memberMarkConversationMessagesRead({
                conversationId,
                requestId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Lib.Flex.Column className="chat" key={conversationId + requestId}>
            <Lib.ChatMessage.Wrapper>
                {localChatMessages && localChatMessages.length > 0 && (
                    <div className="chat__group__messages chat__group__local-messages">
                        {localChatMessages
                            ?.reverse()
                            .map((localMessage) => ({
                                localMessage,
                                messageComponent: (
                                    <Lib.ChatMessage.Member
                                        id={localMessage.message.sendingId}
                                        key={localMessage.message.sendingId}
                                        message={localMessage.message.message}
                                        sendingStatus={
                                            localMessage.sendingStatus
                                        }
                                        sentAt={new Date()}
                                    />
                                ),
                            }))
                            .map((tuple) =>
                                tuple.localMessage.sendingStatus ===
                                "failed" ? (
                                    <Lib.Button.Ghost
                                        key={
                                            tuple.localMessage.message.sendingId
                                        }
                                        onClick={() => {
                                            sendMessage(
                                                tuple.localMessage.message,
                                            );
                                        }}
                                    >
                                        {tuple.messageComponent}
                                    </Lib.Button.Ghost>
                                ) : (
                                    tuple.messageComponent
                                ),
                            )}
                    </div>
                )}
                <ListenToConversationMessages
                    currentMemberId={currentMemberId}
                    conversationId={conversationId}
                    requestId={requestId}
                    requestCreatedFromMessage={requestCreatedFromMessage}
                    isLoading={
                        <Lib.Flex.Row className="chat__loading-spinner">
                            <Loading />
                        </Lib.Flex.Row>
                    }
                    onMessagesLoaded={(messages) => {
                        const sendingIds = messages
                            .map((it) => it.sendingId)
                            .filter(Boolean) as string[];
                        removeLocalChatMessageBySendingId(sendingIds);
                    }}
                >
                    {(chatMessageGroups: ChatMessageGroup[]) => (
                        <div className="chat__group">
                            {chatMessageGroups.map((chatMessageGroup) => (
                                <Lib.Flex.Column
                                    key={chatMessageGroup.date.toISOString()}
                                >
                                    <Lib.ChatMessagePill
                                        text={Lib.Utils.TextFormatter.chat.datePill(
                                            chatMessageGroup.date,
                                        )}
                                    />

                                    <div className="chat__group__messages">
                                        {chatMessageGroup.messages.map(
                                            (chatMessage) => (
                                                <ChatMessageComponent
                                                    key={chatMessage.message.id}
                                                    currentMemberId={
                                                        currentMemberId
                                                    }
                                                    displayTopic={displayTopic}
                                                    chatMessage={chatMessage}
                                                />
                                            ),
                                        )}
                                    </div>
                                </Lib.Flex.Column>
                            ))}
                        </div>
                    )}
                </ListenToConversationMessages>
            </Lib.ChatMessage.Wrapper>
            <Lib.ChatInput
                placeholder={t("chat_input__placeholder")}
                onSend={async (message: string) => {
                    const payload = messageToMemberSendMessagePayload(
                        message,
                        requestId,
                    );
                    await sendMessage(payload);
                    return true;
                }}
                isActive={isActive}
            />
        </Lib.Flex.Column>
    );
}

export default Chat;
