import { z } from "zod";

export const GooglePlaceDefaultFields: Partial<
    keyof google.maps.places.PlaceResult
>[] = [
    "place_id",
    "geometry",
    "name",
    "address_components",
    "formatted_address",
    "international_phone_number",
    "website",
    "opening_hours",
    "types",
    "url",
    "business_status",
];

export const GooglePlaceTimeSensitiveFields: Partial<
    keyof google.maps.places.PlaceResult
>[] = [
    "photos",
    "reviews",
    "rating",
    "user_ratings_total",
    "utc_offset_minutes", // Needed for isOpen function to work
];

export function googlePlaceURL(
    placeId: string,
    fields: Partial<keyof google.maps.places.PlaceResult>[],
    apiKey: string,
) {
    const fieldsToUrl = fields.join(",");
    const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=${fieldsToUrl}&language=en&inputtype=textquery&key=${apiKey}`;
    return url;
}

export function googlePlaceResultsToAddressComponents(
    place: google.maps.places.PlaceResult,
): GooglePlaceAddressComponents {
    return place.address_components?.map((components) => ({
        longName: components.long_name,
        shortName: components.short_name,
        types: components.types,
    }));
}

const GooglePlaceIdUpdateResponse = z.object({
    result: z.object({ place_id: z.string().optional() }).optional(),
    status: z.string(),
});
type GooglePlaceIdUpdateResponse = z.infer<typeof GooglePlaceIdUpdateResponse>;

export async function getCurrentGooglePlaceId(
    placeId: string,
    apiKey: string,
): Promise<GooglePlaceIdUpdateResponse> {
    const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=place_id&key=${apiKey}`;
    const response = await fetch(url);
    const json = await response.json();
    return GooglePlaceIdUpdateResponse.parse(json);
}

const GoogleAddressComponent = z.object({
    longName: z.string(),
    shortName: z.string(),
    types: z.array(z.string()),
});
export type GoogleAddressComponent = z.infer<typeof GoogleAddressComponent>;

export const GooglePlaceAddressComponents = z
    .array(GoogleAddressComponent)
    .optional()
    .nullable();

export type GooglePlaceAddressComponents = z.infer<
    typeof GooglePlaceAddressComponents
>;

export function findComponent(
    components: GooglePlaceAddressComponents,
    type: string,
): GoogleAddressComponent | undefined {
    return components
        ? components.find((component) => component.types.includes(type))
        : undefined;
}
