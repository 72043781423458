/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Lib } from "albertine-shared-web";
import { PublicBooking } from "albertine-shared-web/types/PublicBooking";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import { useScreenStack } from "../context/screenStack";
import "./Booking.css";

type BookingProps = {
    publicBooking: PublicBooking;
    memberBooking: MemberBooking;
};

function Booking(props: BookingProps) {
    const { publicBooking, memberBooking } = props;
    const { openChat } = useScreenStack();

    return (
        <div id={publicBooking.id} className="booking-screen">
            <Lib.Booking.Header {...publicBooking} />

            <Lib.Background.BookingBg04 className="booking-screen__content">
                <Lib.Booking.Images {...publicBooking} />

                <Lib.Booking.AddressDetails {...publicBooking} />

                {publicBooking.template === "accommodation" && (
                    <Lib.Booking.AccommodationDetails {...publicBooking} />
                )}

                <Lib.Booking.Body
                    {...publicBooking}
                    bookingReference={memberBooking.reference}
                />
            </Lib.Background.BookingBg04>
            <Lib.CallToAction openChat={() => openChat("all")} />
        </div>
    );
}

export default Booking;
