import React from "react";
import { OnboardingSteps } from "../../types/OnboardingSteps";
import onboardingStepsMap from "./onboardingStepsMap";

interface Props {
    step: OnboardingSteps;
}

function OnboardingStep(props: Props) {
    const { step } = props;
    const StepComponent = onboardingStepsMap[step];

    if (!StepComponent) {
        return <div>Step not found!</div>;
    }

    return <StepComponent />;
}
export default OnboardingStep;
