import { useEffect, useState } from "react";
import { listenToDocumentToState } from "../api/listeners";
import { conversationDocument } from "../api/firestore";
import { Conversation } from "../../../lmt/src/common/types/Conversation"; // TODO: Fix this import

interface Props {
    children: (conversation: Conversation) => JSX.Element;
    isLoading?: () => JSX.Element;
}

export default function ListenToConversation(
    props: { conversationId: string | undefined } & Props,
) {
    const { conversationId, children, isLoading } = props;
    const [conversation, setConversation] = useState<
        Conversation | undefined | null
    >();

    useEffect(() => {
        if (!conversationId) {
            setConversation(undefined);
            return undefined;
        }
        return listenToDocumentToState<Conversation>(
            conversationDocument(conversationId),
            Conversation,
            setConversation,
        );
    }, [conversationId]);

    if (!conversation) return isLoading ? isLoading() : null;
    return children(conversation);
}
