import { useEffect, useState } from "react";
import { listenToDocumentToState } from "../api/listeners";
import { memberDocument } from "../api/firestore";
import { Member } from "../../../lmt/src/common/types/Member";

interface Props {
    children: (member: Member) => JSX.Element;
    isLoading?: () => JSX.Element;
}

export default function ListenToMember(
    props: { memberId: string | undefined } & Props,
) {
    const { memberId, children, isLoading } = props;
    const [member, setMember] = useState<Member | undefined | null>();

    useEffect(() => {
        if (!memberId) {
            setMember(undefined);
            return undefined;
        }
        return listenToDocumentToState<Member>(
            memberDocument(memberId),
            Member,
            setMember,
        );
    }, [memberId]);

    if (!member) return isLoading ? isLoading() : null;
    return children(member);
}
