import React from "react";
import { Lib, useAlbertineTranslation } from "albertine-shared-web";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";
import "./PastBookings.css";
import { fromTimestampToDate } from "../utils/timestamp.util";
import { groupBookingsByMonthAndYear } from "../utils/bookings.util";
import { useScreenStack } from "../context/screenStack";

interface Props {
    bookings: MemberBooking[];
}

function PastBookings(props: Props) {
    const { bookings } = props;
    const t = useAlbertineTranslation();
    const currentYear = new Date().getFullYear();
    const batchedBookings = groupBookingsByMonthAndYear(bookings);
    const { openBooking } = useScreenStack();

    return batchedBookings.length !== 0 ? (
        <Lib.Flex.Column>
            <Lib.Heading.H3.XBold>
                {t("bookings__your_history")}
            </Lib.Heading.H3.XBold>
            {batchedBookings.map(({ month, year, sortedBookings }) => (
                <Lib.Flex.Column key={`${year}-${month}`}>
                    <Lib.Heading.H6.Bold className="past_bookings__month">
                        {month} {currentYear !== year && year}
                    </Lib.Heading.H6.Bold>
                    {sortedBookings.map((booking: MemberBooking) => {
                        const {
                            id,
                            imageURL,
                            croppedImageURL,
                            title,
                            startTime,
                            startTimezone,
                            endTime,
                            endTimezone,
                            numberOfGuests,
                            numberOfNights,
                            rooms,
                        } = booking;

                        const startTimeString = startTime
                            ? fromTimestampToDate(startTime).toISOString()
                            : undefined;
                        const endTimeString = endTime
                            ? fromTimestampToDate(endTime).toISOString()
                            : undefined;

                        const { bookingStartTime, bookingEndTime } =
                            Lib.Utils.TextFormatter.booking.time(
                                startTimeString,
                                startTimezone || undefined,
                                endTimeString,
                                endTimezone || undefined,
                            );

                        const timeDetail = (() => {
                            if (!bookingStartTime && !bookingEndTime) {
                                return undefined;
                            }
                            return bookingEndTime
                                ? `${bookingStartTime} - ${bookingEndTime}`
                                : bookingStartTime;
                        })();
                        const details = [
                            timeDetail,
                            Lib.Utils.TextFormatter.booking.numberOfNights(
                                numberOfNights || undefined,
                            ),
                            Lib.Utils.TextFormatter.booking.numberOfGuests(
                                numberOfGuests || undefined,
                            ),
                            rooms,
                        ].filter(Boolean);

                        return (
                            <Lib.Flex.Row
                                className="past_booking"
                                key={id}
                                onClick={() => openBooking(id)}
                            >
                                <Lib.RemoteImage.Square.Small
                                    src={croppedImageURL || imageURL}
                                    alt={title || ""}
                                    className="past_booking__image"
                                />

                                <Lib.Flex.Column className="past_booking__info">
                                    <Lib.Heading.H4.XBold className="past_booking__title">
                                        {title}
                                    </Lib.Heading.H4.XBold>
                                    <span>
                                        {details.length !== 0 && (
                                            <Lib.Label.Medium.Reqular.TextColor02>
                                                {details.join(" · ")}
                                            </Lib.Label.Medium.Reqular.TextColor02>
                                        )}
                                    </span>
                                    <Lib.Line.Dashed className="past_booking__line" />
                                </Lib.Flex.Column>
                            </Lib.Flex.Row>
                        );
                    })}
                </Lib.Flex.Column>
            ))}
        </Lib.Flex.Column>
    ) : null;
}

export default PastBookings;
