import React, { useEffect, useState } from "react";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { getUILanguage, Lib } from "albertine-shared-web";
import config from "../config";
import adjustMapCenter from "../utils/map.utils";
import ArticleMapDetails from "./ArticleMapDetails";
import "./ArticleMap.css";

interface Props {
    name: string;
    address: string | null;
    website: string | null;
    location: {
        point: {
            lat: number;
            lon: number;
        };
    };
    image: string | null | undefined;
    placeId: string | null;
}

function ArticleMap(props: Props) {
    const { name, address, website, location, image, placeId } = props;
    const [mapsLoadError, setMapsLoadError] = useState<string | null>(null);
    const [mapCenter, setMapCenter] = useState({
        lat: location?.point.lat,
        lng: location?.point.lon,
    });
    const apiKey = config.mapsApiKey;
    const { lang } = getUILanguage();

    const handleMapLoadFailure = () => {
        setMapsLoadError("Failed to load Google Maps. Please try again later.");
    };

    useEffect(() => {
        adjustMapCenter(location, setMapCenter);
        window.addEventListener("resize", () =>
            adjustMapCenter(location, setMapCenter),
        );
        return () => {
            window.removeEventListener("resize", () =>
                adjustMapCenter(location, setMapCenter),
            );
        };
    }, [location, location.point.lat, location.point.lon]);

    const placeDetails = {
        name,
        website,
        address,
        placeId,
    };

    return (
        <APIProvider
            apiKey={apiKey}
            onError={handleMapLoadFailure}
            language={lang}
        >
            {mapsLoadError ? (
                <Lib.Paragraph.Medium.Bold.TextColor01>
                    {mapsLoadError}
                </Lib.Paragraph.Medium.Bold.TextColor01>
            ) : (
                <Lib.Flex.Column className="article-map__container">
                    <Map
                        mapId={name}
                        center={mapCenter}
                        defaultZoom={15}
                        gestureHandling="none"
                        disableDefaultUI
                        className="article-map__map"
                    >
                        <AdvancedMarker
                            position={{
                                lat: location.point.lat,
                                lng: location.point.lon,
                            }}
                            title={name}
                            className="article-map__marker"
                        >
                            {image && (
                                <img
                                    src={image}
                                    width={60}
                                    height={60}
                                    alt={name}
                                    className="article-map__marker-image"
                                />
                            )}

                            <Lib.Icon.LocationOn.Medium.Gray100 className="article-map__pin" />
                        </AdvancedMarker>
                    </Map>
                    <Lib.ContentCenter.Large className="article-map__details">
                        <ArticleMapDetails details={placeDetails} />
                    </Lib.ContentCenter.Large>
                </Lib.Flex.Column>
            )}
        </APIProvider>
    );
}

export default ArticleMap;
