import { DocumentData, DocumentReference, getDoc } from "firebase/firestore";
import { z } from "zod";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export async function loadDocument<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
): Promise<T> {
    const doc = await getDoc(document);
    if (!doc.exists()) {
        throw new Error("Document does not exist");
    }

    const parsedDoc = schema.safeParse({
        ...doc.data(),
        id: doc.id,
    });

    if (!parsedDoc.success) {
        throw parsedDoc.error;
    }

    return parsedDoc.data;
}

export async function loadToDocumentToState<T>(
    document: DocumentReference<DocumentData>,
    schema: z.ZodSchema<T>,
    setState: SetState<T | undefined>,
) {
    try {
        const doc = await loadDocument<T>(document, schema);
        setState(doc);
    } catch (error) {
        // TODO: Log error to backend
        console.log(error);
        setState(undefined);
    }
}
