import React, { useEffect, useState } from "react";
import { listenToQueryToState } from "../api/listeners";
import { queryBookings } from "../api/firestore";
import { MemberBooking } from "../../../lmt/src/common/types/Booking";

type ListenToBookingsProps = {
    memberId: string;
    children: (bookings: MemberBooking[] | undefined) => React.ReactNode;
    isLoading?: JSX.Element;
};

function ListenToBookings(props: ListenToBookingsProps) {
    const [bookings, setBookings] = useState<MemberBooking[] | undefined>(
        undefined,
    );
    const { memberId, children, isLoading } = props;

    useEffect(() => {
        if (!memberId) return undefined;

        const unsubscribe = listenToQueryToState(
            queryBookings(memberId),
            setBookings,
        );

        return () => unsubscribe();
    }, [memberId]);

    if (!bookings) return isLoading || null;

    return children(bookings);
}

export default ListenToBookings;
