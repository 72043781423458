export const takeFirst = (numberOfItems: number, fromArray: any[]) =>
    fromArray.slice(0, numberOfItems);

export const takeAllAfter = (numberOfExcludedItems: number, fromArray: any[]) =>
    fromArray.slice(numberOfExcludedItems, fromArray.length);

export function joinWith(
    array: string[],
    separator: string,
    lastSeparator: string,
) {
    if (array.length <= 1) return array.join(separator);
    return `${array
        .slice(0, -1)
        .join(separator)} ${lastSeparator} ${array.slice(-1)}`;
}

export function split<T>(array: T[], chunkSize: number): T[][] {
    if (array.length <= chunkSize) return [array];
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
}

export function fromArrayToMap<T extends { id: K }, K extends string>(
    items: T[],
) {
    return items.reduce<{
        [key in K]: T;
    }>(
        (prev, current) => {
            if (prev[current.id]) {
                return prev;
            }

            return {
                ...prev,
                [current.id]: current,
            };
        },
        {} as { [key in K]: T },
    );
}

export function partition<T>(
    array: T[],
    filter: (item: T, index: number, array: T[]) => boolean,
): [T[], T[]] {
    const pass: T[] = [];
    const fail: T[] = [];
    array.forEach((item, i, arr) =>
        (filter(item, i, arr) ? pass : fail).push(item),
    );
    return [pass, fail];
}

export const excludeUndefined = Boolean as any as <T>(
    x: T | undefined,
) => x is T;

export function removeDuplicates<T>(arr: T[]): T[] {
    return arr.filter((element, index) => arr.indexOf(element) === index);
}

export function haveMatchingElementsById<
    T extends { id: string },
    K extends { id: string },
>(compare: T[], compareTo: K[] | null) {
    return (
        compare.map((it) => it.id).length === compareTo?.length &&
        compare
            .map((it) => it.id)
            .filter((it) => compareTo?.map((match) => match.id).includes(it))
            .length === compare.length
    );
}

export function pushOrUpdate<T>(
    item: T,
    equals: (a: T, b: T) => boolean,
    array: T[],
): T[] {
    const itemIndex = array.findIndex((x) => equals(x, item));
    if (itemIndex >= 0) {
        const result = [...array];
        result[itemIndex] = item;
        return result;
    }
    return [...array, item];
}

export function isEmpty(array?: unknown[] | undefined | null): boolean {
    return array ? array.length === 0 : true;
}

export function moveMatchingItemToFront<T>(
    item: T,
    array: T[],
    equals: (a: T, b: T) => boolean,
): T[] {
    return array.find((x) => equals(x, item))
        ? [item, ...array.filter((x) => !equals(x, item))]
        : array;
}

export function findDuplicates(arr: string[]) {
    return arr.filter((value, index) => arr.indexOf(value) !== index);
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
}

export function onlyUniqueWithExtractor<T>(
    extractor: (item: T) => string,
): (item: T, index: number, self: T[]) => boolean {
    return (value, index, self) =>
        self.findIndex((item) => extractor(item) === extractor(value)) ===
        index;
}

export function fileListToArray(iterable: FileList) {
    return Array.from(iterable);
}

export function arrayOfNNumbers(n: number): number[] {
    return Array.from(Array(n).keys());
}

export function sortArrayByKeyOrder<T>(
    keyOrder: string[],
    array: T[],
    keyExtractor: (item: T) => string,
): T[] {
    // Create a map of key to its index in the keyOrder array
    const keyIndexMap = new Map(keyOrder.map((key, index) => [key, index]));

    // Sort the array based on the index in the keyOrder array
    return array.sort((a, b) => {
        const keyA = keyExtractor(a);
        const keyB = keyExtractor(b);

        const indexA = keyIndexMap.get(keyA) ?? -1;
        const indexB = keyIndexMap.get(keyB) ?? -1;

        return indexA - indexB;
    });
}
