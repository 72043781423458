import React from "react";
import { motion } from "framer-motion";
import { Lib } from "albertine-shared-web";
import classNames from "classnames";
import "./AlbertineModal.css";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    children: React.ReactNode;
}

function AlbertineModal(props: ModalProps) {
    const { className, children, isOpen, onClose } = props;

    if (!isOpen) return null; // Prevent rendering when not open

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="albertine-modal__backdrop" onClick={onClose}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    maxWidth: "514px",
                    height: "auto",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    zIndex: 1000,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    padding: "40px 32px 32px 32px",
                    borderRadius: "16px",
                    overflow: "visible",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Lib.Flex.Column
                    gap="value16"
                    className={classNames("albertine-modal", className)}
                >
                    {children}
                </Lib.Flex.Column>
            </motion.div>
        </div>
    );
}

export default AlbertineModal;
