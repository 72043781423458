import { z } from "zod";
import { dateRegex, twentyFourHourTimeRegex } from "../validate";

export const QuickRequestType = z.enum(["restaurant", "travel", "events"]);
export type QuickRequestType = z.infer<typeof QuickRequestType>;

export const RestaurantQuickRequest = z.object({
    type: z.literal("restaurant"),
    title: z.string(),
    startDate: z.string().regex(dateRegex),
    startTime: z.string().regex(twentyFourHourTimeRegex).optional(),
    numberOfGuests: z.number(),
    additionalInfoQuestion: z.string().optional(),
    additionalInfoAnswer: z.string().optional(),
    desiredLocationQuestion: z.string().optional(),
    desiredLocationAnswer: z.string().optional(),
    specificLocationQuestion: z.string().optional(),
    specificLocationAnswer: z.string().optional(),
});
export type RestaurantQuickRequest = z.infer<typeof RestaurantQuickRequest>;

export const TravelQuickRequestSubtype = z.enum([
    "travelItinerary",
    "flight",
    "accommodation",
    "transportation",
]);
export type TravelQuickRequestSubtype = z.infer<
    typeof TravelQuickRequestSubtype
>;
export const TravelQuickRequest = z.object({
    type: z.literal("travel"),
    subtype: TravelQuickRequestSubtype.optional(),
    title: z.string(),
});
export type TravelQuickRequest = z.infer<typeof TravelQuickRequest>;

export const EventQuickRequest = z.object({
    type: z.literal("events"),
    title: z.string(),
});
export type EventQuickRequest = z.infer<typeof EventQuickRequest>;

export const QuickRequest = z.discriminatedUnion("type", [
    RestaurantQuickRequest,
    TravelQuickRequest,
    EventQuickRequest,
]);

export type QuickRequest = z.infer<typeof QuickRequest>;

export const QuickRequestTypeMap: { [key in QuickRequestType]: string } = {
    restaurant: "Restaurant booking",
    travel: "Travel request",
    events: "Event request",
};
