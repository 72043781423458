/* eslint-disable no-underscore-dangle */
import { z } from "zod";
import { DynamicTheme, ThemeType } from "./DynamicTheme";
import {
    OpenSearchIndexConfiguration,
    OpenSearchQuery,
    OpenSearchResponse,
} from "./OpenSearch";

export const ThemesIndexMappings: OpenSearchIndexConfiguration = {
    mappings: {
        properties: {
            key: { type: "text" },
            name: { type: "text" },
            published: { type: "date" },
            slugs: { type: "keyword" },
            query: { type: "text" },
        },
    },
};

export const OpenSearchTheme = DynamicTheme.omit({
    location: true,
    group: true,
    preferences: true,
    image: true,
    type: true,
});
export type OpenSearchTheme = z.infer<typeof OpenSearchTheme>;

export const ThemeResponse = OpenSearchResponse(OpenSearchTheme);

export type ThemeResponse = z.infer<typeof ThemeResponse>;
export function themeResponseToThemes(
    response: ThemeResponse,
): OpenSearchTheme[] {
    return response.hits.hits.map((hit) => hit._source);
}

interface FilterThemeType {
    filter: [{ term: { type: ThemeType } }];
}

interface FilterNotThemeType {
    must_not: [{ term: { type: ThemeType } }];
}

export interface ThemeQuery extends OpenSearchQuery {
    query: {
        bool: FilterThemeType | FilterNotThemeType;
    };
    sort: [
        {
            published: {
                order: "desc";
            };
        },
    ];
}

export const QuerySpotlight: ThemeQuery = {
    query: {
        bool: {
            filter: [{ term: { type: "spotlight" } }],
        },
    },
    sort: [
        {
            published: {
                order: "desc",
            },
        },
    ],
};

export const themesQuery = (
    from: number = 0,
    size: number = 100,
): ThemeQuery => ({
    from,
    size,
    query: {
        bool: {
            must_not: [{ term: { type: "spotlight" } }],
        },
    },
    sort: [
        {
            published: {
                order: "desc",
            },
        },
    ],
});

export function getQueriesForNextPages(
    total: number,
    page: number = 100,
): ThemeQuery[] {
    const queries: ThemeQuery[] = [];
    const secondPage = 2;
    for (let i = secondPage; i <= Math.ceil(total / page); i++) {
        queries.push(themesQuery((i - 1) * page, page));
    }
    return queries;
}
