import { z } from "zod";

export type OpenSearchPagination = { from: number; size: number };

export function OpenSearchHit<HitSchema extends z.ZodTypeAny>(
    hitSchema: HitSchema,
) {
    return z.object({
        _index: z.string(),
        _id: z.string(),
        _score: z.number().nullable().optional(),
        _source: hitSchema,
        sort: z.array(z.number()).optional(),
    });
}

const OpenSearchResultCreated = z.literal("created");
const OpenSearchResultUpdated = z.literal("updated");
const OpenSearchResultDeleted = z.literal("deleted");
const OpenSearchResultNoOperation = z.literal("noop");

export const OpenSearchCreateDocumentSuccessResponse = z.object({
    _index: z.string(),
    _id: z.string(),
    _version: z.number(),
    result: OpenSearchResultCreated.or(OpenSearchResultUpdated).or(
        OpenSearchResultNoOperation,
    ),
});

export const OpenSearchUpdateDocumentSuccessResponse = z.object({
    _index: z.string(),
    _id: z.string(),
    _version: z.number(),
    result: OpenSearchResultUpdated.or(OpenSearchResultNoOperation),
});

export const OpenSearchDeleteDocumentSuccessResponse = z.object({
    _index: z.string(),
    _id: z.string(),
    _version: z.number(),
    result: OpenSearchResultDeleted,
});

export function OpenSearchResponse<HitSchema extends z.ZodTypeAny>(
    hitSchema: HitSchema,
) {
    return z.object({
        hits: z.object({
            total: z.object({
                value: z.number(),
            }),
            max_score: z.number().nullable(),
            hits: z.array(OpenSearchHit(hitSchema)),
        }),
    });
}

export type OpenSearchCreateDocumentSuccessResponse = z.infer<
    typeof OpenSearchCreateDocumentSuccessResponse
>;
export type OpenSearchUpdateDocumentSuccessResponse = z.infer<
    typeof OpenSearchUpdateDocumentSuccessResponse
>;
export type OpenSearchDeleteDocumentSuccessResponse = z.infer<
    typeof OpenSearchDeleteDocumentSuccessResponse
>;

export type OpenSearchApiResponse<SuccessResponse> = {
    success: boolean;
    response: SuccessResponse | undefined;
};

const PartialPropertyMapping = z.object({
    type: z.enum(["text", "date", "keyword"]).optional(),
    analyzer: z.enum(["accentAnalyzer"]).optional(),
});

const PropertyMapping = PartialPropertyMapping.merge(
    z.object({
        properties: z.record(PartialPropertyMapping).optional(),
    }),
);

export const OpenSearchIndexConfiguration = z.object({
    mappings: z
        .object({
            properties: z.record(PropertyMapping),
        })
        .optional(),
});

export type OpenSearchIndexConfiguration = z.infer<
    typeof OpenSearchIndexConfiguration
>;

export interface OpenSearchQuery {
    from?: number | "$from";
    size?: number | "$size";
}

export function setQueryPagination(
    query: OpenSearchQuery,
    pagination: OpenSearchPagination = { from: 0, size: 100 },
): OpenSearchQuery {
    return {
        ...query,
        from: pagination.from,
        size: pagination.size,
    };
}

export const OpenSearchVisibilityFilter = z.enum(["published", "unpublished"]);

export type OpenSearchVisibilityFilter = z.infer<
    typeof OpenSearchVisibilityFilter
>;

export const OpenSearchVisibilityFilterTextMap: {
    [key in OpenSearchVisibilityFilter]: string;
} = {
    unpublished: "Unpublished",
    published: "Published",
};
