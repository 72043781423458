import React, { useState, useRef, useEffect } from "react";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import { useLocation } from "react-router";
import {
    Lib,
    getUILanguage,
    useAlbertineTranslation,
    useTenant,
} from "albertine-shared-web";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import {
    checkTheSignInLink,
    sendEmailLink,
    sendSms,
    setupRecaptchaVerifier,
} from "../api/auth";
import Screen from "./Screen";
import "./Login.css";
import hbLevel4Video from "../assets/CardLvl04.mp4";
import LoginFormStep from "../components/LoginFormStep";
import { defaultCountryCode } from "../constants/countryCodes";
import {
    getLoginEmail,
    getLoginPhoneCode,
    getLoginPhoneNumber,
    setLoginEmail,
    setLoginPhoneCode,
    setLoginPhoneNumber,
} from "../utils/localStorage.util";
import delay from "../utils/delay.util";
import LoadingScreen from "./Loading";
import { LoginStep } from "../types/LoginStep";
import { Email } from "../../../lmt/src/common/validate";
import LoginStepMotion from "../components/LoginMotion";

const recaptchaElementId = "recaptcha-container";

type LoginStepTitleProps = {
    isError: boolean;
    onBack: () => void;
    secondaryTitle: string;
    primaryTitle: string;
};
function LoginStepTitle(props: LoginStepTitleProps) {
    const { onBack, isError, secondaryTitle, primaryTitle } = props;
    const { tenant } = useTenant();
    const BackButtonIcon =
        tenant === "hugoboss"
            ? Lib.Icon.ArrowBack.Medium.Gray100
            : Lib.Icon.ArrowBack.Medium.Gray10;

    return (
        <>
            <Lib.Button.Ghost
                className="login_form_step__back-button"
                onClick={onBack}
            >
                <BackButtonIcon className="login_form_step__back-arrow" />
            </Lib.Button.Ghost>
            <Lib.Tag.Primary
                className={classNames(
                    "login_form_step__tag",
                    "code" && {
                        login_form_step__tag_error: isError,
                    },
                )}
            >
                {secondaryTitle}
            </Lib.Tag.Primary>
            <Lib.Paragraph.XXLarge.Bold.TextColor01 className="login_form_step__title">
                {primaryTitle}
            </Lib.Paragraph.XXLarge.Bold.TextColor01>
        </>
    );
}

function Login() {
    const [confirmationResult, setConfirmationResult] = useState<
        ConfirmationResult | undefined
    >();

    const [countryCode, setCountryCode] = useState<string>(defaultCountryCode);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [loginError, setLoginError] = useState<boolean>(false);
    const [step, setStep] = useState<LoginStep>("intro");
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [validEmail, setValidEmail] = useState<boolean>(false);

    const { tenant, tenantConfig } = useTenant();
    const t = useAlbertineTranslation();
    const location = useLocation();

    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
    const supportEmailLink = `mailto:${tenantConfig.emailService}`;

    useEffect(() => {
        const hasParams = location.search.length > 0;
        recaptchaVerifierRef.current =
            setupRecaptchaVerifier(recaptchaElementId);
        if (hasParams) {
            setLoadingScreen(true);
        } else {
            setLoadingScreen(false);
        }

        if (getLoginPhoneCode !== null) {
            const cc = getLoginPhoneCode() || defaultCountryCode;
            setCountryCode(cc);
        }

        if (getLoginPhoneNumber !== null) {
            const pn = getLoginPhoneNumber() || "";
            setPhoneNumber(pn);
        }
        if (getLoginEmail !== null) {
            const emailAddress = getLoginEmail() || "";
            setEmail(emailAddress);
            checkTheSignInLink(emailAddress);
        }
    }, []);

    const handleSendCode = async () => {
        setLoginError(false);
        setLoginPhoneCode(countryCode);
        setLoginPhoneNumber(phoneNumber);
        setStep("code");
        try {
            if (recaptchaVerifierRef.current) {
                const result = await sendSms(
                    countryCode + phoneNumber,
                    tenant,
                    recaptchaVerifierRef.current,
                );

                if (result) {
                    setLoginError(false);
                    setConfirmationResult(result);
                }
            }
        } catch (error) {
            console.error("SMS not sent", error);
            setLoginError(true);
        }

        recaptchaVerifierRef.current =
            setupRecaptchaVerifier(recaptchaElementId);
    };

    const handleVerifyCode = async () => {
        setLoading(true);
        if (confirmationResult) {
            try {
                await delay(3000);
                await confirmationResult.confirm(verificationCode);
            } catch (error) {
                console.error("Error during verification", error);
                setLoginError(true);
                setLoading(false);
            }
        } else {
            await delay(3000);
            setLoginError(true);
            setLoading(false);
        }

        recaptchaVerifierRef.current =
            setupRecaptchaVerifier(recaptchaElementId);
    };

    const handleCountryPhonePrefix = (value: string) => {
        setCountryCode(value);
    };

    const handlePhoneNumber = (value: string) => {
        setPhoneNumber(value);
    };

    const handleVerificationCode = (value: string) => {
        setVerificationCode(value);
    };

    const handleEmail = (value: string) => {
        setEmail(value);
        try {
            Email.parse(value);
            setValidEmail(true);
        } catch (error) {
            setValidEmail(false);
        }
    };

    const handleSendEmail = async () => {
        setLoginEmail(email);
        setStep("link");
        try {
            if (recaptchaVerifierRef.current) {
                const languageCode = getUILanguage().lang;
                const result = await sendEmailLink(email, tenant, languageCode);

                if (result) {
                    setLoginError(false);
                    setConfirmationResult(result);
                }
            }
        } catch (error) {
            console.error("Email not sent", error);
            setLoginError(true);
        }
    };

    useEffect(() => {
        setLoginError(false);
        if (verificationCode.length === 6) {
            handleVerifyCode();
        }
        try {
            Email.parse(email);
            setValidEmail(true);
        } catch (error) {
            setValidEmail(false);
        }
    }, [verificationCode, email]);

    const albertineIntro = (
        <Lib.Flex.Column
            alignItems="center"
            gap="value32"
            className="login__intro"
        >
            <Lib.Flex.Column
                className="login__albertine-icon-wrapper"
                justifyContent="end"
            >
                <Lib.Asset.AlbertineEmblem className="login__albertine-icon" />
            </Lib.Flex.Column>

            <Lib.Flex.Column
                alignItems="center"
                gap="value24"
                justifyContent="end"
                className="login__content-wrapper-intro"
            >
                <Lib.Flex.Column className="login__albertine-text-icon-wrapper">
                    <Lib.Asset.AlbertineTextLogo />
                </Lib.Flex.Column>
                <Lib.Paragraph.Medium.Reqular.TextColor02>
                    {t("login__albertine_slogan")}
                </Lib.Paragraph.Medium.Reqular.TextColor02>

                <Lib.Flex.Column gap="value24" alignItems="center">
                    <Lib.Button.Primary
                        onClick={() => setStep("phone")}
                        label={t("login__get_started")}
                        className="login_form_step__get_started_button"
                    />
                    <Lib.TextLink href={tenantConfig.serviceMarketingUrl}>
                        {t("login__join_text")}
                    </Lib.TextLink>
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );

    const hugobossIntro = (
        <Lib.Flex.Column
            gap="value32"
            alignItems="center"
            className="login__intro"
        >
            <Lib.Flex.Column
                alignItems="center"
                className="login__intro-hb-video-wrapper"
            >
                <video autoPlay loop muted className="login__intro-hb-video">
                    <source src={hbLevel4Video} type="video/mp4" />
                </video>
            </Lib.Flex.Column>

            <Lib.Flex.Column
                gap="value32"
                alignItems="center"
                className="login__content-wrapper-intro"
            >
                <Lib.Flex.Column
                    gap="value4"
                    alignItems="center"
                    className="login__intro-hb-logo"
                >
                    <Lib.Heading.H1.XBold>Hugo Boss XP</Lib.Heading.H1.XBold>
                    <Lib.Heading.H1.XBold className="login__intro-hb-logo-second-text">
                        Concierge
                    </Lib.Heading.H1.XBold>
                </Lib.Flex.Column>

                <Lib.Flex.Column gap="value32" alignItems="center">
                    <Lib.Paragraph.Medium.Reqular.TextColor01 className="login__step-description">
                        {t("login-hb__level4_description")}
                    </Lib.Paragraph.Medium.Reqular.TextColor01>
                    <Lib.Button.PrimaryBackground
                        onClick={() => setStep("phone")}
                        label={t("login__get_started")}
                        className="login_form_step__get_started_button"
                    />

                    <Lib.Asset.PoweredByAlbertineHBBrown />
                </Lib.Flex.Column>
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );

    return (
        <>
            <div id={recaptchaElementId} />

            {loadingScreen && <LoadingScreen />}
            {!loadingScreen && (
                <Screen.StartBg01
                    textColorInversable={tenant === "hugoboss"}
                    noScroll
                >
                    <Lib.ContentCenter.Small className="login__screen">
                        {step === "intro" && (
                            <AnimatePresence>
                                <LoginStepMotion className="login__step">
                                    {tenant === "albertine" && albertineIntro}
                                    {tenant === "hugoboss" && hugobossIntro}
                                </LoginStepMotion>
                            </AnimatePresence>
                        )}

                        {step === "phone" && (
                            <AnimatePresence>
                                <LoginStepMotion className="login__step">
                                    <Lib.Flex.Column className="login__content-wrapper">
                                        <LoginStepTitle
                                            secondaryTitle={t(
                                                "login_form__tag_sign_in",
                                            )}
                                            primaryTitle={t(
                                                "login_form__title_sms",
                                            )}
                                            isError={false}
                                            onBack={() => setStep("intro")}
                                        />

                                        <LoginFormStep
                                            type="phone"
                                            descriptionText={t(
                                                "login_form__description_sms",
                                            )}
                                            countryCode={countryCode}
                                            currentValue={phoneNumber}
                                            buttonText={t(
                                                "login_form__continue",
                                            )}
                                            buttonDisabled={
                                                phoneNumber?.length < 6
                                            }
                                            onTyping={handlePhoneNumber}
                                            onSelect={handleCountryPhonePrefix}
                                            onButton={handleSendCode}
                                        />
                                    </Lib.Flex.Column>
                                </LoginStepMotion>
                            </AnimatePresence>
                        )}

                        {step === "code" && (
                            <AnimatePresence>
                                <LoginStepMotion className="login__step">
                                    <Lib.Flex.Column className="login__content-wrapper">
                                        <LoginStepTitle
                                            secondaryTitle={
                                                loginError
                                                    ? t(
                                                          "login_form__tag_error_incorrect_code",
                                                      )
                                                    : t(
                                                          "login_form__tag_verification",
                                                      )
                                            }
                                            primaryTitle={t(
                                                "login_form__title_code",
                                            )}
                                            isError={loginError}
                                            onBack={() => setStep("phone")}
                                        />
                                        <LoginFormStep
                                            type="code"
                                            descriptionText={t(
                                                "login_form__description_code",
                                            )}
                                            currentValue={verificationCode}
                                            buttonText={t("login_form__verify")}
                                            onTyping={handleVerificationCode}
                                            onButton={handleVerifyCode}
                                            onEmailLink={() => setStep("email")}
                                            loading={loading}
                                        />
                                    </Lib.Flex.Column>
                                </LoginStepMotion>
                            </AnimatePresence>
                        )}

                        {step === "email" && (
                            <AnimatePresence>
                                <LoginStepMotion className="login__step">
                                    <Lib.Flex.Column className="login__content-wrapper">
                                        <LoginStepTitle
                                            secondaryTitle={
                                                loginError
                                                    ? t("errorMessage")
                                                    : t(
                                                          "login_form__tag_sign_in",
                                                      )
                                            }
                                            primaryTitle={t(
                                                "login_form__title_email",
                                            )}
                                            isError={loginError}
                                            onBack={() => setStep("code")}
                                        />

                                        <LoginFormStep
                                            type="email"
                                            descriptionText={t(
                                                "login_form__description_email",
                                            )}
                                            buttonText={t(
                                                "login_form__continue",
                                            )}
                                            onTyping={handleEmail}
                                            onButton={handleSendEmail}
                                            buttonDisabled={!validEmail}
                                            currentValue={email || ""}
                                        />
                                    </Lib.Flex.Column>
                                </LoginStepMotion>
                            </AnimatePresence>
                        )}

                        {step === "link" && (
                            <AnimatePresence>
                                <LoginStepMotion className="login__step">
                                    <Lib.Flex.Column className="login__content-wrapper">
                                        <LoginStepTitle
                                            secondaryTitle={t(
                                                "login_form__tag_sign_in",
                                            )}
                                            primaryTitle={t(
                                                "login_form__title_link",
                                            )}
                                            isError={loginError}
                                            onBack={() => setStep("email")}
                                        />
                                        <LoginFormStep
                                            type="link"
                                            descriptionText={t(
                                                "login_form__description_link",
                                            )}
                                            buttonText={t(
                                                "login_form__open_email",
                                            )}
                                            onTyping={handleEmail}
                                            onButton={() =>
                                                window.open(supportEmailLink)
                                            }
                                        />
                                    </Lib.Flex.Column>
                                </LoginStepMotion>
                            </AnimatePresence>
                        )}
                    </Lib.ContentCenter.Small>
                </Screen.StartBg01>
            )}
        </>
    );
}

export default Login;
