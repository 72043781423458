import { z } from "zod";
import { PercentCrop, PixelCrop } from "../validate";

export const ImageKitUrlPrefix = "https://ik.imagekit.io/albertine/";
export const AttachmentUrl = z
    .string()
    .url()
    .refine((s) =>
        [
            ImageKitUrlPrefix,
            "https://storage.googleapis.com/albertine-prod.appspot.com/",
            "https://storage.googleapis.com/albertine-dev.appspot.com/",
            "https://storage.googleapis.com/albertine-staging.appspot.com/",
            "https://firebasestorage.googleapis.com/v0/b/albertine-prod.appspot.com/",
            "https://firebasestorage.googleapis.com:443/v0/b/albertine-prod.appspot.com/",
            "https://a.storyblok.com/f/160792", // prod
            "https://a.storyblok.com/f/263542", // staging
            "https://a.storyblok.com/f/149550", // dev
            "https://s3.amazonaws.com/a.storyblok.com/f/160792", // prod
            "https://s3.amazonaws.com/a.storyblok.com/f/263542", // staging
            "https://s3.amazonaws.com/a.storyblok.com/f/149550", // dev
            "https://firebasestorage.googleapis.com/v0/b/albertine-dev.appspot.com/",
            "https://firebasestorage.googleapis.com/v0/b/albertine-staging.appspot.com/",
            "https://firebasestorage.googleapis.com:443/v0/b/albertine-dev.appspot.com/",
            "https://firebasestorage.googleapis.com:443/v0/b/albertine-staging.appspot.com/",
            "http://localhost:9199/v0/b/albertine-dev.appspot.com/",
            "http://localhost:9199/albertine-dev.appspot.com/",
            "http://127.0.0.1:9199/albertine-dev.appspot.com/",
        ].some((prefix) => s.startsWith(prefix)),
    );
export type AttachmentUrl = z.infer<typeof AttachmentUrl>;

export const Attachment = z.object({
    id: z.string().optional(),

    filename: z.string().optional(),
    height: z.number().nullable().optional(),
    mimeType: z.string(),
    storageFullPath: z.string().optional(),
    storageName: z.string().optional(),
    url: AttachmentUrl,
    width: z.number().nullable().optional(),
});

export type Attachment = z.infer<typeof Attachment>;
export type AttachmentWithFile = Attachment & { file?: File };

export const ImageAttachmentCropped = z.object({
    croppedImageURL: AttachmentUrl.optional().nullable(),
    absoluteCrop: PixelCrop.optional().nullable(),
    relativeCrop: PercentCrop.optional().nullable(),
});

export const ImageAttachment = Attachment.and(ImageAttachmentCropped);
export type ImageAttachment = z.infer<typeof ImageAttachment>;

const BookingAttachmentPrivacy = z.object({
    isPublic: z.boolean().nullable().optional(),
});
type BookingAttachmentPrivacy = z.infer<typeof BookingAttachmentPrivacy>;
export const BookingAttachment = Attachment.and(BookingAttachmentPrivacy);
export type BookingAttachment = z.infer<typeof BookingAttachment>;
export type BookingAttachmentWithFile = BookingAttachment & { file?: File };

const ExternalAssetReference = z.object({
    type: z.literal("external"),
    imageUrl: z.string(),
});
export type ExternalAssetReference = z.infer<typeof ExternalAssetReference>;

const LocalAssetReference = z.object({
    type: z.literal("local"),
    imageRef: z.object({
        height: z.number(),
        width: z.number(),
        mimeType: z.string(),
        storageName: z.string(),
    }),
});
export type LocalAssetReference = z.infer<typeof LocalAssetReference>;

const LibraryAssetReference = z.object({
    type: z.literal("library"),
    id: z.string(),
    url: z.string(), // TODO: Use CatalogImage
});
export type LibraryAssetReference = z.infer<typeof LibraryAssetReference>;

export const AssetReference = z.discriminatedUnion("type", [
    ExternalAssetReference,
    LocalAssetReference,
    LibraryAssetReference,
]);
export type AssetReference = z.infer<typeof AssetReference>;
