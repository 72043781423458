import { FirebaseOptions } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseAppConfig: FirebaseOptions = {
    apiKey: import.meta.env.VITE_FIREBASE_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const extendedConfig = {
    mapsApiKey: import.meta.env.VITE_MAPS_PLATFORM_API_KEY,
    conciergeServiceHourAssetUrl: import.meta.env
        .VITE_STATIC_ASSET_URL_CONCIERGE_SERVICE_HOURS,
    webPushKey: import.meta.env.VITE_FIREBASE_MESSAGING_WEB_PUSH_KEY,
    topCitiesList: import.meta.env.VITE_TOP_CITIES_LIST,
};

export default {
    ...firebaseAppConfig,
    ...extendedConfig,
};
