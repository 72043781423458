import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AlbertineSlider.css";

interface AlbertineSliderProps {
    slidesToShow?: number;
    slidesToShowXL?: number;
    slidesToShowM?: number;
    slidesToShowSM?: number;
    hasTextBelow?: boolean;
    children: React.ReactNode;
}

interface AlbertineSliderArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    shouldMoveUp?: boolean;
}

function AlbertineSliderPreviousArrow(
    props: AlbertineSliderArrowProps,
): JSX.Element {
    const { className, style, onClick, shouldMoveUp } = props;

    return (
        <button
            type="button"
            className={`albertine-slider__arrow-icon ${className}`}
            style={{
                ...style,
                left: "15px",
                zIndex: 99,
                width: "24px",
                height: "24px",
                // shouldMoveUp is here to determine if we should move arrows up if there is text below the slider image
                top: shouldMoveUp ? "38%" : "50%",
            }}
            onClick={onClick}
        />
    );
}

function AlbertineSliderNextArrow(
    props: AlbertineSliderArrowProps,
): JSX.Element {
    const { className, style, onClick, shouldMoveUp } = props;

    return (
        <button
            type="button"
            className={`albertine-slider__arrow-icon ${className}`}
            style={{
                ...style,
                right: "15px",
                zIndex: 99,
                width: "24px",
                height: "24px",
                // shouldMoveUp is here to determine if we should move arrows up if there is text below the slider image
                top: shouldMoveUp ? "38%" : "50%",
            }}
            onClick={onClick}
        />
    );
}

function AlbertineSlider(props: AlbertineSliderProps) {
    const {
        children,
        slidesToShow = 3,
        slidesToShowXL = 3,
        slidesToShowM = 2,
        slidesToShowSM = 1,
        hasTextBelow,
    } = props;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        prevArrow: <AlbertineSliderPreviousArrow shouldMoveUp={hasTextBelow} />,
        nextArrow: <AlbertineSliderNextArrow shouldMoveUp={hasTextBelow} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: slidesToShowXL,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 744,
                settings: {
                    slidesToShow: slidesToShowM,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: slidesToShowSM,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="slider-container">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Slider {...settings}>{children}</Slider>
        </div>
    );
}

export default AlbertineSlider;
