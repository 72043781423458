import React from "react";
import { motion } from "framer-motion";

interface Props {
    children: JSX.Element;
    className?: string;
}

function Transition(props: Props) {
    const { children, className } = props;
    return (
        <motion.div
            variants={{
                initial: { opacity: 0, y: 25 },
                animate: { opacity: 1, y: 0 },
                exit: { opacity: 0, y: -40 },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            style={{ padding: 0, margin: 0, width: "100%" }}
            className={className && className}
        >
            {children}
        </motion.div>
    );
}
export default Transition;
