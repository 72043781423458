import { z } from "zod";
import { Tag, Tags } from "./tags";

const GooglePlacePriceLevel = z.enum([
    "PRICE_LEVEL_FREE",
    "PRICE_LEVEL_INEXPENSIVE",
    "PRICE_LEVEL_MODERATE",
    "PRICE_LEVEL_EXPENSIVE",
    "PRICE_LEVEL_VERY_EXPENSIVE",
]);
type GooglePlacePriceLevel = z.infer<typeof GooglePlacePriceLevel>;

const GooglePlacePriceLevelMap: { [key in GooglePlacePriceLevel]: Tag } = {
    PRICE_LEVEL_FREE: Tags.price.free,
    PRICE_LEVEL_INEXPENSIVE: Tags.price.inexpensive,
    PRICE_LEVEL_MODERATE: Tags.price.moderate,
    PRICE_LEVEL_EXPENSIVE: Tags.price.expensive,
    PRICE_LEVEL_VERY_EXPENSIVE: Tags.price.veryExpensive,
};

// Price level https://developers.google.com/maps/documentation/places/web-service/reference/rest/v1/places
export function mapGooglePlacePriceLevel(googlePlace: GooglePlace): Tag[] {
    const parsedLevel = GooglePlacePriceLevel.safeParse(googlePlace.priceLevel);
    if (!parsedLevel.success) return [];
    return [GooglePlacePriceLevelMap[parsedLevel.data]];
}

export function mapGooglePlaceDetailsToTags(place: GooglePlace): Tag[] {
    const tags: Tag[][] = [
        place.servesBreakfast ? ["meals:breakfast"] : [],
        place.servesBrunch ? ["meals:brunch"] : [],
        place.servesLunch ? ["meals:lunch"] : [],
        place.servesDinner ? ["meals:dinner", "type:restaurant"] : [],
        place.liveMusic ? ["type:live-music"] : [],
    ];

    return tags.flat();
}

export const GooglePlaceBusinessStatus = z.enum([
    "OPERATIONAL",
    "CLOSED_TEMPORARILY",
    "CLOSED_PERMANENTLY",
    "UNAVAILABLE",
]);
export type GooglePlaceBusinessStatus = z.infer<
    typeof GooglePlaceBusinessStatus
>;

export const GooglePlaceBusinessStatusMap: {
    [key in GooglePlaceBusinessStatus]: string;
} = {
    CLOSED_PERMANENTLY: "Closed permanently",
    CLOSED_TEMPORARILY: "Closed temporarily",
    OPERATIONAL: "Operational",
    UNAVAILABLE: "Business status unavailable",
};

export const GooglePlacePhoto = z.object({
    name: z.string(),
    heightPx: z.number(),
    widthPx: z.number(),
    // Note - photoUri is not part of Place APIs response
    // Update it post fetching images using Google Place Image APIs
    photoUri: z.string().optional().nullable(),
});

export type GooglePlacePhoto = z.infer<typeof GooglePlacePhoto>;

export const GoogleGeoPoint = z.object({
    latitude: z.number(),
    longitude: z.number(),
});

export type GoogleLocation = z.infer<typeof GoogleGeoPoint>;

export const GooglePlaceText = z.object({
    text: z.string(),
    languageCode: z.string(),
});

export type GooglePlaceText = z.infer<typeof GooglePlaceText>;

export const GoogleReview = z.object({
    name: z.string().optional().nullable(),
    rating: z.number().optional().nullable(),
    text: GooglePlaceText.optional().nullable(),
    originalText: GooglePlaceText.optional().nullable(),
});

export type GoogleReview = z.infer<typeof GoogleReview>;

export const GooglePlaceOpenClosePeriod = z.object({
    day: z.number(),
    hour: z.number(),
    minute: z.number(),
});

export type GooglePlaceOpenClosePeriod = z.infer<
    typeof GooglePlaceOpenClosePeriod
>;

export const GooglePlaceAddressComponent = z.object({
    longText: z.string(),
    shortText: z.string(),
    types: z.array(z.string()),
    languageCode: z.string().optional().nullable(),
});

export type GooglePlaceAddressComponent = z.infer<
    typeof GooglePlaceAddressComponent
>;

export const GooglePlace = z.object({
    id: z.string(),
    displayName: z
        .object({
            text: z.string().optional().nullable(),
            languageCode: z.string().optional().nullable(),
        })
        .optional()
        .nullable(),

    websiteUri: z.string().optional().nullable(),
    googleMapsUri: z.string().optional().nullable(),

    internationalPhoneNumber: z.string().optional().nullable(),

    businessStatus: GooglePlaceBusinessStatus.optional().nullable(),

    priceLevel: z.string().optional().nullable(),

    types: z.array(z.string()).optional().nullable(),
    primaryType: z.string().optional().nullable(),
    primaryTypeDisplayName: GooglePlaceText.optional().nullable(),

    photos: z.array(GooglePlacePhoto).optional().nullable(),

    rating: z.number().optional().nullable(),
    userRatingCount: z.number().optional().nullable(),
    userRatingsTotal: z.number().optional().nullable(),
    reviews: z.array(GoogleReview).optional().nullable(),

    // geometry
    location: GoogleGeoPoint.optional().nullable(),
    viewport: z
        .object({
            low: GoogleGeoPoint.optional().nullable(),
            high: GoogleGeoPoint.optional().nullable(),
        })
        .optional()
        .nullable(),

    addressComponents: z
        .array(GooglePlaceAddressComponent)
        .optional()
        .nullable(),
    formattedAddress: z.string().optional().nullable(),

    utcOffsetMinutes: z.number().optional().nullable(),

    regularOpeningHours: z
        .object({
            periods: z.array(
                z.object({
                    open: GooglePlaceOpenClosePeriod.optional().nullable(),
                    close: GooglePlaceOpenClosePeriod.optional().nullable(),
                }),
            ),
            weekdayDescriptions: z.array(z.string()).optional().nullable(),
        })
        .optional()
        .nullable(),

    servesBreakfast: z.boolean().optional().nullable(),
    servesBrunch: z.boolean().optional().nullable(),
    servesLunch: z.boolean().optional().nullable(),
    servesDinner: z.boolean().optional().nullable(),
    liveMusic: z.boolean().optional().nullable(),
    reservable: z.boolean().optional().nullable(),
});

export type GooglePlace = z.infer<typeof GooglePlace>;

export type GooglePlaceFields = Partial<keyof GooglePlace>[];
