import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { OpenSearchWebClient } from "../../../lmt/src/common/utils/opensearch/client";
import { MemberPreferences } from "../../../lmt/src/common/types/MemberCatalogConfiguration";
import { memberGetCatalogConfiguration } from "../api/auth";
import { openSearchWebClient } from "../../../lmt/src/common/utils/opensearch/webClient";
import { useAuth } from "./auth";

type OpenSearchContextType = {
    opensearch: OpenSearchWebClient | undefined;
    memberPreferences: MemberPreferences | undefined;
};

const OpenSearchContext = createContext<OpenSearchContextType>({
    opensearch: undefined,
    memberPreferences: undefined,
});

function OpenSearchProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [openSearch, setOpenSearch] = useState<OpenSearchContextType>({
        opensearch: undefined,
        memberPreferences: undefined,
    });
    const { user } = useAuth();

    useEffect(() => {
        const getMemberCatalog = async () => {
            const {
                url: memberCatalogUrl,
                token,
                topCities,
                topThemes,
            } = await memberGetCatalogConfiguration();

            setOpenSearch({
                opensearch: openSearchWebClient(memberCatalogUrl, () =>
                    Promise.resolve({ tokenType: "Basic", token }),
                ),
                memberPreferences: {
                    topCities,
                    topThemes,
                },
            });
        };

        if (user && !openSearch.opensearch) {
            getMemberCatalog();
        }
    }, [user, openSearch.opensearch]);

    const value = useMemo(() => openSearch, [openSearch]);

    return (
        <OpenSearchContext.Provider value={value}>
            {children}
        </OpenSearchContext.Provider>
    );
}

export default OpenSearchProvider;

export const useOpenSearch = () => {
    const context = useContext(OpenSearchContext);
    if (!context) {
        throw new Error(
            "useOpenSearch must be used within an OpenSearchContext",
        );
    }
    return context;
};
