/* eslint-disable no-param-reassign */
import { z } from "zod";
import { useEffect, useState } from "react";
import { chatMessagesKey, getLocalStorageData } from "./localStorage.util";
import { LocalStorageChatMessages } from "../../../lmt/src/common/types/Message";

interface StoredLocalStorageListener<T> {
    stateListeners?: ((_: T | undefined) => void)[];
}

function buildLocalStorageListener<T>(
    key: string,
    stored: StoredLocalStorageListener<T>,
    schema: z.ZodSchema<T>,
): (setState: (value: T | undefined) => void) => () => void {
    return (setState: (value: T | undefined) => void) => {
        // Add the setState function to the listeners
        stored.stateListeners = [...(stored.stateListeners || []), setState];

        // Get the data from the local storage and return immediately
        const data = getLocalStorageData(key, schema);
        if (data) {
            setState(data);
        }

        const onStorageChange = (event: StorageEvent) => {
            if (event.key !== key) return;
            const updatedData = getLocalStorageData(key, schema);
            stored.stateListeners?.forEach((listener) => listener(updatedData));
        };

        // Listen to the changes in other browser contexts
        window.addEventListener("storage", onStorageChange);

        return () => {
            stored.stateListeners = stored.stateListeners?.filter(
                (listener) => listener !== setState,
            );
            window.removeEventListener("storage", onStorageChange);
        };
    };
}

export function triggerLocalStorageChange<T>(key: string, data: T) {
    // Window storage events do not trigger automatically in the same browser context
    const event = new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(data),
        storageArea: localStorage,
    });
    window.dispatchEvent(event);
}

const localStorageListeners = {
    localChatMessages:
        {} as StoredLocalStorageListener<LocalStorageChatMessages>,
};

export const useLocalChatMessages = () => {
    const [state, setState] = useState<LocalStorageChatMessages>();

    useEffect(
        () =>
            buildLocalStorageListener(
                chatMessagesKey,
                localStorageListeners.localChatMessages,
                LocalStorageChatMessages,
            )(setState),
        [],
    );

    return state;
};
