import { getUILanguage, translation } from "albertine-shared-web";
import {
    EventQuickRequest,
    QuickRequest,
    RestaurantQuickRequest,
    TravelQuickRequest,
} from "../types/QuickRequests";
import { isTypeOf } from "./zod.util";
import { localDateToDayDateString } from "./time.util";

export function quickRequestTitle(
    quickRequest: QuickRequest,
): string | undefined {
    if (isTypeOf(quickRequest, RestaurantQuickRequest)) {
        return quickRequest.title;
    }
    return undefined;
}

export function quickRequestSubtitle(
    quickRequest: QuickRequest,
): string | undefined {
    if (isTypeOf(quickRequest, RestaurantQuickRequest)) {
        const { startDate, startTime, numberOfGuests } = quickRequest;
        return [
            startDate &&
                localDateToDayDateString(
                    startDate,
                    getUILanguage().dateFnsLocale,
                ),
            startTime && ` ${startTime}`,
            numberOfGuests &&
                ` • ${translation("number-of-quests", {
                    count: numberOfGuests,
                })}`,
        ]
            .filter(Boolean)
            .join("");
    }
    return undefined;
}

export function quickRequestTag(
    quickRequest: QuickRequest,
): string | undefined {
    if (isTypeOf(quickRequest, RestaurantQuickRequest)) {
        return translation("quick_request__label");
    }
    if (isTypeOf(quickRequest, TravelQuickRequest)) {
        return quickRequest.title;
    }
    if (isTypeOf(quickRequest, EventQuickRequest)) {
        return quickRequest.title;
    }
    return undefined;
}

export function quickRequestQuestionAnswerPairs(quickRequest: QuickRequest): {
    question: string;
    answer: string;
}[] {
    if (isTypeOf(quickRequest, RestaurantQuickRequest)) {
        return [
            ...(quickRequest.desiredLocationQuestion
                ? [
                      {
                          question: quickRequest.desiredLocationQuestion,
                          answer: quickRequest.desiredLocationAnswer || "",
                      },
                  ]
                : []),
            ...(quickRequest.specificLocationQuestion
                ? [
                      {
                          question: quickRequest.specificLocationQuestion,
                          answer: quickRequest.specificLocationAnswer || "",
                      },
                  ]
                : []),
            ...(quickRequest.additionalInfoQuestion
                ? [
                      {
                          question: quickRequest.additionalInfoQuestion,
                          answer: quickRequest.additionalInfoAnswer || "",
                      },
                  ]
                : []),
        ];
    }
    return [];
}
