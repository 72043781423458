import React from "react";
import "./CountryCodes.css";
import { Lib } from "albertine-shared-web";
import classNames from "classnames";

interface ICountryCodes {
    countryCodes: string[];
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value?: string;
    className?: string;
}

function CountryCodes(props: ICountryCodes) {
    const { className, value, onChange, countryCodes } = props;

    const countryCodesClass = classNames("country_codes ", className);

    return (
        <div className="country_codes__wrapper">
            <select
                className={countryCodesClass}
                value={value}
                onChange={onChange}
            >
                {countryCodes.map((code: string) => (
                    <option key={code} value={code}>
                        {code}
                    </option>
                ))}
            </select>
            <span className="country_codes__custom_arrow">
                <Lib.Icon.ChevronRight />
            </span>
        </div>
    );
}

export default CountryCodes;
