import React, { useState, useEffect, ReactNode } from "react";
import { getDownloadURL, StorageReference } from "@firebase/storage";

const emptyImage =
    "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

const cacheDownloadedUrls = new Map<string, Promise<string>>();

async function getCachedDownloadURL(ref: StorageReference) {
    if (!cacheDownloadedUrls.has(ref.fullPath)) {
        cacheDownloadedUrls.set(ref.fullPath, getDownloadURL(ref));
    }
    return cacheDownloadedUrls.get(ref.fullPath);
}

interface Props {
    storageRef: StorageReference;
    alt?: string;
    className?: string | undefined;
    emptyString?: ReactNode;
}

const CachedAvatar = function CachedAvatar(props: Props) {
    const { storageRef, alt, className, emptyString } = props;
    const [avatar, setAvatar] = useState(emptyImage);

    useEffect(() => {
        const load = async () => {
            try {
                const avatarURL = await getCachedDownloadURL(storageRef);
                setAvatar(avatarURL || emptyImage);
            } catch {
                setAvatar(emptyImage);
            }
        };
        load();
    }, [storageRef]);

    if (avatar === emptyImage && emptyString) {
        return <div className={className}>{emptyString}</div>;
    }

    return <img className={className} src={avatar} alt={alt} />;
};

export default React.memo(CachedAvatar);
