import React, { useEffect } from "react";
import { useOutletContext } from "react-router";
import { Lib, useAlbertineTranslation, useTenant } from "albertine-shared-web";
import "./OnboardingCommonStyles.css";
import { OnboardingOutletContextType } from "../../types/OnboardingOutlet";

function OnboardingStart() {
    const { setNextStep, setProgress } =
        useOutletContext<OnboardingOutletContextType>();
    const { tenantConfig } = useTenant();
    const t = useAlbertineTranslation();

    useEffect(() => {
        setProgress(5);
    }, []);

    const handleClick = () => {
        setNextStep("name");
    };
    return (
        <Lib.Flex.Column gap="value24">
            <Lib.Flex.Column gap="value8">
                <Lib.Tag.Primary>{t("onboarding__start__tag")}</Lib.Tag.Primary>

                <Lib.Paragraph.XXLarge.XBold>
                    {t("onboarding__start__tenant-welcome-message", {
                        tenantMsg: tenantConfig.serviceName,
                    })}
                </Lib.Paragraph.XXLarge.XBold>
                <Lib.Paragraph.Small.MediumBold>
                    {t("onboarding__start__questions")}
                </Lib.Paragraph.Small.MediumBold>
            </Lib.Flex.Column>
            <Lib.Flex.Column>
                <Lib.Button.Onboarding type="button" onClick={handleClick}>
                    {t("onboarding__start__button")}
                </Lib.Button.Onboarding>
            </Lib.Flex.Column>
        </Lib.Flex.Column>
    );
}

export default OnboardingStart;
